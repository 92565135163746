import React, { useCallback, useEffect } from "react";
import { MainViewportModal } from "../../../react-components/modals/MainViewportModal";
import { LitresBlock } from "../../litres/Litres";
import { disableMovement, enableMovement } from "../../../utils/movement";

type QuizPromocodeModalProps = {
  handlePromocodeCopy: () => void;
  handlePromocodeClose: () => void;
  promocode: string;
};

export const QuizPromocodeModal: React.FC<QuizPromocodeModalProps> = ({
  handlePromocodeCopy,
  handlePromocodeClose,
  promocode
}) => {
  useEffect(() => {
    disableMovement();

    return () => {
      enableMovement();
    };
  }, []);

  return (
    <MainViewportModal>
      <LitresBlock onClick={handlePromocodeCopy} promocode={promocode} onClose={handlePromocodeClose} />
    </MainViewportModal>
  );
};
