import { getMeshes } from "../utils/aframe-utils";
import {
  CollectCatCollisionEventId,
  CollectCatCollisionEventName
} from "../custom/collect-cats/react-components/CollectCats";
import { getNextHub } from "../utils/get-next-hub";
import { COLLIDABLE_MODELS } from "../custom/collidable-model/constants";
import quest from "../custom/quest"
import '../custom/collidable-model/triggers/portal'

const teleportUrls = new Set();

AFRAME.registerComponent("event-inspect-collider", {
  collisionObjectsBuffer: {},
  collisionCurrent: {},

  init() {
    this.mesh = new THREE.Mesh(
      new THREE.BoxGeometry(0.5, 2.8, 0.5),
      new THREE.MeshBasicMaterial({
        visible: false
      })
    );

    this.el.setObject3D("event-inspect-collider", this.mesh);
    this.tick = AFRAME.utils.throttleTick(this.tick, 500, this);
  },

  checkAndSendEvent(intersectMesh) {
    try {
      let mesh = intersectMesh;

      while (mesh) {
        const userData = mesh.userData;

        if (userData !== null && userData !== undefined) {
          const eventId = userData.eventId;

          //TODO: Event backend via sendMessage
          switch (eventId) {
            case "hub":
              window?.changeHub(userData.eventData, true);
              break;

            case "teleport":
              {
                const eventData = userData.eventData;

                if (!teleportUrls.has(eventData)) {
                  teleportUrls.add(eventData);

                  getNextHub(eventData)
                    .then(async hubId => {
                      hubId && (await window?.changeHub(hubId));
                    })
                    .catch(console.error)
                    .finally(() => {
                      teleportUrls.delete(eventData);
                    });
                }
              }
              break;

            // case "quiz":
            //   {
            //     const solvedQuizes = JSON.parse(localStorage.getItem("quiz"));
            //     const isQuizSolved = solvedQuizes && Object.keys(solvedQuizes).includes(userData.quizId);
            //     if (isQuizSolved) {
            //       break;
            //     }
            //     const event = new CustomEvent("quiz_collision", { detail: { id: userData.quizId, mesh } });
            //     dispatchEvent(event);
            //   }
            //   break;

            case CollectCatCollisionEventId:
              {
                const event = new CustomEvent(CollectCatCollisionEventName, { detail: { mesh } });
                dispatchEvent(event);
              }
              break;

            case COLLIDABLE_MODELS.cats2.eventId:
            case COLLIDABLE_MODELS.quiz.eventId:
            case COLLIDABLE_MODELS.volumetricPlay.eventId:
            case COLLIDABLE_MODELS.portal.eventId:
              {
                const event = new CustomEvent(eventId, { detail: { mesh } });
                dispatchEvent(event);
              }
              break;
            case quest.getEventCollide():
              quest.questInspectColliderItems(mesh) // Это компонент КВЕСТА, там логика сбора претметов
              break;
            case quest.getEventCollideQuest():
              quest.questInspectCollider() // Это компонент КВЕСТА, тут логика взятия,  квеста
              break;
            default:
              break;
          }
        }

        mesh = mesh.children[0];
      }
    } catch (ex) {
      console.error(ex);
    }
  },

  // Проверим что мы уже не в коллизии с объектом
  checkLeaveCollisionAndSendEvent() {
    for (const key in this.collisionObjectsBuffer) {
      if (!this.collisionCurrent[key]) {
        const mesh = this.collisionObjectsBuffer[key]
        const userData = mesh.userData;

        if (userData !== null && userData !== undefined) {
          const eventId = userData.eventId;

          switch (eventId) {
          case quest.getEventCollideQuest():
            quest.questInspectColliderLeave() // Это компонент КВЕСТА, тут логика взятия,  квеста
            break;
          default:
            break;
          }
        }
        delete this.collisionObjectsBuffer[key];
      }
    }
  },

  checkOnfCollisionAndSendEvent() {
    this.collisionCurrent = {}
    const collisionEntities = Array.from(this.el.sceneEl.querySelectorAll("[body-helper]"));
    const meshes = getMeshes(collisionEntities);

    this.bbox = new THREE.Box3().setFromObject(this.mesh);

    for (const mesh of meshes) {
      if (!mesh.isObject3D) return
      const bbox = new THREE.Box3().setFromObject(mesh);
      const isIntersect = this.bbox.intersectsBox(bbox);
      
      if (isIntersect){
        this.collisionCurrent[mesh.uuid] = true;
  
        if(!this.collisionObjectsBuffer[mesh.uuid]){
          this.collisionObjectsBuffer[mesh.uuid] = mesh;
          this.checkAndSendEvent(mesh);
        }
      }
    }
  },

  tick() {
    // Проверка что ВОШЛИ в коллизию
    this.checkOnfCollisionAndSendEvent();

    // Проверка что вышли из коллизии объекта
    this.checkLeaveCollisionAndSendEvent();
  }
});
