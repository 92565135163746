import React, { FC, ReactNode } from "react";
import styles from "./FlexLayout.module.scss";

interface Props {
  children: ReactNode;
  className?: string,
  stylesParameters?: {
    direction: "row" | "column" | "row-reverse" | "column-reverse";
    align: string;
    justify: string;
  };
}

/**
* Flex-контейнер для задания структуры. Оберните элементы в этот контейнер. 
* По умолчанию задаёт flex направление в строку, однако в пропсе stylesParameters можно задать направление оси, и расположение контента вдоль основной оси(justify-content) и вдоль вспомогательной оси(align-items).
### Props
  - **stylesParameters** ({
    - direction: **"row"** | "column" | "row-reverse" | "column-reverse",
    - align: **"flex-start"** | любое валидное align-items свойство,
    - justify: **"flex-start"** | любое валидное justify-content свойство,
  - }): Основные параметры flex-контейнера

  - **className** (*string*): Возможность задать класс для кастомизации flex-контейнера

  - **children** (*ReactNode*): Контент
*/
export const FlexLayout: FC<Props> = ({
  children,
  className = "",
  stylesParameters = { direction: "row", align: "flex-start", justify: "flex-start" }
}) => {
  return (
    <div
      className={`${styles.row} ${className}`}
      style={{
        flexDirection: stylesParameters.direction,
        alignItems: stylesParameters.align,
        justifyContent: stylesParameters.justify
      }}
    >
      {children}
    </div>
  );
};
