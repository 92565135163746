import React, { useEffect, useState, useRef, FC } from "react";
import { DialogueThree, DialogueChoice, openDialogEventName } from ".";
// import { MainViewportModal } from "../react-components/modals/MainViewportModal";
import styles from "./styles.module.scss";
// import styles from "./styles.scss";

import { ModalWindow } from "../ui/StructureComponents/ModalWindow/ModalWindow";
import OptionsButton from "../ui/IndividualComponents/Buttons/OptionsButton/OptionsButton";
import { IndividualComponentsCloseButton } from "../ui/IndividualComponents/Buttons/CloseButton/CloseButton";

type ChoiceProps = {
  choice: DialogueChoice;
  go: (choice: number) => void;
  transitionDelay: number;
};

const Choice: React.FC<ChoiceProps> = ({ choice, go, transitionDelay }) => {
  const itemRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    // Animation
    itemRef.current!.style.opacity = `1`;
    itemRef.current!.style.transform = `translate3d(0, 0, 0)`;

    setTimeout(() => {
      itemRef.current!.style.pointerEvents = `all`;
    }, 350);
  }, []);

  return (
    <OptionsButton
      ref={itemRef}
      text={choice.text}
      onClick={() => go(choice.id)}
      className={`${choice.visited ? styles.visited : ""} ${styles.choice}`}
      style={{ transitionDelay: `${transitionDelay}s` }}
      isHidden={true}
    ></OptionsButton>
  );
};

type DialogueProps = {
  dialogue: DialogueThree;
};

interface DialogueModalProps {
  state: {
    id: number;
    texts: string[];
    choices: DialogueChoice[];
  };
  go: (choice: number) => void;
  handleClose: () => void;
}

export const DialogueWindow: FC<DialogueModalProps> = ({ state, go, handleClose }) => {
  return (
    <ModalWindow
      LayoutStructure={
        <div className={styles.wrapper}>
          {state.texts.map((text, i) => (
            <p className={styles.caption}>
              {i === 0 ? <span className={styles.characterName}>{"БотБот: "}</span> : ``}
              {text}
            </p>
          ))}

          <div className={styles.choices}>
            {state.choices.map((choice, i) => (
              <Choice choice={choice} key={choice.id} go={go} transitionDelay={0.2 * i} />
            ))}
          </div>
        </div>
      }
      CloseButton={<IndividualComponentsCloseButton onClick={handleClose} className={styles.closeBtn}></IndividualComponentsCloseButton>}
      handleClose={handleClose}
    ></ModalWindow>
  );
};

export const Dialogue: React.FC<DialogueProps> = ({ dialogue }) => {
  const [state, setState] = useState(dialogue.getState());
  const [visible, setVisible] = useState(false);

  const go = (choice: number) => {
    dialogue.go(choice);
    setState(dialogue.getState());
  };

  useEffect(() => {
    dialogue.setExitCallback(() => setVisible(false));

    const onShow = () => {
      setState(dialogue.getState());
      setVisible(true);
    };

    window.addEventListener(openDialogEventName, onShow);
    return () => {
      window.removeEventListener(openDialogEventName, onShow);
    };
  }, []);

  // window.dispatchEvent(new Event("open-dialogue"));

  if (!visible || !state) {
    return null;
  }

  return <DialogueWindow state={state} go={go} handleClose={() => setVisible(false)}></DialogueWindow>;
};
