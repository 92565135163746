import React, { useCallback, useEffect } from "react";
import { MainViewportModal } from "../../../react-components/modals/MainViewportModal";
import { SberZvukBlock } from "../../sberzvuk/Sberzvuk";
import { disableMovement, enableMovement } from "../../../utils/movement";
import { FirstQuiz } from "../../first-quiz/FirstQuiz";

type QuizCompletedModalProps = {
  handleSberQuizFinished: (close?: boolean) => void;
};

/**
 * Когда квиз завершен
 */
export const QuizCompletedModal: React.FC<QuizCompletedModalProps> = ({ handleSberQuizFinished }) => {
  useEffect(() => {
    disableMovement();

    return () => {
      enableMovement();
    };
  }, []);

  // const onClick = useCallback(() => {
  //   handleSberQuizFinished(false);
  // }, [handleSberQuizFinished]);

  const onClose = useCallback(() => {
    handleSberQuizFinished(true);
  }, [handleSberQuizFinished]);

  return (
    <MainViewportModal>
      <FirstQuiz onClick={onClose} closeHandler={onClose} buttonText="Ok">
        <>
        <br /><br />
          <b>Поздравляем, ты&nbsp;ответил на&nbsp;все вопросы Квиза! </b>
          <br />
          <br />
          Теперь ты&nbsp;знаешь немного больше про Искусственный интеллект! Не&nbsp;прощаемся, до&nbsp;новых встреч!
        </>
      </FirstQuiz>
    </MainViewportModal>
  );
};
