import React, { FC } from "react";
import styles from "./PillButton.module.scss";

interface Props {
  text: string;
  onClick: (() => void) | ((event: React.MouseEvent | MouseEvent) => void);
  className?: string;
	animationDirection?: "none" | "left" | "right" | "up" | "down"; // TODO: Add 2 other cases!
}

export const PillButton: FC<Props> = ({ text, onClick, className = "", animationDirection = "none" }) => {
  let animationDirectionClass = "";
	switch (animationDirection) {
		case "left":
			animationDirectionClass += "PillButtonLeft"
			break;
		case "right":
			animationDirectionClass += "PillButtonRight"
			break;
		case "up":
			animationDirectionClass += "PillButtonUp"
			break;
		case "down":
			animationDirectionClass += "PillButtonDown"
			break;
		default:
			break;
	}

	return (
    <button className={`${styles.PillButtonWrapper} ${className}`} onClick={onClick}>
      <div className={`${styles.PillButton} ${animationDirectionClass}`}>
        <p className={styles.PillButtonText}>{text}</p>
      </div>
    </button>
  );
};
