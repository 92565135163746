import { styled } from "styled-components";
import { QuizModal } from "../Modal/Modal";

export const FirstQuizModalContentStyled = styled.div`
  flex: 1;
  overflow: auto;
`;

export const FirstQuizStyled = styled(QuizModal)`
  display: flex;
  flex-direction: column;
`;

FirstQuizStyled.defaultProps = {
  close_button_offset: [50, 100]
};
