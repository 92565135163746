/** @jsx createElementEntity */
import { createElementEntity, createRef, renderAsEntity } from "../../utils/jsx-entity";
import buttonSrc from "../../assets/hud/button.9.png";
import buttonSrc30 from "../../assets/hud/btn_transparent_30.png";
import buttonSrc50 from "../../assets/hud/btn_transparent_50.png";
import buttonSrc80 from "../../assets/hud/btn_transparent_80.png";
import { textureLoader } from "../../utils/media-utils";
import { BUTTON_TYPES } from "../../systems/single-action-button-system";
import { Layers } from "../../components/layers";
import { changeHub } from "../../change-hub";
// import { isHubsRoomUrl, isLocalHubsUrl } from "../../utils/media-url-utils";
import { getNextHub } from "../../utils/get-next-hub";
import { AElement, AElementECS } from "aframe";
import { Object3D, Texture, EventListener } from "three";
import { metrikaReachGoal } from "../utils/metrika";
import configs from "../../utils/configs";

export const btnTextures = [
  textureLoader.load(buttonSrc),
  textureLoader.load(buttonSrc30),
  textureLoader.load(buttonSrc50),
  textureLoader.load(buttonSrc80)
];

type ButtonEntityProps = {
  text: string;
  size: [number, number];
  texture: Texture;
  type?: typeof BUTTON_TYPES.ACTION;
  position: [number, number, number];
  rotation: [number, number, number];
  scale: [number, number, number];
  name?: string;
};

export function ButtonEntity({
  text = "test",
  size = [1, 1],
  texture = btnTextures[0],
  type = BUTTON_TYPES.ACTION,
  ...props
}: ButtonEntityProps) {
  const labelRef = createRef();
  return (
    <entity
      name={"Button"}
      slice9={{
        size,
        insets: [64, 66, 64, 66],
        // TODO Fix?
        // @ts-expect-error
        texture
      }}
      cursorRaycastable
      remoteHoverTarget
      hoverButton={{ type }}
      textButton={{ labelRef }}
      singleActionButton
      layers={1 << Layers.CAMERA_LAYER_UI}
      {...props}
    >
      <entity
        ref={labelRef}
        layers={1 << Layers.CAMERA_LAYER_UI}
        text={{
          value: text,
          side: THREE.FrontSide,
          color: "#000000",
          textAlign: "center",
          anchorX: "center",
          anchorY: "middle",
          fontUrl: `${configs.BASE_ASSETS_PATH}assets/fonts/SB-Sans/SB-Sans-Interface.ttf`,
        }}
        position={[0, 0, 0.01]}
        name={props.name ? `${props.name} Label` : "Button Label"}
        visible={text.length > 1}
      />
    </entity>
  );
}

function Button3DObj(
  text: string = "test",
  position: [number, number, number] = [0, 0, 0],
  rotation: [number, number, number] = [0, 0, 0],
  size: [number, number] = [1, 1],
  scale: [number, number, number] = [1, 1, 1],
  texture: Texture = btnTextures[0],
  onClick: EventListener<Event, "custom-click", Object3D<Event>>
) {
  const eid = renderAsEntity(APP.world, ButtonEntity({ text, position, rotation, size, scale, texture }));
  const obj = APP.world.eid2obj.get(eid);

  if (!obj) {
    return;
  }

  // @ts-expect-error
  obj.prototype = Object.create(THREE.EventDispatcher.prototype);

  obj.addEventListener("custom-click", onClick);
  return obj;
}

/**
 * Ссылка для перехода в хаб
 */
export function createHubLinkButton(
  el: AElementECS,
  _componentName: string,
  componentData: {
    href: string;
  }
) {
  let position: [number, number, number] = [0, 0, 0];
  let rotation: [number, number, number] = [0, 0, 0];
  let scale: [number, number, number] = [0.5, 1.86, 0.2];

  // console.log("createHubLinkButton el", el, componentData);
  const href = /^https?::\/\//.test(componentData.href)
    ? componentData.href
    : `${location.origin}${componentData.href}`;

  /**
   * По умолчанию кнопки в размерах пляшут от родителя, а родитель - трапеция,
   * поэтому пропорции кнопок неодинаковые, и их надо переопределять
   */
  switch (el.className) {
    case "gate_valley":
      scale = [0.55, 1.86, 0.2];
      break;
    case "gate_lobby":
      scale = [0.52, 1.86, 0.2];
      break;
  }

  const buttonObj = Button3DObj("", position, rotation, [2, 0.6], scale, btnTextures[3], async () => {
    if (APP.store.state.preferences.fastRoomSwitching) {
      const url = new URL(href);

      const params = new URLSearchParams(url.search);

      let hubName = params.get("event");

      if (hubName) {
        switch (hubName) {
          case "mainhub":
            hubName = "СберКласс";
            break;
          case "lectory":
            hubName = "DD-22 | Lectory";
            break;
        }
        //  clicked-mainhub
        //  clicked-lecture2404-2
        //  clicked-lecture2404-1
        //  clicked-lecture2404-3
        //  clicked-lecture2404-4
        //  clicked-walkoffame
        //  clicked-metabrain
        //  clicked-lobby
        metrikaReachGoal("clicked-" + hubName);
        getNextHub(hubName)
          .then(hubId => {
            hubId && changeHub(hubId);
          })
          .catch(console.error);
      }
    } else {
      document.location = href;
    }
  });

  if (!buttonObj) {
    return;
  }

  el.object3D?.add(buttonObj);

  el.visible = true;
  el.object3D && (el.object3D.visible = true);
}
