/** @jsx createElementEntity */
import cameraModelSrc from "../../assets/camera_photoshoot.glb";
// import cameraModelSrc from "../../assets/models/Camera.glb";
import { cloneModelFromCache, loadModel } from "../../components/gltf-model-plus";
import { Layers } from "../../components/layers";
import { BUTTON_TYPES } from "../../systems/single-action-button-system";
import { createElementEntity, createRef } from "../../utils/jsx-entity";
import { preload } from "../../utils/preload";
import { ButtonEntity } from "../button/button";

// eslint-disable-next-line react/prop-types
export function Label({ text = {}, ...props }, ...children) {
  const value = children.join("\n");
  return <entity name="Label" text={{ value, ...text }} layers={1 << Layers.CAMERA_LAYER_UI} {...props} />;
}

const RENDER_WIDTH = 1280;
const RENDER_HEIGHT = 720;

// We intentionally do not remove this model from the GLTF Cache
preload(loadModel(cameraModelSrc, null, true));

export function CameraTool() {
  const snapMenuRef = createRef();
  const nextButtonRef = createRef();
  const prevButtonRef = createRef();
  const snapRef = createRef();
  const cancelRef = createRef();
  const recVideoRef = createRef();
  const screenRef = createRef();
  const selfieScreenRef = createRef();
  const cameraRef = createRef();
  const countdownLblRef = createRef();
  const captureDurLblRef = createRef();
  const sndToggleRef = createRef();

  const screenMaterial = new THREE.MeshBasicMaterial({ toneMapped: false });
  const width = 0.28;
  const aspect = 1280 / 720;
  const screenGeometry = new THREE.PlaneBufferGeometry(width, width / aspect);

  const camera = new THREE.PerspectiveCamera(75, RENDER_WIDTH / RENDER_HEIGHT, 0.1, 30000);
  camera.layers.enable(Layers.CAMERA_LAYER_VIDEO_TEXTURE_TARGET);
  camera.layers.enable(Layers.CAMERA_LAYER_THIRD_PERSON_ONLY);
  camera.layers.enable(Layers.CAMERA_LAYER_PHOTOSHOOT);
  const buttonHeight = 0.2;
  const buttonScale = [0.5, 0.5, 0.5];
  const smallButtonScale = [0.2, 0.2, 0.2];
  const uiZ = 0.05;
  return (
    <entity
      name="Camera Tool"
      cursorRaycastable
      // remoteHoverTarget
      // handCollisionTarget
      // offersRemoteConstraint
      // offersHandConstraint
      // makeKinematicOnRelease
      // holdable
      // rigidbody={{ collisionGroup: COLLISION_LAYERS.INTERACTABLES, collisionMask: COLLISION_LAYERS.HANDS }}
      // physicsShape={{ halfExtents: [0.22, 0.14, 0.1] }}
      cameraTool={{
        snapMenuRef,
        nextButtonRef,
        prevButtonRef,
        snapRef,
        cancelRef,
        recVideoRef,
        screenRef,
        selfieScreenRef,
        cameraRef,
        countdownLblRef,
        captureDurLblRef,
        sndToggleRef
      }}
    >
      <entity
        name="Screen"
        object3D={new THREE.Mesh(screenGeometry, screenMaterial)}
        ref={screenRef}
        position={[0, 0, -0.042]}
        rotation={[0, Math.PI, 0]}
      />
      <entity
        name="Selfie Screen"
        ref={selfieScreenRef}
        object3D={new THREE.Mesh(screenGeometry, screenMaterial)}
        position={[0, 0.4, 0]}
        scale={[-2, 2, 2]}
      />

      <entity
        name="Camera Model"
        model={{ model: cloneModelFromCache(cameraModelSrc).scene }}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        visible={false}
      />

      <entity name="Camera" ref={cameraRef} object3D={camera} position={[0, 0, 0]} rotation={[0, Math.PI, 0]} />

      <entity name="Snap Menu" ref={snapMenuRef} rotation={[0, 0, 0]} position={[0, -0.05, 0]} scale={[0.5, 0.5, 0.5]}>
        <Label name="CountDown" ref={countdownLblRef} position={[0, -0.2, uiZ + 0.002]} />
        <Label name="CaptureDur" ref={captureDurLblRef} position={[0, -0.2, uiZ + 0.002]} />

        <ButtonEntity
          ref={cancelRef}
          name={"cancelPhoto"}
          scale={buttonScale}
          position={[0, -0.1, uiZ]}
          size={[.8, buttonHeight]}
          text={"Отменить"}
        />
        <ButtonEntity
          ref={snapRef}
          name={"makePhoto"}
          scale={buttonScale}
          position={[0, -0.1, uiZ]}
          size={[.8, buttonHeight]}
          type={BUTTON_TYPES.ACTION}
          text={"Сделать фото"}
        />

        <ButtonEntity
          ref={prevButtonRef}
          scale={smallButtonScale}
          position={[-0.082, 0, uiZ]}
          size={[buttonHeight, buttonHeight]}
          text={"<"}
        />
        <ButtonEntity
          ref={recVideoRef}
          scale={buttonScale}
          position={[0, -1000, uiZ]}
          size={[0.4, buttonHeight]}
          type={BUTTON_TYPES.ACTION}
          text={"Video"}
        />
        <ButtonEntity
          ref={nextButtonRef}
          scale={smallButtonScale}
          position={[0.082, 0, uiZ]}
          size={[buttonHeight, buttonHeight]}
          text={">"}
        />

        <ButtonEntity
          ref={sndToggleRef}
          scale={smallButtonScale}
          position={[0, -0.17, uiZ]}
          size={[0.6, buttonHeight]}
          text={"Sound OFF"}
        />
      </entity>
    </entity>
  );
}
