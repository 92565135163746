import EventHelper from "./EventHelper";
import QUEST_DATA from "./data";

class Quest {
  #event: EventHelper = new EventHelper(QUEST_DATA.eventName, QUEST_DATA.eventBlock);
  #items: any = QUEST_DATA.items;
  #status: string = QUEST_DATA.status.off;
  #questChainElement: number = 0; // текущий квест из цепочки квестов
  #questEventId: string; // Текущий Эвент квеста из цепочки
  #foundItems: number[]; // массив уже найденных предметов
  #endDialogflag: string;
  #exclamationMark: any = false // костылёк для восклицательного знака

  constructor() {
    this.#questChainElement = parseInt(localStorage.getItem(QUEST_DATA.localStorage.questChainElement) || "0");
    this.#status = localStorage.getItem(QUEST_DATA.localStorage.questStatus) || QUEST_DATA.status.off;
    this.#endDialogflag = localStorage.getItem(QUEST_DATA.localStorage.questDialoguesFlag) || "";
    this.#questEventId = this.#getActiveChain()?.chain;

    this.#checkFoundItem()

    window.addEventListener("quest-received", (e: CustomEvent) => {
      this.#endDialogflag = e?.detail?.flag;
      if (this.#endDialogflag) {
        localStorage.setItem(QUEST_DATA.localStorage.questDialoguesFlag, this.#endDialogflag);
        this.start();
      } else {
        console.error("no detail.flag");
      }
    });

    // Прослушиваем свой же эвент для воскл знака
    window.addEventListener(QUEST_DATA.eventName, (el: CustomEvent) => {
      const status = el?.detail?.data?.status
      this.setVisibleMark(status)
    })
  }

  /**
   * public
   */
  init() {
    // this.#clearFoundItem();
    this.#checkFoundItem()
    this.#checkItems();

    // Костыль для восклицательного Знака.
    if (!QUEST_DATA.exclamationMark) return
    const exclamationMark: any = document.querySelector(QUEST_DATA.exclamationMark);
    if (!exclamationMark) return

    exclamationMark.object3D.traverse((object:any) => {
      if(object.type == 'SkinnedMesh') {
        this.#exclamationMark = object 
        return
      }
    });

    this.setVisibleMark(this.#status)
  }

  // Запустит квест
  start() {
    if (!this.nextQuest()) return;

    this.#setStatus(QUEST_DATA.status.start);
    // this.#clearFoundItem();
    this.#checkItems();

    this.#dispatch(QUEST_DATA.eventType.start);
  }

  getEventCollide() {
    return QUEST_DATA.eventCollide;
  }

  getEventCollideQuest() {
    return QUEST_DATA.quesCollide;
  }

  nextQuest() {
    if (this.#status == QUEST_DATA.status.found) {
      const iterChain: number = ++this.#questChainElement;

      if (iterChain >= this.#items.length) {
        this.endQuest();
        return false;
      }

      this.#setChainElement(iterChain);
      this.#questEventId = this.#getActiveChain()?.chain;
      return true;
    }

    return true;
  }

  endQuest() {
    this.#setStatus(QUEST_DATA.status.end);
    this.#dispatch(QUEST_DATA.eventType.end);

    window.dispatchEvent(new CustomEvent(QUEST_DATA.eventDialog, { detail: { flag: this.#endDialogflag } }));
  }

  foundQuest() {
    this.#setStatus(QUEST_DATA.status.found);
    this.#dispatch(QUEST_DATA.eventType.foundAll);
  }

  searchQuest() {
    this.#setStatus(QUEST_DATA.status.search);
  }

  // Когда нашли нужный элемент квеста, вызывется этот метод
  foundItem(mesh: any, objectId: number) {
    const eventId = mesh?.userData?.eventId;

    this.#setFoundItem(objectId);

    mesh.el.setAttribute("visible", false);

    if (this.#foundItems.length >= this.#getActiveChain()?.amount) {
      if (this.#questChainElement >= this.#items.length - 1) {
        this.endQuest();
      } else {
        this.foundQuest();
      }
    } else {
      this.searchQuest();
    }

    this.#dispatch(QUEST_DATA.eventType.foundItem, {
      eventId: eventId,
      objectId: objectId,
      mesh: mesh
    });
  }

  // Для определения коллизии ( если мы нашли нужный нам предмет )
  questInspectColliderItems = (mesh: any) => {
    const visible = mesh.el.getAttribute("visible");
    const objectId = mesh?.userData?.objectId.split(QUEST_DATA.itemsSeparator);
    if (visible && objectId[0] && objectId[1] && this.#questEventId == objectId[0]) quest.foundItem(mesh, objectId[1]);
  };

  // Для определения коллизии ( чтобы взять квест )
  questInspectCollider() {
    this.#dispatch(QUEST_DATA.eventType.joinObj);
  };

  questInspectColliderLeave() {
    this.#dispatch(QUEST_DATA.eventType.exitObj);
  }

  // Костылёк для восклицательного знака
  setColorMark(status:string = 'off'){
    if(this.#exclamationMark){
      switch (status) {
        case QUEST_DATA.status.off: this.#exclamationMark.material.color.set('red'); break;
        case QUEST_DATA.status.start: this.#exclamationMark.material.color.set('yellow'); break;
        case QUEST_DATA.status.search: this.#exclamationMark.material.color.set('blue'); break;
        case QUEST_DATA.status.found: this.#exclamationMark.material.color.set('blue'); break;
        case QUEST_DATA.status.end: this.#exclamationMark.material.color.set('green'); break;

        default: break;
      }
    }
  }
  setVisibleMark(status:string = 'off'){
    const exclamationMark: any = document.querySelector(QUEST_DATA.exclamationMark);
    if (!exclamationMark) return

    switch (status) {
      case QUEST_DATA.status.off: exclamationMark.setAttribute("visible", true); break;
      default: exclamationMark.setAttribute("visible", false); break;
    }
    // Принудительно выставить желтый цвет
    // this.#exclamationMark.material.color.set('yellow')
  }

  /**
   * private
   */
  #dispatch(type: string, data: object = {}) {
    this.#event.dispatch(type, {
      status: this.#status, // текущий статус квеста
      chain: this.#getActiveChain()?.chain, // активный сейчас квест из всей цепочки
      countItems: this.#foundItems.length, // Количество итемов что уже собрали
      amountAllItems: this.#getActiveChain()?.amount, // Количество ВСЕХ итемов что нужно собрать
      ...data
    });
  }

  #setStatus(status: string) {
    this.#status = status;
    localStorage.setItem(QUEST_DATA.localStorage.questStatus, this.#status);
  }

  #setFoundItem(id: number) {
    if (id) {
      this.#foundItems.push(id);
      localStorage.setItem(QUEST_DATA.localStorage.questFoundItems, this.#foundItems.join(","));
    }
  }

  #setChainElement(num: number = 0) {
    this.#questChainElement = num;
    localStorage.setItem(QUEST_DATA.localStorage.questChainElement, String(this.#questChainElement));
  }

  #checkFoundItem() {
    this.#foundItems =
      localStorage.getItem(QUEST_DATA.localStorage.questFoundItems)
        ?.split(",")
        .filter(Boolean)
        ?.map(string => +string) || [];
  }

  #clearFoundItem() {
    this.#foundItems = [];
    localStorage.setItem(QUEST_DATA.localStorage.questFoundItems, "");
  }

  #getActiveChain() {
    return this.#items[this.#questChainElement];
  }

  // Проверит итемы что мы ищем, скроет ненужные откроет нужные.
  #checkItems() {
    const nodes = document.querySelectorAll("#environment-scene a-entity[body-helper]");
    const itemsEvent = this.#items.map((obj: any) => obj.eventId);

    nodes.forEach((node: any) => {
      const mesh = node.getObject3D("mesh");
      const { eventId, objectId } = mesh?.userData || {};

      if (!eventId || !objectId) {
        return;
      }

      const objectIds = objectId.split(QUEST_DATA.itemsSeparator);

      if (eventId != QUEST_DATA.eventCollide || !objectIds || objectIds.length < 2) return;

      if (QUEST_DATA.status.off == this.#status) {
        node.setAttribute("visible", false);
        return;
      }

      if (QUEST_DATA.status.found == this.#status) {
        node.setAttribute("visible", false);
        return;
      }
      
      if (QUEST_DATA.status.end == this.#status) {
        node.setAttribute("visible", false);
        return;
      }

      if (objectIds[0] == this.#questEventId && !this.#foundItems.includes(parseInt(objectIds[1]))) {
        node.setAttribute("visible", true);
        return;
      }

      node.setAttribute("visible", false);
    });
  }
}

const quest = new Quest();
export default quest;
