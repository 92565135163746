const MetrikaID = 91375135;

type MetrikaGoal = "photo-click" | "window-zvuk" | "windows-litres" | "show-prime" | string;

export const metrikaReachGoal = (goal: MetrikaGoal) => {
  // @ts-expect-error
  if (global.ym) {
    // @ts-expect-error
    global.ym(MetrikaID, "reachGoal", goal);
  }
};
