import { AElementECS } from "aframe";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AnimationClip, Group, Mesh, Object3D } from "three";
import { findAncestorWithComponent } from "../../../../utils/scene-graph";
import { IconCat } from "../icons/Cat/IconCat";
import { Modal } from "../Modal";
import { CatsPrizeModal } from "./modals/Prize";

import styles from "./styles.scss";

export const CollectCatCollisionEventId = "collectCats";

/**
 * @deprecated
 */
export const CollectCatCollisionEventName = "collectCatCollision";

const STORAGE_KEY = "cats_collections";

const CAT_COLLECTED_ANIMATION_NAME = "CatCollected";

/**
 * Сколько всего котов требуется
 */
const TOTAL = 11;

type CollectCatsModal = "first" | "any" | "all";

/**
 * @deprecated
 */
export const CollectCats: React.FC = () => {
  // All finded
  const [finded, findedSetter] = useState<string[]>([]);

  const collectedAll = finded.length <= TOTAL ? finded.length : TOTAL;

  const [modal, modalSetter] = useState<CollectCatsModal | null>(null);

  /**
   * Получаем исходное состояние хранилища и фиксируем в стейт
   */
  const initData = useCallback(() => {
    let data: string[] = [];

    try {
      const cats_collections = localStorage.getItem(STORAGE_KEY);
      data = cats_collections ? JSON.parse(cats_collections) : [];

      if (!Array.isArray(data)) {
        data = [];
      }
    } catch (error) {
      console.error(error);
    }

    /**
     * Если есть уже найденные, надо удалить их из сцены
     */
    if (data.length) {
      /**
       * Получаем все ноды объектов из загруженной сцены
       */
      const nodes = [...document.querySelectorAll("#environment-scene > [gltf-model-plus] > [environment-settings] *")];

      nodes.forEach(node => {
        // @ts-expect-error
        const object3D: AElementECS["object3D"] | undefined = node.object3D;

        const eventId: string = object3D?.children[0]?.userData?.eventId;
        const objectId: string | number | undefined = object3D?.children[0]?.userData?.objectId;

        if (eventId === CollectCatCollisionEventId && objectId) {
          /**
           * Если id объекта совпадает, удаляем узел
           */
          if (data.includes(objectId.toString())) {
            node.parentElement?.parentElement?.remove();
          }
        }
      });
    }

    /**
     * Фиксируем количество найденных
     */
    findedSetter(data);

    return;
  }, []);

  /**
   * Первичная инициализация данных
   */
  useEffect(() => {
    initData();
  }, []);

  /**
   * Навешиваем обработчик на обновление окрежения сцены, так
   * как при быстром переходе в другие хабы нет загрузки страницы
   * и не срабатывает инициализация
   */
  useEffect(() => {
    const environmentScene = document.querySelector("#environment-scene");

    if (!environmentScene) {
      return;
    }

    environmentScene.addEventListener("model-loaded", initData);

    return () => {
      environmentScene.removeEventListener("model-loaded", initData);
    };
  }, []);

  /**
   * Сохраняем измененное состояние в хранилище
   */
  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(finded));
  }, [finded]);

  const showModal = useCallback((finded: string[], showAny = false) => {
    /**
     * В зависимости от количества найденных, выводим модалку
     */
    switch (finded.length) {
      case 0:
        modalSetter(null);
        break;
      case 1:
        modalSetter("first");
        break;
      case TOTAL:
        modalSetter("all");
        break;
      default:
        showAny && modalSetter("any");
    }
  }, []);

  const showStatusModal = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();

      showModal(finded, true);
    },
    [finded]
  );

  /**
   * Навешиваем обработчик события коллизии
   */
  useEffect(() => {
    const onCatCollision = (
      event: CustomEvent<{
        mesh: (Mesh | Group) & {
          el?: AElementECS;
        };
      }>
    ) => {
      const mesh = event.detail.mesh;

      if (mesh instanceof Group) {
        const objectId: string = mesh.userData.objectId?.toString();

        if (!objectId) {
          console.error("Can not get objectId");
          return;
        }

        if (mesh.el) {
          /**
           * Чтобы исключить повторный вызов, устанавливаем флаг
           */
          if (mesh.el.getAttribute("cat-collected")) {
            return;
          } else {
            mesh.el.setAttribute("cat-collected", "true");
          }

          const groupNode = mesh.el.parentElement?.parentElement;

          if (groupNode) {
            /**
             * Находим ноду кота
             */
            const catNode = [...groupNode.children]
              // @ts-expect-error
              .find(n => n.object3D?.name.startsWith("Cat"));

            if (catNode instanceof HTMLElement) {
              /**
               * Удаляем текущие анимации
               */
              catNode.removeAttribute("loop-animation");

              // Find animations
              const mixerEl = findAncestorWithComponent(mesh.el, "animation-mixer");

              const animations: AnimationClip[] = mixerEl.components["animation-mixer"]?.animations || [];

              const collectedAnimation = animations.find(n => n.name === CAT_COLLECTED_ANIMATION_NAME);

              const findedNew = [...new Set([objectId, ...finded])];

              findedSetter(findedNew);

              const removeNode = () => {
                groupNode?.remove();

                showModal(findedNew);
              };

              if (collectedAnimation) {
                catNode.setAttribute(
                  "loop-animation",
                  // @ts-expect-error
                  {
                    activeClipIndices: [animations.indexOf(collectedAnimation)],
                    paused: false,
                    activeClipIndex: 0,
                    startOffset: 0,
                    timeScale: 1
                  }
                );
                setTimeout(removeNode, 1000 * ((collectedAnimation.duration || 0.5) - 0.1));
              } else {
                removeNode();
              }
            }
          }
        }
      }
    };

    window.addEventListener(CollectCatCollisionEventName, onCatCollision);

    return () => {
      window.removeEventListener(CollectCatCollisionEventName, onCatCollision);
    };
  }, [finded]);

  const closeModal = useCallback(() => {
    modalSetter(null);
  }, []);

  const modalContent = useMemo(() => {
    if (!modal) {
      return;
    }

    let text: string | JSX.Element = "";

    switch (true) {
      case collectedAll === TOTAL:
        // text = "Ты нашел всех котов, тебя ждет подарок!";

        return <CatsPrizeModal onClose={closeModal} />;
      case collectedAll === 1:
        text = (
          <>
            <b>Ты нашел первого кота!</b>
            <br /> Они прячутся в разных комнатах. Найди всех котов и получи подарок.
          </>
        );
        break;
      default:
        text = (
          <>
            Найди всех котов и получи подарок. Они прячутся в разных комнатах.
          </>
        );
    }

    return (
      <Modal handleClose={closeModal} key={`${modal}-${collectedAll}`}>
        <IconCat />

        <div className="total">
          <b>{collectedAll}</b>/{TOTAL}
        </div>

        <div className="text">{text}</div>
        <div className="searchCat">На поиски!</div>
      </Modal>
    );
  }, [modal, collectedAll, closeModal]);

  return (
    <>
      <div className={styles.catsCollection} onClick={showStatusModal}>
        <div className={styles.iconCat}>
          <div className={styles.counterCat}>
            <span className={styles.currCats}>{finded.length}</span>/{TOTAL}
          </div>
        </div>
      </div>
      {modalContent}
    </>
  );
};
