type ChoiceAction = {
  name: string;
  data: { [key: string]: any };
};

type Flags = { [key: string]: boolean };

const noop = () => {};

export const json = {
  start: 10,
  choices: [
    {
      id: 11,
      text: "Что случилось?",
      node: 20
    },
    {
      id: 21,
      text: "Мне бы не помешали подробности.",
      node: 30
    },
    {
      id: 22,
      text: "Почему ты хочешь научиться говорить?",
      node: 50
    },
    {
      id: 23,
      text: "Да, конечно.",
      node: 60
    },
    {
      id: 31,
      text: "А почему именно сюда?",
      node: 40
    },
    {
      id: 41,
      text: "Да, не вопрос. Что надо сделать?",
      node: 60
    },
    {
      id: 51,
      text: "Действительно. Чем я могу тебе помочь?",
      node: 60
    },
    {
      id: 61,
      text: "Отлично. И что мне надо сделать в первую очередь?",
      node: 70
    },
    {
      id: 71,
      text: "Хорошо, пойду искать.",
      action: {
        name: "quest-received",
        data: {
          quest: "chip-quest",
          flag: "chip-quest-complited"
        }
      },
      exit: true,
      node: 80
    },
    {
      id: 81,
      text: "Хорошо, пойду искать.",
      exit: true,
      condition: "!chip-quest-complited",
      node: 80
    },
    {
      id: 82,
      text: "Так, что у нас по плану дальше?",
      condition: "chip-quest-complited",
      action: {
        name: "quest-complited",
        data: {
          quest: "chip-quest"
        }
      },
      node: 100
    },
    {
      id: 101,
      text: "Конечно. Я мастер в таких задачах.",
      node: 110
    },

    {
      id: 111,
      text: "Как называется голосовой помощник, придуманный компанией «Яндекс»?",
      node: 130
    },
    {
      id: 112,
      text: "Какой голосовой помощник работает на базе данных VK?",
      node: 120
    },
    {
      id: 113,
      text: "Как называется первый в мире голосовой помощник?",
      node: 120
    },
    {
      id: 121,
      text: "Хорошо. Давай попробуем еще раз.",
      node: 110
    },
    {
      id: 131,
      text: "Отлично. Поехали!",
      node: 140
    },
    {
      id: 141,
      text: "Какие голосовые помощники были придуманы последними?",
      node: 150
    },
    {
      id: 142,
      text: "Как называются голосовые помощники, у которых есть мужские голоса?",
      node: 160
    },
    {
      id: 143,
      text: "Какие голосовые помощники были придуманы в начале 2010-х годов?",
      node: 150
    },
    {
      id: 151,
      text: "Хорошо. Давай попробуем еще раз.",
      node: 140
    },
    {
      id: 161,
      text: "Отлично. Поехали!",
      node: 170
    },
    {
      id: 171,
      text: "Как называется сервис персонального ассистента, разработанный компанией Google?",
      node: 190
    },
    {
      id: 172,
      text: "Как называется сервис, откуда можно скачивать приложения, в том числе и голосовые помощники?",
      node: 180
    },
    {
      id: 173,
      text: "Как называется семейство виртуальных голосовых помощников, куда входят целых три ассистента — «Джой», «Афина» и «Сбер»?",
      node: 180
    },
    {
      id: 181,
      text: "Хорошо. Давай попробуем еще раз.",
      node: 170
    },
    {
      id: 191,
      text: "Теперь ты можешь говорить?",
      node: 200
    },
    {
      id: 201,
      text: "Это было просто и интересно.",
      action: {
        name: "play-robot-sound"
      },
      node: 210
    },
    {
      id: 202,
      text: "Было непросто, но мне удалось.",
      action: {
        name: "play-robot-sound"
      },
      node: 210
    },
    {
      id: 203,
      text: "Мне все так быстро дается.",
      action: {
        name: "play-robot-sound"
      },
      node: 210
    },
    {
      id: 211,
      text: "Не за что. Здорово, что у нас всё получилось.",
      exit: true,
      node: 210
    }
  ],
  nodes: [
    {
      id: 10,
      texts: "Привет! Я бот и хочу научиться говорить. Но пока умею только писать. Поможешь мне найти свой голос?",
      choices: [11]
    },
    {
      id: 20,
      texts:
        "Я не могу общаться голосом, как другие современные помощники. Мне не хватает голосового модуля и проведения тестовой проверки. В этом не обойтись без участия человека. Сможешь мне помочь?",
      choices: [21, 22, 23]
    },
    {
      id: 30,
      texts:
        "Меня называют БотБот, я один из текстовых помощников для обучения. Мне хочется стать лучше! Я нашёл сведения о способах взаимодействия роботов с человеком. Одним из них является способность говорить. Я пока не умею, но хочу научиться. Вот поэтому я и пришёл сюда за помощью.",
      choices: [31]
    },
    {
      id: 40,
      texts:
        "Согласно базе данных, люди умеют обучать ботов. Метавселенная - подходящее место, чтобы найти человека и попросить его об этом. Что думаешь?",
      choices: [41]
    },
    {
      id: 50,
      texts:
        "Я проанализировал других ботов и сделал вывод, что умение говорить обеспечивает более доверительную связь с человеком. А так как я бот-помощник, это одна из важнейших частей моего функционала. Поэтому иметь голос крайне важно.",
      choices: [51]
    },
    {
      id: 60,
      texts:
        "Согласно моей базе данных, в этой комнате находятся четыре части голосового модуля и документ с правилами машинного обучения. Собирать их нужно в определённой последовательности, иначе ничего не получится, а после пройти диагностику.",
      choices: [61]
    },
    {
      id: 70,
      texts: "Найти четыре микросхемы голосового модуля и принести их мне.",
      choices: [71]
    },
    {
      id: 80,
      texts: [
        { text: "Спасибо за микросхемы! Теперь я могу собрать голосовой модуль.", condition: "chip-quest-complited" },
        {
          text: "Тут не все микросхемы. Я не смогу собрать голосовой модуль, если не будет всех частей.",
          condition: "!chip-quest-complited"
        }
      ],
      choices: [81, 82]
    },
    {
      id: 100,
      texts:
        "Мне нужен документ с алгоритмом машинного обучения. Чтобы получить его, тебе нужно будет проверить свои знания об искусственном интеллекте.",
      choices: [101]
    },
    {
      id: 110,
      texts:
        "Алиса. Её выпустили 10 октября 2017 года, и сейчас это один из самых популярных виртуальных голосовых помощников в России.",
      choices: [111, 112, 113]
    },
    {
      id: 120,
      texts: "Неверно. Попробуй подумать и выбрать другой вопрос.",
      choices: [121]
    },
    {
      id: 130,
      texts: "Верно! Переходим к следующему ответу.",
      choices: [131]
    },
    {
      id: 140,
      texts: "«Олег» и «Салют». Первый был придуман компанией «Тинькофф», а второй — компанией «Сбер».",
      choices: [141, 142, 143]
    },
    {
      id: 150,
      texts: "Неверно. Попробуй подумать и выбрать другой вопрос.",
      choices: [151]
    },
    {
      id: 160,
      texts: "Верно! Переходим к следующему ответу.",
      choices: [161]
    },
    {
      id: 170,
      texts: "Google Ассистент. Его первый запуск был в 2016 году, а поддержку русского языка он получил в 2018-м.",
      choices: [171, 172, 173]
    },
    {
      id: 180,
      texts: "Неверно. Попробуй подумать и выбрать другой вопрос.",
      choices: [181]
    },
    {
      id: 190,
      texts: "Верно!  Спасибо большое за помощь.",
      choices: [191]
    },
    {
      id: 200,
      texts:
        "Похоже, что я уже слышу свой внутренний голос и скоро он вырвется наружу! Тебе удалось быстро справиться.",
      choices: [201, 202, 203]
    },
    {
      id: 210,
      texts: "Как приятно слышать свой голос. Спасибо тебе за помощь!",
      choices: [211]
    }
  ]
};

const checkId = (id?: number) => {
  if (!id) {
    console.error("id обязателен");
  }
};

const checkArrayLenght = (array: any[]) => {
  if (!array.length) {
    console.error("массив не может быть пустым");
  }
};

export const openDialogEventName = "open-dialogue";

// переход на другую ноду
export class DialogueChoice {
  id: number = 0;
  text: string = ""; // текст выбора
  condition: string = ""; // название флага для проверки условия появления
  flag: string = ""; // устанавливаемый флаг при выборе
  action: ChoiceAction | null = null; // название генерируемого события при выборе
  exit: boolean = false; // флаг выхода из диалога при переходе
  visited: boolean = false; // флаг осуществленного перехода
  node: number = 0; // id ноды для перехода

  constructor(choice: any) {
    checkId(choice.id);
    checkId(choice.node);

    this.id = choice.id;
    this.text = choice.text || "";
    this.condition = choice.condition || "";
    this.flag = choice.flag || "";
    this.action = choice.action || null;
    this.exit = !!choice.exit;
    this.node = choice.node;
  }
}

type ActionCallback = (action: ChoiceAction) => void;
type ExitCallback = () => void;

type DialogueNodeText = {
  text: string; // текст
  condition?: string; // название флага для отображения текста
};

// нода дерева
export class DialogueNode {
  id: number = 0;
  texts: DialogueNodeText[];
  choices: number[] = []; // список переходов из ноды

  constructor(node: any) {
    checkId(node.id);
    checkArrayLenght(node.choices);

    this.id = node.id;

    if (typeof node.texts === "string") {
      this.texts = [
        {
          text: node.texts || ""
        }
      ];
    } else if (Array.isArray(node.texts)) {
      this.texts = node.texts;
    } else if (typeof node.texts === "object") {
      this.texts = [node.texts];
    }

    this.choices = node.choices;
  }
}

type StorageData = {
  visitedChoices: number[];
  flags: Flags;
  current: number;
};

interface Storage {
  save: (data: StorageData) => void;
  load: () => StorageData;
}

export class LocalSaver {
  key: string;

  constructor(key: string) {
    this.key = key;
  }

  save(data: StorageData) {
    localStorage.setItem(this.key, JSON.stringify(data));
  }

  load() {
    const json = localStorage.getItem(this.key);

    if (!json) {
      return {
        visitedChoices: [],
        flags: {}
      };
    }

    return JSON.parse(json);
  }
}

type SetFlagRequestEvent = {
  detail: {
    flag: string;
  };
};

// дерево
export class DialogueThree {
  flags: Flags = {};
  nodes: DialogueNode[] = []; // список нод
  choices: DialogueChoice[] = []; // список всех переходов для дерева
  current: number; // текущая нода
  action: ActionCallback = noop;
  exit: ExitCallback = noop;
  storage?: Storage;

  static fromJSON(json: any) {
    const choices = json.choices.map((c: any) => new DialogueChoice(c));
    const nodes = json.nodes.map((n: any) => new DialogueNode(n));
    const current = json.start;

    return new DialogueThree(nodes, choices, current);
  }

  constructor(nodes: DialogueNode[], choices: DialogueChoice[], current: number, flags?: Flags) {
    this.nodes = nodes;
    this.choices = choices;
    this.current = current;
    this.flags = flags || {};

    window.addEventListener("dialogue-set-flag-request", (event: CustomEvent) => {
      this.setFlag(event.detail.flag);
    });
  }

  setStorage(storage: Storage) {
    this.storage = storage;
  }

  setExitCallback(exit: ExitCallback) {
    this.exit = exit;
  }

  setActionCallback(action: ActionCallback) {
    this.action = action;
  }

  getCondition(condition?: string) {
    if (!condition) {
      return true;
    }

    let inverse = false;
    if (condition.startsWith("!")) {
      inverse = true;
      condition = condition.slice(1);
    }

    let result = !!this.flags[condition];
    if (inverse) {
      result = !result;
    }

    return result;
  }

  getState() {
    const node = this.nodes.find(n => n.id === this.current);

    if (!node) {
      console.error("нода не найдена");
      return;
    }

    const choices = node.choices
      .map(c => this.choices.find(tc => tc.id === c))
      .filter(choice => {
        if (!choice) {
          return false;
        }

        return this.getCondition(choice.condition);
      })
      // грязный хак из-за того что ts не понимает что undefined уже отфильтрованы
      .map((c: DialogueChoice) => c);

    const texts = node.texts.filter(text => this.getCondition(text.condition)).map((t: DialogueNodeText) => t.text);

    return {
      id: node.id,
      texts,
      choices
    };
  }

  setFlag(flag: string) {
    this.flags[flag] = true;
    this.save();
  }

  setFlags(flags: Flags) {
    this.flags = flags;
  }

  setVisited(ids: number[]) {
    this.choices.forEach(c => {
      c.visited = ids.some(id => id === c.id);
    });
  }

  go(id: number) {
    const choice = this.choices.find(c => c.id === id);

    if (!choice) {
      console.error("выбора не найден");
      return;
    }

    const nextNode = this.nodes.find(n => n.id === choice.node);

    if (!nextNode) {
      console.error("нода не найдена");
      return;
    }

    choice.visited = true;

    if (choice.flag) {
      this.flags[choice.flag] = true;
    }

    if (choice.action) {
      this.action(choice.action);
    }

    if (choice.exit) {
      this.exit();
    }

    this.current = choice.node;
    this.save();
  }

  save() {
    if (!this.storage) {
      return;
    }
    const visitedChoices = this.choices.filter(c => c.visited).map(c => c.id);

    this.storage.save({
      visitedChoices,
      flags: { ...this.flags },
      current: this.current
    });
  }

  load() {
    if (!this.storage) {
      return;
    }

    const data = this.storage.load();

    this.setVisited(data.visitedChoices);
    this.setFlags(data.flags);

    if (data.current) {
      // this.go(data.current);
      this.current = data.current;
    }
  }
}
