import orange from "../../assets/custom/textures/orange.jpg";
import darkpurple from "../../assets/custom/textures/darkpurple.jpg";
import purple from "../../assets/custom/textures/purple.jpg";
import glossy from "../../assets/custom/textures/glossy.jpg";
import { MeshBasicMaterial, MeshStandardMaterial } from "three";

AFRAME.registerComponent("custom-materials", {
  init: function () {
    if (this.el.object3D) {
      this.applyMaterials();
    }
    this.el.addEventListener("model-loaded", this.applyMaterials.bind(this));
  },
  applyMaterials: function () {
    this.el.object3D.traverse(o => {
      if (o.material) {

        const txLoader = new THREE.TextureLoader();
        switch (o.material.name) {
          case "DarkPurple_MAT":
            o.material = new THREE.MeshMatcapMaterial({ matcap: txLoader.load(darkpurple) });
            break;
          case "OrangeFresnel_MAT":
            o.material = new THREE.MeshMatcapMaterial({ matcap: txLoader.load(orange) });
            break;
          case "PurpleFresnel_MAT":
            o.material = new THREE.MeshMatcapMaterial({ matcap: txLoader.load(purple) });
            break;
          case "Glossy_MAT":
            o.material = new THREE.MeshMatcapMaterial({ matcap: txLoader.load(glossy) });
            break;
          case "GlossyMasked_MAT":
            if (o.material.isMeshPhysicalMaterial) {
              const prevMaterial = o.material;
              o.material = new MeshStandardMaterial();
              MeshBasicMaterial.prototype.copy.call(o.material, prevMaterial);
            }
            o.material.toneMapped = false;
            o.material.metalness = 0.9; // radio testing purpose
            o.material.needsUpdate = true;
            break;
        }
        o.material.toneMapped = false;
        o.material.needsUpdate = true;
      }
    });
  }
});

AFRAME.GLTFModelPlus.registerComponent("custom-materials", "custom-materials");
