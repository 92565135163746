import { styled, css } from "styled-components";
import { CloseButtonStyled } from "../../../ui/Button/CloseButton/styles";
import { Theme } from "../../../theme";

export type QuizModalStyledProps = {
  /**
   * Ширина и высота, за пределами которых кнопка будет
   * вынесена за пределы окна
   */
  orientation_breakpoints?: [keyof Theme["breakpoints"], keyof Theme["breakpoints"]];

  /**
   * Отступы для кнопки закрытия внутри окна и за пределаеми его
   */
  close_button_offset?: [number, number];
};

export const QuizModalStyled = styled.div<QuizModalStyledProps>(
  ({ theme, orientation_breakpoints, close_button_offset }) => {
    const { 0: minWidthBreakPointKey = "md", 1: minHeightBreakPointKey = "sm" } = orientation_breakpoints || [];

    /**
     * Определяем минимальную ширину и высоту,
     * при которых кнопка закрытия будет выходить за пределы окна
     */
    const minWidthBreakpoint = theme.breakpoints[minWidthBreakPointKey];
    const minHeightBreakpoint = theme.breakpoints[minHeightBreakPointKey];

    const { 0: closeButtonOffsetInner = 30, 1: closeButtonOffsetOuter = 130 } = close_button_offset || [];

    return css`
      position: absolute;
      z-index: 1000;

      ${CloseButtonStyled} {
        flex: none;
        position: absolute;
        right: 0px;
        top: 0px;
        transform: translate(-${closeButtonOffsetInner}%, ${closeButtonOffsetInner}%);

        /**
        Здесь если ширина и высота больше указанных порогов,
        кнопка будет перемещена за пределы окна
      */
        @media (min-width: ${minWidthBreakpoint}px) and (min-height: ${minHeightBreakpoint}px) {
          transform: translate(${closeButtonOffsetOuter}%, -${closeButtonOffsetOuter}%);
        }
      }
    `;
  }
);
