import React from "react";
import LitresImg from "../assets/images/litres.png";
import CopyImg from "../assets/images/copy.png";
import styles from "../assets/stylesheets/quiz-ui.scss";

type LitresBlock = {
  promocode: string;
  onClick: () => void;
  onClose: () => void;
};

export const LitresBlock: React.FC<LitresBlock> = ({ promocode, onClick, onClose }) => {
  return (
    <div className={styles.promocodeBlock}>
      {
        <div className={styles.closeBtn} onClick={onClose}>
          x
        </div>
      }
      <img src={LitresImg} className={styles.litresImg} />
      <span className={styles.presentText}>Выгодное предложение!</span>
      <span className={styles.descrText}>Книга из подборки, а также скидка 20% на остальной каталог ЛитРес!</span>
      <div onClick={onClick} className={styles.promoInput}>
        <span>{promocode}</span>
        <img src={CopyImg} />
      </div>
      <div className={styles.fullDescr}>
        <span className={styles.textAuth}>
          Для активации подарка необходимо авторизоваться по{" "}
          <a
            className={styles.link}
            href="https://www.litres.ru/?sberdemo=&utm_source=sberdemo&utm_campaign=sberdemo&from=loyalpr"
            target="_blank"
          >
            ссылке
          </a>
        </span>
        или ввести вручную промокод на сайте www.litres.ru в разделе "Промокод".
      </div>
      <a href="https://www.litres.ru/?sberdemo=&utm_source=sberdemo&utm_campaign=sberdemo&from=loyalpr" target="_blank">
        <div className={styles.linkBtn}>Перейти на сайт</div>
      </a>
    </div>
  );
};
