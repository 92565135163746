import dashjs from "dashjs";
import { HoloVideoObjectStates } from "./constants";
import { HoloVideoObjectF, IHoloVideoObject, HoloVideoObjectArrayBuffer } from "./interfaces";

export var HoloVideoObject: HoloVideoObjectF = function (
  context,
  d,
  e: IHoloVideoObject["errorCallback"],
  framecallback
) {
  if (HoloVideoObject._instanceCounter === undefined) {
    HoloVideoObject._instanceCounter = 0;
  }
  if (this.States === undefined) {
    this.States = HoloVideoObjectStates;
  }

  this.id = HoloVideoObject._instanceCounter++;
  this.state = HoloVideoObjectStates.Empty;
  this.suspended = !1;
  this.gl = context;
  this.audioVolume = 0;
  this.logLevel = 1;
  this.videoElements = [];
  this.errorCallback = e;
  this.framecallback = framecallback;

  d
    ? ((this.createOptions = d),
      2 > d.numAsyncFrames &&
        (this._logWarning("numAsyncFrames must be at least 2 (" + d.numAsyncFrames + " specified)"),
        (this.createOptions.numAsyncFrames = 2)))
    : (this.createOptions = {});
  this.createOptions.numAsyncFrames || (this.createOptions.numAsyncFrames = 3);

  //this.createOptions.disableAsyncDecode = true;

  document.addEventListener(
    "visibilitychange",
    function () {
      document.hidden
        ? this.state == HoloVideoObjectStates.Playing
          ? ((this.wasPlaying = !0), this._logInfo("document hidden -> pausing playback"), this.pause())
          : (this.wasPlaying = !1)
        : this.wasPlaying &&
          ((this.wasPlaying = !1), this._logInfo("document visible -> resuming playback"), this.play());
    }.bind(this)
  );
  d = context.canvas;
  d.addEventListener(
    "webglcontextlost",
    function (b: any) {
      this.contextLost = !0;
      this.wasPlaying = this.state == HoloVideoObjectStates.Playing;
      this.pause();
      this._logInfo("webglcontextlost -> pausing playback");
      this._releaseWebGLResources(context);
    }.bind(this),
    !1
  );
  d.addEventListener(
    "webglcontextrestored",
    function (b: any) {
      this._initializeWebGLResources(this.gl);
      if (this.json && this.outputBuffers) {
        b = HoloVideoObject._extName && this.json.extensions[HoloVideoObject._extName || ""];
        for (var f = this.gl, k = f.getParameter(f.ARRAY_BUFFER_BINDING), c = 0; 3 > c; ++c)
          f.bindBuffer(f.ARRAY_BUFFER, this.outputBuffers[c]),
            f.bufferData(f.ARRAY_BUFFER, 12 * b.maxVertexCount, f.STREAM_COPY);
        f.bindBuffer(f.ARRAY_BUFFER, k);
      }
      this.contextLost = !1;
      this.wasPlaying &&
        ((this.wasPlaying = !1), this._logInfo("webglcontextrestored -> resuming playback"), this.play());
    }.bind(this),
    !1
  );
  this._initializeWebGLResources(context);
};

// @ts-expect-error
HoloVideoObject.prototype._createProgram = function (a: any, d: any, e: any, b: any) {
  function f(c: any, p: any, m: any) {
    m = c.createShader(m);
    c.shaderSource(m, p);
    c.compileShader(m);
    return m;
  }

  var k = a.createProgram();
  d = f(a, d, a.VERTEX_SHADER);
  a.attachShader(k, d);
  a.deleteShader(d);
  e = f(a, e, a.FRAGMENT_SHADER);
  a.attachShader(k, e);
  a.deleteShader(e);
  b && b(k);
  a.linkProgram(k);
  (b = a.getProgramInfoLog(k)) && this._logError(b);
  (b = a.getShaderInfoLog(d)) && this._logError(b);
  (b = a.getShaderInfoLog(e)) && this._logError(b);
  return k;
};

// @ts-expect-error
HoloVideoObject.prototype._loadJSON = function (a: any, d: any) {
  var e = this,
    b = new XMLHttpRequest();
  b.overrideMimeType("application/json");
  b.onreadystatechange = function () {
    4 == b.readyState && 200 == b.status
      ? d(b.responseText, e)
      : 400 <= b.status &&
        (e._logError("_loadJSON failed for: " + a + ", XMLHttpRequest status = " + b.status),
        e._onError(HoloVideoObject.ErrorStates?.NetworkError));
  };
  b.onerror = function () {
    e._logError("_loadJSON XMLHttpRequest error for: " + a + ", status = " + b.status);
    e._onError(HoloVideoObject.ErrorStates?.NetworkError);
  };
  b.open("GET", a, !0);
  b.send(null);
  return b.responseText;
};

// @ts-expect-error
HoloVideoObject.prototype._loadArrayBuffer = function (
  src: string,
  resultCallback: (response: string | ArrayBuffer) => void
) {
  const instance = this;

  //const name = src.substring(src.lastIndexOf("/") + 1, src.length);

  const loader = new THREE.FileLoader();

  loader.setResponseType("arraybuffer");

  loader.load(
    // resource URL
    src,

    // onLoad callback
    function (data) {
      resultCallback(data);
    },

    // onProgress callback
    function (xhr) {
      //console.log( name + ' : '  +(xhr.loaded / xhr.total * 100) + '% loaded' );
    },

    // onError callback
    function (err) {
      console.error(err);
      instance._onError(HoloVideoObject.ErrorStates?.NetworkError);
    }
  );
};

HoloVideoObject.prototype._loadArrayBuffer2 = function (a, d) {
  var e = this,
    b = new XMLHttpRequest();
  // @ts-expect-error
  b.name = a.substring(a.lastIndexOf("/") + 1, a.length);
  b.responseType = "arraybuffer";
  b.onprogress = function (f) {
    f.lengthComputable &&
      e._logInfo(
        // @ts-expect-error
        b.name + " progress: " + Math.floor((f.loaded / f.total) * 100)
      );
  };
  b.onreadystatechange = function () {
    if (4 == b.readyState) {
      if (200 == b.status) {
        var f = b.response;
        f && d && d(f);
      } else
        400 <= b.status
          ? (e._logError("_loadArrayBuffer failed for: " + a + ", XMLHttpRequest status = " + b.status),
            e._onError(HoloVideoObject.ErrorStates?.NetworkError))
          : e._logWarning("_loadArrayBuffer unexpected status = " + b.status);
      e.httpRequest == b && (e.httpRequest = null);
    }
  };
  b.ontimeout = function () {
    e._logError("_loadArrayBuffer timeout");
    e._onError(HoloVideoObject.ErrorStates?.NetworkError);
  };
  b.open("GET", a, !0);
  b.send(null);
  this.httpRequest = b;
};
// @ts-expect-error
HoloVideoObject.prototype._startPlaybackIfReady = function (this: IHoloVideoObject) {
  this.state == HoloVideoObjectStates.Opening &&
    // @ts-expect-error
    this.buffersLoaded >= this.minBuffers &&
    // @ts-expect-error
    this.videosLoaded >= this.minVideos &&
    (this._logInfo("state -> Opened"),
    (this.state = HoloVideoObjectStates.Opened),
    // @ts-expect-error
    this.openOptions.autoplay && this.play());
  if (this.suspended) {
    var a = this.json?.extensions[HoloVideoObject._extName || ""].timeline;
    // @ts-expect-error
    a = this.json?.images[a[this.currentVideoIndex].image];
    a = a.video;
    (!a.paused && a.playing) ||
      !a.preloaded ||
      (this._logInfo("video " + a.mp4Name + " was suspended, resuming"), (this.suspended = !1), a.play());
  } else
    this.state == HoloVideoObjectStates.Playing &&
      ((a = this.json?.extensions[HoloVideoObject._extName || ""].timeline),
      // @ts-expect-error
      (a = this.json?.images[a[this.currentVideoIndex].image]),
      (a = a.video),
      a.playing || a.play());
};
HoloVideoObject.prototype._loadNextBuffer = function () {
  if (0 == this.freeArrayBuffers.length)
    this.openOptions.keepAllMeshesInMemory
      ? this._logInfo("All meshes loaded.")
      : this._logInfo("_loadNextBuffer: Waiting for next free buffer...");
  else {
    if (!this.json) {
      return;
    }

    var a = this.nextBufferLoadIndex;
    this.nextBufferLoadIndex = (this.nextBufferLoadIndex + 1) % (this.json?.buffers.length || 0);
    this.fallbackFrameBuffer && 0 == this.nextBufferLoadIndex && (this.nextBufferLoadIndex = 1);
    var d = this.json?.buffers[a],
      e = this.urlRoot + d.uri;
    d.loaded = !1;
    var b = -1;
    0 == a
      ? this._logInfo("loading preview frame buffer")
      : // @ts-expect-error
        ((b = this.freeArrayBuffers.shift()), this._logInfo("loading buffer: " + d.uri + " into slot " + b));
    this.pendingBufferDownload = !0;
    this._loadArrayBuffer(
      e,
      // Здесь точно f: ArrayBuffer
      function (this: IHoloVideoObject, f: HoloVideoObjectArrayBuffer) {
        this._logInfo("buffer loaded: " + d.uri);

        this.fallbackFrameBuffer || this.filledFallbackFrame
          ? (++this.buffersLoaded,
            (this.buffers[b] = f),
            (f.bufferIndex = a),
            (d.arrayBufferIndex = b),
            (d.loaded = !0),
            (this.needMeshData = this.pendingBufferDownload = !1),
            // @ts-expect-error
            this._startPlaybackIfReady(),
            // @ts-expect-error
            this._loadNextBuffer())
          : (this._logInfo("fallback frame buffer downloaded " + d.uri),
            // @ts-expect-error
            (this.fallbackFrameBuffer = f),
            // @ts-expect-error
            this._loadNextBuffer(),
            (this.pendingBufferDownload = !1));
      }.bind(this)
    );
  }
};
HoloVideoObject.prototype._loadNextVideo = function () {
  var a = this;

  function loadVideo(video: HTMLVideoElement, src: string, isSafari = false) {

    const elementPreloader = isSafari;

    if (elementPreloader) {
      video.src = src;
      return;
    }

    const loader = new THREE.FileLoader();

    loader.setResponseType("blob");

    loader.load(
      // resource URL
      src,

      // onLoad callback
      function (data: string) {

        //const blob = new Blob([data], {type: 'video/mp4'});
        // @ts-expect-error
        video.src = window.URL.createObjectURL(data);
      },

      // onProgress callback
      function (xhr) {
        //console.log( (xhr.loaded / xhr.total * 100) + '% loaded' );
      },

      // onError callback
      function (err) {
        console.error("An error happened");
      }
    );
  }

  if (0 != this.freeVideoElements.length) {
    var d = this.freeVideoElements.shift(),
      // @ts-expect-error
      e = this.videoElements[d];
    e.videoElementIndex = d;
    // @ts-expect-error
    d = this.nextVideoLoadIndex;
    var b = this.json?.extensions[HoloVideoObject._extName || ""].timeline.length;
    this.nextVideoLoadIndex = (this.nextVideoLoadIndex + 1) % b;
    // @ts-expect-error
    d = this.json.images[this.json.extensions[HoloVideoObject._extName || ""].timeline[d].image];
    // @ts-expect-error
    d.video = e;
    e.preloaded = !1;
    e.autoplay = !1;
    e.muted = this.openOptions.autoplay || !this.openOptions.audioEnabled;
    this.isSafari && (e.muted = !0);
    e.loop = 1 == b && this.openOptions.autoloop;
    e.preload = "auto";
    e.crossOrigin = "anonymous";
    e.playing = !1;
    e.preloaded = !1;
    // @ts-expect-error
    d.uri.split(".").pop();
    // @ts-expect-error
    b = d.extensions[HoloVideoObject._extName || ""];
    // @ts-expect-error
    void 0 === this.openOptions.streamMode && (this.openOptions.streamMode = HoloVideoObject.StreamMode?.Automatic);
    // @ts-expect-error
    var f = this.iOSVersion && 14 == this.iOSVersion.major && 6 > this.iOSVersion.minor;
    // @ts-expect-error
    !this.iOSVersion && 15 > this.safariVersion.major && (f = !0);
    this.openOptions.streamMode == HoloVideoObject.StreamMode?.HLS ||
    (this.openOptions.streamMode == HoloVideoObject.StreamMode?.Automatic &&
      // @ts-expect-error
      (this.isSafari || this.isMozillaWebXRViewer) &&
      b.hlsUri &&
      !f)
      ? ((e.src = this.urlRoot + b.hlsUri), (e.mp4Name = b.hlsUri))
      : this.openOptions.streamMode == HoloVideoObject.StreamMode?.Dash ||
        (this.openOptions.streamMode == HoloVideoObject.StreamMode?.Automatic &&
          !this.isSafari &&
          // @ts-expect-error
          !this.isMozillaWebXRViewer &&
          b.dashUri &&
          "undefined" != typeof dashjs)
      ? // @ts-expect-error
        (this.dashPlayer || ((this.dashPlayer = dashjs.MediaPlayer().create()), this.dashPlayer.initialize()),
        (f = this.urlRoot + b.dashUri),
        // @ts-expect-error
        this.dashPlayer.attachView(e),
        // @ts-expect-error
        this.dashPlayer.attachSource(f),
        (e.mp4Name = b.dashUri))
      : (console.log("Load MP4"),
        // @ts-expect-error
        (f = this.urlRoot + d.uri),
        loadVideo(e, f, this.isSafari),
        //e.src = f,
        // @ts-expect-error
        (e.mp4Name = d.uri));
    this._logInfo("loading video " + e.mp4Name);
    var k = this;
    e.addEventListener("canplay", function () {
      k.videoState = HoloVideoObject.VideoStates?.CanPlay;
    });
    e.addEventListener("play", function () {
      k.videoState = HoloVideoObject.VideoStates?.Playing;
    });
    e.addEventListener("canplaythrough", function () {
      k.videoState = HoloVideoObject.VideoStates?.CanPlayThrough;
    });
    e.addEventListener("waiting", function () {
      k.videoState = HoloVideoObject.VideoStates?.Waiting;
    });
    e.addEventListener("suspend", function () {
      k.videoState = HoloVideoObject.VideoStates?.Suspended;
    });
    e.addEventListener("stalled", function () {
      k.videoState = HoloVideoObject.VideoStates?.Stalled;
    });
    e.canplay = function () {
      a._logInfo("video -> canplay");
      a.videoState = HoloVideoObject.VideoStates?.CanPlay;
    };
    e.canplaythrough = function () {
      a._logInfo("video -> canplaythrough");
      a.videoState = HoloVideoObject.VideoStates?.CanPlayThrough;
    };
    e.waiting = function () {
      a._logInfo("video -> waiting");
      a.videoState = HoloVideoObject.VideoStates?.Waiting;
    };
    e.suspend = function () {
      a._logInfo("video -> suspend");
      a.videoState = HoloVideoObject.VideoStates?.Suspended;
    };

    e.stalled = function () {
      a._logInfo("video -> stalled");
      a.videoState = HoloVideoObject.VideoStates?.Stalled;
    };

    e.onerror = function (c: any) {
      a._logError("video error: " + c.target.error.code + " - " + c.target.mp4Name);
      // @ts-expect-error
      a._onError(HoloVideoObject.ErrorStates?.VideoError, c.target.error);
    };

    e.onended = function () {
      a.pendingVideoEndEvent = !0;
      // @ts-expect-error
      a.pendingVideoEndEventWaitCount = 0;
    };
    this.isSafari
      ? (e.onplaying = function () {
          e.pause();
          e.muted = this.openOptions.autoplay || !this.openOptions.audioEnabled;
          e.preloaded = !0;
          this._logInfo("video loaded: " + e.mp4Name);
          e.onplaying = function () {
            this._logInfo("video playing: " + e.mp4Name);
            e.playing = !0;
          }.bind(this);
          ++this.videosLoaded;
          this._startPlaybackIfReady();
          this._loadNextVideo();
        }.bind(this))
      : ((e.onloadeddata = function () {
          var c = e.play();
          void 0 !== c &&
            c
              .then(function (p: any) {})
              .catch(function (p: any) {
                e.onplaying();
              });
        }.bind(this)),
        (e.onplaying = function () {
          e.pause();
          e.preloaded = !0;
          this._logInfo("video loaded: " + e.mp4Name);
          e.onplaying = function () {
            this._logInfo("video playing: " + e.mp4Name);
            e.playing = !0;
          }.bind(this);
          ++this.videosLoaded;
          this._startPlaybackIfReady();
          this._loadNextVideo();
        }.bind(this)));
    this.isSafari &&
      ((d = e.play()),
      void 0 !== d &&
        // @ts-expect-error
        d.catch(function (c: any) {
          a._logWarning("play prevented: " + c);
          // @ts-expect-error
          a._onError(HoloVideoObject.ErrorStates?.PlaybackPrevented, c);
        }));
  }
};
HoloVideoObject.prototype.rewind = function () {
  if (this.json) {
    this._logInfo("rewind");
    var a =
      // @ts-expect-error
      this.json.images[this.json.extensions[HoloVideoObject._extName || ""].timeline[this.currentVideoIndex].image]
        .video;
    a.pause();
    a.playing = !1;
    a.currentTime = 0;
    this.pendingVideoEndEvent = !1;
    this.state = HoloVideoObjectStates.Opening;
    if (!this.openOptions.keepAllMeshesInMemory)
      for (
        this.freeArrayBuffers = [], a = 0;
        a < Math.min(this.openOptions?.maxBuffers ?? 0, this.json.buffers.length - 1);
        ++a
      )
        this.freeArrayBuffers.push(a);
    this.currentBufferIndex = 0;
    this.nextBufferLoadIndex = this.fallbackFrameBuffer ? 1 : 0;
    this.lastKeyframe = this.frameIndex = -1;
    this.nextPbo = 0;
    this.lastVideoSampleIndex = -1;
    this.filledFallbackFrame = !1;
    this.prevPrevMesh = this.prevMesh = this.curMesh = null;
    if (this.readFences)
      for (a = 0; a < this.readFences.length; ++a)
        // @ts-expect-error
        this.readFences[a] && (this.gl.deleteSync(this.readFences[a]), (this.readFences[a] = null));
    // @ts-expect-error
    this._loadNextBuffer();
    // @ts-expect-error
    this._loadFallbackFrame();
    // @ts-expect-error
    this._startPlaybackIfReady();
  }
};
HoloVideoObject.prototype.forceLoad = function () {
  var a = this;
  if (this.json) {
    var d =
      // @ts-expect-error
      this.json.images[this.json.extensions[HoloVideoObject._extName || ""].timeline[this.currentVideoIndex].image]
        .video;
    d.playing
      ? this._logInfo("forceLoad: video already playing")
      : d.preloaded ||
        (this._logInfo("forceLoad: manually starting video"),
        (this.suspended = !0),
        (d = d.play()),
        void 0 !== d &&
          d
            .then(function (e: any) {
              a.state = HoloVideoObjectStates.Playing;
            })
            .catch(function (e: any) {
              a._logWarning("play prevented: " + e);
              // @ts-expect-error
              a._onError(HoloVideoObject.ErrorStates?.PlaybackPrevented, e);
            }));
  } else this._logInfo("forceLoad: don't have json yet");
};
HoloVideoObject.prototype._onVideoEnded = function (a) {
  this._logInfo("video ended = " + a.mp4Name);
  this.freeVideoElements.push(a.videoElementIndex);
  a.videoElementIndex = -1;
  a = this.json?.extensions[HoloVideoObject._extName || ""].timeline;
  this.state = HoloVideoObjectStates.Opened;
  if (this.currentVideoIndex != a.length - 1 || this.openOptions.autoloop)
    (this.currentVideoIndex = (this.currentVideoIndex + 1) % a.length),
      // @ts-expect-error
      this._loadNextVideo(),
      // @ts-expect-error
      this._startPlaybackIfReady();
  // @ts-expect-error
  else if (((this.eos = !0), this.onEndOfStream)) this.onEndOfStream(this);
};
HoloVideoObject.prototype._setupTransformFeedback = function () {
  var a = this.gl;
  // @ts-expect-error
  this.outputBufferIndex = 0;
  // @ts-expect-error
  this.deltasBuf = a.createBuffer();
  // @ts-expect-error
  this.outputBuffers = [a.createBuffer(), a.createBuffer(), a.createBuffer()];
  // @ts-expect-error
  this.transformFeedbacks = [a.createTransformFeedback(), a.createTransformFeedback(), a.createTransformFeedback()];
  // @ts-expect-error
  this.vaos = [a.createVertexArray(), a.createVertexArray(), a.createVertexArray()];
  // @ts-expect-error
  a.bindVertexArray(null);
  // for (var d = 0; 3 > d; ++d)
  //   a.bindTransformFeedback(a.TRANSFORM_FEEDBACK, this.transformFeedbacks[d]),
  //     a.bindBufferBase(a.TRANSFORM_FEEDBACK_BUFFER, 0, this.outputBuffers[d]);
  for (var i = 0; 3 > i; ++i)
    // @ts-expect-error
    a.bindTransformFeedback(a.TRANSFORM_FEEDBACK, this.transformFeedbacks[i]),
      // @ts-expect-error
      a.bindBufferBase(a.TRANSFORM_FEEDBACK_BUFFER, 0, this.outputBuffers[i]);
  // @ts-expect-error
  this.normalsVao = a.createVertexArray();
  // @ts-expect-error
  this.normalsTF = a.createTransformFeedback();
  // @ts-expect-error
  a.bindTransformFeedback(a.TRANSFORM_FEEDBACK, null);
  // @ts-expect-error
  a.bindBuffer(a.TRANSFORM_FEEDBACK_BUFFER, null);
  // @ts-expect-error
  let d = this._createProgram(
    a,
    "#version 300 es\n            in vec3 inQuantized;\n            in vec3 prevPos;\n            in vec3 prevPrevPos;\n\n            uniform vec3 decodeMin;\n            uniform vec3 decodeMax;\n            uniform int havePrevPos;\n            uniform int havePrevPrevPos;\n\n            out vec3 outPos;\n\n            void main()\n            {\n                if (havePrevPos == 1)\n                {\n                    vec3 dm = vec3(0.0, 0.0, 0.0);\n\n                    if (havePrevPrevPos == 1)\n                    {\n                        dm = prevPos - prevPrevPos;\n                    }\n\n                    vec3 delta = (decodeMax - decodeMin) * inQuantized + decodeMin;\n                    outPos = prevPos + dm + delta;\n                }\n\n                else\n                {\n                    outPos = (decodeMax - decodeMin) * inQuantized + decodeMin;\n                }\n            }",
    "#version 300 es\n            out lowp vec4 fragColor;\n            void main()\n            {\n                fragColor = vec4(0,0,0,0);\n            }\n            ",
    function (e: any) {
      // @ts-expect-error
      a.transformFeedbackVaryings(e, ["outPos"], a.SEPARATE_ATTRIBS);
    }
  );
  d.havePrevPosLoc = a.getUniformLocation(d, "havePrevPos");
  d.havePrevPrevPosLoc = a.getUniformLocation(d, "havePrevPrevPos");
  d.decodeMinLoc = a.getUniformLocation(d, "decodeMin");
  d.decodeMaxLoc = a.getUniformLocation(d, "decodeMax");
  d.inQuantizedLoc = a.getAttribLocation(d, "inQuantized");
  d.prevPosLoc = a.getAttribLocation(d, "prevPos");
  d.prevPrevPosLoc = a.getAttribLocation(d, "prevPrevPos");
  // @ts-expect-error
  this.tfShader = d;
  // @ts-expect-error
  d = this._createProgram(
    a,
    "#version 300 es\n            in vec2 inOctNormal;\n            out vec3 outNormal;\n\n            vec3 OctDecode(vec2 f)\n            {\n                f = f * 2.0 - 1.0;\n\n                // https://twitter.com/Stubbesaurus/status/937994790553227264\n                vec3 n = vec3( f.x, f.y, 1.0 - abs(f.x) - abs(f.y));\n                float t = clamp(-n.z, 0.0, 1.0);\n                n.x += n.x >= 0.0 ? -t : t;\n                n.y += n.y >= 0.0 ? -t : t;\n                return normalize(n);\n            }\n\n            void main()\n            {\n                outNormal = OctDecode(inOctNormal);\n            }",
    "#version 300 es\n            out lowp vec4 fragColor;\n            void main()\n            {\n                fragColor = vec4(0,0,0,0);\n            }\n            ",
    function (e: any) {
      // @ts-expect-error
      a.transformFeedbackVaryings(e, ["outNormal"], a.SEPARATE_ATTRIBS);
    }
  );
  d.inOctNormalLoc = a.getAttribLocation(d, "inOctNormal");
  // @ts-expect-error
  this.octNormalsShader = d;
};
HoloVideoObject.prototype._updateMeshTF = function (a, d, e, b, f, k) {
  var c = this.gl;
  // @ts-expect-error
  a.outputBuffer = this.outputBuffers[this.outputBufferIndex];
  var p = c.getParameter(c.ARRAY_BUFFER_BINDING),
    m = c.getParameter(c.ELEMENT_ARRAY_BUFFER_BINDING),
    l = c.getParameter(c.CURRENT_PROGRAM),
    // @ts-expect-error
    n = c.getParameter(c.VERTEX_ARRAY_BINDING);
  // @ts-expect-error
  c.useProgram(this.tfShader);
  c.bindBuffer(c.ARRAY_BUFFER, null);
  // @ts-expect-error
  var g = this.tfShader;
  if (a.primitives[0].extensions[HoloVideoObject._extName || ""].attributes.POSITION) {
    this.lastKeyframe = this.frameIndex;
    c.bindBuffer(c.ELEMENT_ARRAY_BUFFER, b);
    c.bufferData(c.ELEMENT_ARRAY_BUFFER, k.indices, c.STATIC_DRAW);
    c.bindBuffer(c.ELEMENT_ARRAY_BUFFER, m);
    c.bindBuffer(c.ARRAY_BUFFER, e);
    c.bufferData(c.ARRAY_BUFFER, k.compressedUVs, c.STATIC_DRAW);
    // @ts-expect-error
    c.bindVertexArray(this.vaos[0]);
    this.prevPrevMesh = this.prevMesh = null;
    e = a.compressedPos.count;
    a.indexCount = a.indices.count;
    // @ts-expect-error
    c.bindBuffer(c.ARRAY_BUFFER, this.deltasBuf);
    c.bufferData(c.ARRAY_BUFFER, k.compressedPos, c.DYNAMIC_DRAW);
    c.enableVertexAttribArray(g.inQuantizedLoc);
    c.vertexAttribPointer(g.inQuantizedLoc, 3, a.compressedPos.componentType, !0, 0, 0);
    c.disableVertexAttribArray(g.prevPosLoc);
    c.disableVertexAttribArray(g.prevPrevPosLoc);
    b = a.compressedPos.extensions[HoloVideoObject._extName || ""].decodeMin;
    var h = a.compressedPos.extensions[HoloVideoObject._extName || ""].decodeMax;
    c.uniform3fv(g.decodeMinLoc, b);
    c.uniform3fv(g.decodeMaxLoc, h);
    this.currentFrameInfo.bboxMin = b;
    this.currentFrameInfo.bboxMax = h;
    c.uniform1i(g.havePrevPosLoc, 0);
    c.uniform1i(g.havePrevPrevPosLoc, 0);
  } else
    (e = a.deltas.count),
      // @ts-expect-error
      (a.indexCount = this.prevMesh.indexCount),
      // @ts-expect-error
      null == this.prevPrevMesh ? c.bindVertexArray(this.vaos[1]) : c.bindVertexArray(this.vaos[2]),
      // @ts-expect-error
      c.bindBuffer(c.ARRAY_BUFFER, this.deltasBuf),
      c.bufferData(c.ARRAY_BUFFER, k.deltas, c.DYNAMIC_DRAW),
      c.enableVertexAttribArray(g.inQuantizedLoc),
      c.vertexAttribPointer(g.inQuantizedLoc, 3, a.deltas.componentType, !0, 0, 0),
      c.uniform3fv(g.decodeMinLoc, a.deltas.extensions[HoloVideoObject._extName || ""].decodeMin),
      c.uniform3fv(g.decodeMaxLoc, a.deltas.extensions[HoloVideoObject._extName || ""].decodeMax),
      c.uniform1i(g.havePrevPosLoc, 1),
      // @ts-expect-error
      c.bindBuffer(c.ARRAY_BUFFER, this.prevMesh.outputBuffer),
      c.enableVertexAttribArray(g.prevPosLoc),
      c.vertexAttribPointer(g.prevPosLoc, 3, c.FLOAT, !1, 0, 0),
      null == this.prevPrevMesh
        ? (c.uniform1i(g.havePrevPrevPosLoc, 0), c.disableVertexAttribArray(g.prevPrevPosLoc))
        : (c.uniform1i(g.havePrevPrevPosLoc, 1),
          // @ts-expect-error
          c.bindBuffer(c.ARRAY_BUFFER, this.prevPrevMesh.outputBuffer),
          c.enableVertexAttribArray(g.prevPrevPosLoc),
          c.vertexAttribPointer(g.prevPrevPosLoc, 3, c.FLOAT, !1, 0, 0));
  g = 12 * e;
  c.bindBuffer(c.ARRAY_BUFFER, a.outputBuffer);
  c.bindBuffer(c.ARRAY_BUFFER, null);
  // @ts-expect-error
  c.bindTransformFeedback(c.TRANSFORM_FEEDBACK, this.transformFeedbacks[this.outputBufferIndex]);
  // @ts-expect-error
  c.enable(c.RASTERIZER_DISCARD);
  // @ts-expect-error
  c.beginTransformFeedback(c.POINTS);
  c.drawArrays(c.POINTS, 0, e);
  // @ts-expect-error
  c.endTransformFeedback();
  // @ts-expect-error
  c.disable(c.RASTERIZER_DISCARD);
  // @ts-expect-error
  c.bindTransformFeedback(c.TRANSFORM_FEEDBACK, null);
  // @ts-expect-error
  c.bindBuffer(c.TRANSFORM_FEEDBACK_BUFFER, null);
  // @ts-expect-error
  c.bindBuffer(c.COPY_READ_BUFFER, a.outputBuffer);
  // @ts-expect-error
  c.bindBuffer(c.COPY_WRITE_BUFFER, d);
  // @ts-expect-error
  c.bufferData(c.COPY_WRITE_BUFFER, g, c.DYNAMIC_COPY);
  // @ts-expect-error
  c.copyBufferSubData(c.COPY_READ_BUFFER, c.COPY_WRITE_BUFFER, 0, 0, g);
  // @ts-expect-error
  c.bindBuffer(c.COPY_READ_BUFFER, null);
  // @ts-expect-error
  c.bindBuffer(c.COPY_WRITE_BUFFER, null);
  // @ts-expect-error
  this.outputBufferIndex = (this.outputBufferIndex + 1) % 3;
  f &&
    k.compressedNormals &&
    (this.fileInfo.octEncodedNormals
      ? // @ts-expect-error
        (c.useProgram(this.octNormalsShader),
        c.bindBuffer(c.ARRAY_BUFFER, null),
        // @ts-expect-error
        c.bindVertexArray(this.normalsVao),
        // @ts-expect-error
        c.bindBuffer(c.ARRAY_BUFFER, this.deltasBuf),
        c.bufferData(c.ARRAY_BUFFER, k.compressedNormals, c.DYNAMIC_DRAW),
        // @ts-expect-error
        c.enableVertexAttribArray(this.octNormalsShader.inOctNormalLoc),
        // @ts-expect-error
        c.vertexAttribPointer(this.octNormalsShader.inOctNormalLoc, 2, c.UNSIGNED_BYTE, !0, 0, 0),
        (g = 12 * e),
        c.bindBuffer(c.ARRAY_BUFFER, f),
        c.bufferData(c.ARRAY_BUFFER, g, c.DYNAMIC_DRAW),
        c.bindBuffer(c.ARRAY_BUFFER, null),
        // @ts-expect-error
        c.bindTransformFeedback(c.TRANSFORM_FEEDBACK, this.normalsTF),
        // @ts-expect-error
        c.bindBufferBase(c.TRANSFORM_FEEDBACK_BUFFER, 0, f),
        // @ts-expect-error
        c.enable(c.RASTERIZER_DISCARD),
        // @ts-expect-error
        c.beginTransformFeedback(c.POINTS),
        c.drawArrays(c.POINTS, 0, e),
        // @ts-expect-error
        c.endTransformFeedback(),
        // @ts-expect-error
        c.disable(c.RASTERIZER_DISCARD),
        // @ts-expect-error
        c.bindTransformFeedback(c.TRANSFORM_FEEDBACK, null),
        // @ts-expect-error
        c.bindBuffer(c.TRANSFORM_FEEDBACK_BUFFER, null))
      : (c.bindBuffer(c.ARRAY_BUFFER, f), c.bufferData(c.ARRAY_BUFFER, k.compressedNormals, c.DYNAMIC_DRAW)));
  c.useProgram(l);
  c.bindBuffer(c.ARRAY_BUFFER, p);
  c.bindBuffer(c.ELEMENT_ARRAY_BUFFER, m);
  // @ts-expect-error
  c.bindVertexArray(n);
  return !0;
};
// @ts-expect-error
HoloVideoObject.prototype._updateMesh = function (this: HoloVideoObject, a, d, e, b) {
  this.frameIndex = (this.frameIndex + 1) % this.meshFrames.length;
  var f = this.meshFrames[this.frameIndex];
  if (!f.ensureBuffers()) return !1;
  this.prevPrevMesh && (this.prevPrevMesh.uncompressedPos = null);
  this.prevPrevMesh = this.prevMesh;
  this.prevMesh = this.curMesh;
  this.curMesh = f;
  var k = {
      indices: null,
      compressedPos: null,
      compressedUVs: null,
      compressedNormals: null,
      deltas: null
    },
    c = this.gl,
    p = this.json.buffers,
    m = this.json.bufferViews;
  if (f.primitives[0].extensions[HoloVideoObject._extName || ""].attributes.POSITION) {
    var l = p[m[f.indices.bufferView].buffer].arrayBufferIndex;
    var n = this.buffers[l],
      g = this.buffers[l],
      h = this.buffers[l];
    // @ts-expect-error
    k.indices =
      f.indices.componentType == c.UNSIGNED_SHORT
        ? new Uint16Array(n, m[f.indices.bufferView].byteOffset + f.indices.byteOffset, f.indices.count)
        : new Uint32Array(n, m[f.indices.bufferView].byteOffset + f.indices.byteOffset, f.indices.count);
    // @ts-expect-error
    k.compressedPos = new Uint16Array(
      g,
      m[f.compressedPos.bufferView].byteOffset + f.compressedPos.byteOffset,
      3 * f.compressedPos.count
    );
    // @ts-expect-error
    k.compressedUVs = new Uint16Array(
      h,
      m[f.compressedUVs.bufferView].byteOffset + f.compressedUVs.byteOffset,
      2 * f.compressedUVs.count
    );
  } else
    (l = p[m[f.deltas.bufferView].buffer].arrayBufferIndex),
      // @ts-expect-error
      (k.deltas = new Uint8Array(
        this.buffers[l],
        m[f.deltas.bufferView].byteOffset + f.deltas.byteOffset,
        3 * f.deltas.count
      ));
  l != this.currentBufferIndex &&
    (this._logInfo("currentBufferIndex -> " + l),
    this.openOptions.keepAllMeshesInMemory || this.freeArrayBuffers.push(this.currentBufferIndex),
    (this.currentBufferIndex = l),
    this.pendingBufferDownload || this._loadNextBuffer());
  null != f.compressedNormals &&
    ((p = this.buffers[p[m[f.compressedNormals.bufferView].buffer].arrayBufferIndex]),
    "VEC2" == f.compressedNormals.type
      ? // @ts-expect-error
        (k.compressedNormals = new Uint8Array(
          p,
          m[f.compressedNormals.bufferView].byteOffset + f.compressedNormals.byteOffset,
          2 * f.compressedNormals.count
        ))
      : "VEC3" == f.compressedNormals.type &&
        // @ts-expect-error
        (k.compressedNormals = new Uint16Array(
          p,
          m[f.compressedNormals.bufferView].byteOffset + f.compressedNormals.byteOffset,
          3 * f.compressedNormals.count
        )));
  if (this.caps.webgl2 && !this.caps.badTF) return this._updateMeshTF(f, a, d, e, b, k);
  m = c.getParameter(c.ARRAY_BUFFER_BINDING);
  p = c.getParameter(c.ELEMENT_ARRAY_BUFFER_BINDING);
  if (f.primitives[0].extensions[HoloVideoObject._extName || ""].attributes.POSITION) {
    this.lastKeyframe = this.frameIndex;
    this.prevMesh && (this.prevMesh = this.prevMesh.uncompressedPos = null);
    this.prevPrevMesh && (this.prevPrevMesh = this.prevPrevMesh.uncompressedPos = null);
    c.bindBuffer(c.ELEMENT_ARRAY_BUFFER, e);
    c.bufferData(c.ELEMENT_ARRAY_BUFFER, k.indices, c.DYNAMIC_DRAW);
    f.indexCount = f.indices.count;
    e = f.compressedPos.count;
    f.uncompressedPos = new Float32Array(3 * e);
    h = f.compressedPos.extensions[HoloVideoObject._extName || ""].decodeMin;
    l = f.compressedPos.extensions[HoloVideoObject._extName || ""].decodeMax;
    this.currentFrameInfo.bboxMin = h;
    this.currentFrameInfo.bboxMax = l;
    var q = (l[0] - h[0]) / 65535,
      t = (l[1] - h[1]) / 65535,
      x = (l[2] - h[2]) / 65535;
    for (l = 0; l < e; ++l) {
      var r = 3 * l,
        v = r + 1,
        u = r + 2;
      // @ts-expect-error
      f.uncompressedPos[r] = k.compressedPos[r] * q + h[0];
      // @ts-expect-error
      f.uncompressedPos[v] = k.compressedPos[v] * t + h[1];
      // @ts-expect-error
      f.uncompressedPos[u] = k.compressedPos[u] * x + h[2];
    }
    c.bindBuffer(c.ARRAY_BUFFER, a);
    c.bufferData(c.ARRAY_BUFFER, f.uncompressedPos, c.DYNAMIC_DRAW);
    c.bindBuffer(c.ARRAY_BUFFER, d);
    c.bufferData(c.ARRAY_BUFFER, k.compressedUVs, c.DYNAMIC_DRAW);
  } else {
    e = f.deltas.count;
    f.uncompressedPos = new Float32Array(3 * e);
    f.indexCount = this.prevMesh.indexCount;
    h = f.deltas.extensions[HoloVideoObject._extName || ""].decodeMin;
    l = f.deltas.extensions[HoloVideoObject._extName || ""].decodeMax;
    q = (l[0] - h[0]) / 255;
    t = (l[1] - h[1]) / 255;
    x = (l[2] - h[2]) / 255;
    var w = k.deltas;
    if (null == this.prevPrevMesh)
      for (l = 0; l < e; ++l) {
        r = 3 * l;
        v = r + 1;
        u = r + 2;
        d = this.prevMesh.uncompressedPos[r];
        n = this.prevMesh.uncompressedPos[v];
        g = this.prevMesh.uncompressedPos[u];
        // @ts-expect-error
        var y = w[r] * q + h[0],
          // @ts-expect-error
          z = w[v] * t + h[1],
          // @ts-expect-error
          A = w[u] * x + h[2];
        d += y;
        n += z;
        g += A;
        f.uncompressedPos[r] = d;
        f.uncompressedPos[v] = n;
        f.uncompressedPos[u] = g;
      }
    else
      for (l = 0; l < e; ++l)
        (r = 3 * l),
          (v = r + 1),
          (u = r + 2),
          (d = this.prevMesh.uncompressedPos[r]),
          (n = this.prevMesh.uncompressedPos[v]),
          (g = this.prevMesh.uncompressedPos[u]),
          (y = n - this.prevPrevMesh.uncompressedPos[v]),
          (z = g - this.prevPrevMesh.uncompressedPos[u]),
          (d += d - this.prevPrevMesh.uncompressedPos[r]),
          (n += y),
          (g += z),
          // @ts-expect-error
          (y = w[r] * q + h[0]),
          // @ts-expect-error
          (z = w[v] * t + h[1]),
          // @ts-expect-error
          (A = w[u] * x + h[2]),
          (d += y),
          (n += z),
          (g += A),
          (f.uncompressedPos[r] = d),
          (f.uncompressedPos[v] = n),
          (f.uncompressedPos[u] = g);
    c.bindBuffer(c.ARRAY_BUFFER, a);
    c.bufferData(c.ARRAY_BUFFER, f.uncompressedPos, c.DYNAMIC_DRAW);
  }
  if (b && k.compressedNormals)
    if (this.fileInfo.octEncodedNormals) {
      // @ts-expect-error
      e = k.compressedNormals.length;
      a = new Float32Array(3 * e);
      f = Math.abs;
      h = this._clamp;
      for (l = 0; l < e; ++l)
        (d = k.compressedNormals[2 * l]),
          (n = k.compressedNormals[2 * l + 1]),
          (d = -1 + 0.0078125 * d),
          (n = -1 + 0.0078125 * n),
          (g = 1 - f(d) - f(n)),
          (q = h(-g, 0, 1)),
          (d += 0 <= d ? -q : q),
          (n += 0 <= n ? -q : q),
          (q = 1 / Math.sqrt(d * d + n * n + g * g)),
          (a[3 * l] = d * q),
          (a[3 * l + 1] = n * q),
          (a[3 * l + 2] = g * q);
      c.bindBuffer(c.ARRAY_BUFFER, b);
      c.bufferData(c.ARRAY_BUFFER, a, c.DYNAMIC_DRAW);
    } else c.bindBuffer(c.ARRAY_BUFFER, b), c.bufferData(c.ARRAY_BUFFER, k.compressedNormals, c.DYNAMIC_DRAW);
  c.bindBuffer(c.ARRAY_BUFFER, m);
  c.bindBuffer(c.ELEMENT_ARRAY_BUFFER, p);
  return !0;
};
// @ts-expect-error
HoloVideoObject.prototype._clamp = function (a, d, e) {
  return a < d ? d : a > e ? e : a;
};
// @ts-expect-error
HoloVideoObject.prototype._onJsonLoaded = function (a) {
  this._logInfo(this.capsStr);
  this._logInfo("got json");
  var d = (this.json = JSON.parse(a));
  this.openOptions.keepAllMeshesInMemory && (this.openOptions.maxBuffers = this.json.buffers.length - 1);
  this.minBuffers = Math.min(this.openOptions.minBuffers, this.json.buffers.length - 1);
  this.minVideos = Math.min(2, this.json.extensions[HoloVideoObject._extName || ""].timeline.length);
  this.buffers = [null, null, null];
  0 == this.videoElements.length && (this.videoElements = [document.createElement("video")]);
  this.videoElements[0].setAttribute("playsinline", "playsinline");
  this.videoElements[0].volume = this.audioVolume;
  this.freeVideoElements.push(0);
  for (a = 0; a < Math.min(this.openOptions.maxBuffers, this.json.buffers.length - 1); ++a)
    this.freeArrayBuffers.push(a);
  this._loadNextVideo();
  this._loadNextBuffer();
  this.currentBufferIndex = 0;
  var e = this.json.accessors,
    b = this.json.meshes.length,
    f = this.buffers,
    k = this,
    c = function () {
      var l = d.bufferViews,
        n = d.buffers;
      if (this.primitives[0].extensions[HoloVideoObject._extName || ""].attributes.POSITION) {
        var g = l[this.indices.bufferView];
        if (void 0 == n[g.buffer].arrayBufferIndex || f[n[g.buffer].arrayBufferIndex].bufferIndex != g.buffer)
          return k._logInfo("buffer for frame " + this.frameIndex + " not downloaded yet: " + n[g.buffer].uri), !1;
        g = l[this.compressedPos.bufferView];
        if (void 0 == n[g.buffer].arrayBufferIndex || f[n[g.buffer].arrayBufferIndex].bufferIndex != g.buffer)
          return k._logInfo("buffer for frame " + this.frameIndex + " not downloaded yet: " + n[g.buffer].uri), !1;
        g = l[this.compressedUVs.bufferView];
        if (void 0 == n[g.buffer].arrayBufferIndex || f[n[g.buffer].arrayBufferIndex].bufferIndex != g.buffer)
          return k._logInfo("buffer for frame " + this.frameIndex + " not downloaded yet: " + n[g.buffer].uri), !1;
      } else if (
        ((g = l[this.deltas.bufferView]),
        void 0 == n[g.buffer].arrayBufferIndex || f[n[g.buffer].arrayBufferIndex].bufferIndex != g.buffer)
      )
        return k._logInfo("buffer for frame " + this.frameIndex + " not downloaded yet: " + n[g.buffer].uri), !1;
      return this.compressedNormals &&
        ((l = l[this.compressedNormals.bufferView]),
        void 0 == n[l.buffer].arrayBufferIndex || f[n[l.buffer].arrayBufferIndex].bufferIndex != l.buffer)
        ? (k._logInfo("buffer for frame " + this.frameIndex + " not downloaded yet: " + n[l.buffer].uri), !1)
        : !0;
    };
  for (a = 0; a < b; ++a) {
    var p = this.json.meshes[a];
    p.frameIndex = a;
    p.ensureBuffers = c;
    var m = p.primitives[0].extensions[HoloVideoObject._extName || ""].attributes;
    m.POSITION
      ? ((p.indices = e[p.primitives[0].extensions[HoloVideoObject._extName || ""].indices]),
        (p.compressedUVs = e[m.TEXCOORD_0]),
        (p.compressedPos = e[m.POSITION]))
      : (p.deltas = e[m._DELTA]);
    null != m.NORMAL &&
      ((this.fileInfo.haveNormals = !0),
      (p.compressedNormals = e[m.NORMAL]),
      "VEC2" == p.compressedNormals.type && (this.fileInfo.octEncodedNormals = !0));
    this.meshFrames.push(p);
  }
  a = this.json.images[1].extensions[HoloVideoObject._extName || ""];
  this.fileInfo.videoWidth = a.width;
  this.fileInfo.videoHeight = a.height;
  e = this.json.extensions[HoloVideoObject._extName || ""];
  this.fileInfo.maxVertexCount = e.maxVertexCount;
  this.fileInfo.maxIndexCount = e.maxIndexCount;
  this.fileInfo.boundingBox = {
    min: e.boundingMin,
    max: e.boundingMax
  };
  if (this.onLoaded) this.onLoaded(this.fileInfo);
  if (this.outputBuffers) {
    b = this.gl;
    c = b.getParameter(b.ARRAY_BUFFER_BINDING);
    for (a = 0; 3 > a; ++a)
      b.bindBuffer(b.ARRAY_BUFFER, this.outputBuffers[a]),
        b.bufferData(b.ARRAY_BUFFER, 12 * e.maxVertexCount, b.STREAM_COPY);
    b.bindBuffer(b.ARRAY_BUFFER, c);
  }
};
// @ts-expect-error
HoloVideoObject.prototype._getChromeVersion = function () {
  var a = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
  return a ? parseInt(a[2], 10) : !1;
};
// @ts-expect-error
HoloVideoObject.prototype._getIOSVersion = function () {
  var a = window.navigator.userAgent;
  if (0 < a.indexOf("iPhone") || 0 < a.indexOf("iPad")) {
    // @ts-expect-error
    if ((a = a.match(/OS (\d+)_(\d+)_?(\d+)?/)))
      return {
        // @ts-expect-error
        major: parseInt(a[1] || 0, 10),
        // @ts-expect-error
        minor: parseInt(a[2] || 0, 10),
        // @ts-expect-error
        patch: parseInt(a[3] || 0, 10)
      };
  }
  return !1;
};
// @ts-expect-error
HoloVideoObject.prototype._getSafariVersion = function () {
  var a = window.navigator.userAgent.match(/Version\/(\d+).(\d+).?(\d+)?/);
  return a
    ? {
        // @ts-expect-error
        major: parseInt(a[1] || 0, 10),
        // @ts-expect-error
        minor: parseInt(a[2] || 0, 10),
        // @ts-expect-error
        patch: parseInt(a[3] || 0, 10)
      }
    : !1;
};
// @ts-expect-error
HoloVideoObject.prototype._logDebug = function (a, d) {
  3 <= this.logLevel && console.log("[" + this.id + "] " + a);
};
// @ts-expect-error
HoloVideoObject.prototype._logInfo = function (a, d) {
  (2 <= this.logLevel || d) && console.log("[" + this.id + "] " + a);
};
// @ts-expect-error
HoloVideoObject.prototype._logWarning = function (a) {
  1 <= this.logLevel && console.log("[" + this.id + "] " + a);
};
// @ts-expect-error
HoloVideoObject.prototype._logError = function (a) {
  0 <= this.logLevel && console.log("[" + this.id + "] " + a);
};
// @ts-expect-error
HoloVideoObject.prototype._onError = function (a, d) {
  this.errorCallback && this.errorCallback(a, d);
};
HoloVideoObject.prototype._initializeWebGLResources = function (a) {
  var d = {},
    e = a.getParameter(a.VERSION);
  // @ts-expect-error
  d.webgl2 = -1 != e.indexOf("WebGL 2.");
  // @ts-expect-error
  d.badTF = !1;
  this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  // @ts-expect-error
  this.safariVersion = this._getSafariVersion();
  // @ts-expect-error
  this.isMozillaWebXRViewer = (this.iOSVersion = this._getIOSVersion()) && navigator.userAgent.includes("WebXRViewer");
  navigator.userAgent.includes("Mobile") &&
    "iPhone" != navigator.platform &&
    "iPad" != navigator.platform &&
    (this.isSafari = !1);
  // @ts-expect-error
  this.isSafari && (d.webgl2 = !1);
  if ((e = a.getExtension("WEBGL_debug_renderer_info")))
    // @ts-expect-error
    (d.vendor = a.getParameter(e.UNMASKED_VENDOR_WEBGL)),
      // @ts-expect-error
      (d.renderer = a.getParameter(e.UNMASKED_RENDERER_WEBGL)),
      // @ts-expect-error
      (d.isSafari = this.isSafari),
      // @ts-expect-error
      (d.iOSVersion = this.iOSVersion),
      // @ts-expect-error
      -1 != d.renderer.indexOf("Mali") && (d.badTF = !0);
  // @ts-expect-error
  this.capsStr = JSON.stringify(d, null, 4);
  // @ts-expect-error
  this.caps = d;
  // @ts-expect-error
  this.fbo1 = a.createFramebuffer();
  // @ts-expect-error
  this.caps.webgl2
    ? // @ts-expect-error
      ((this.caps.supports32BitIndices = !0),
      // @ts-expect-error
      this.caps.badTF || this._setupTransformFeedback(),
      this.createOptions.disableAsyncDecode
        ? // @ts-expect-error
          (this.textures = [null])
        : // @ts-expect-error
          ((this.fbo2 = a.createFramebuffer()),
          // @ts-expect-error
          (this.textures = Array(this.createOptions.numAsyncFrames)),
          // @ts-expect-error
          (this.pixelBuffers = Array(this.createOptions.numAsyncFrames)),
          (this.readFences = Array(this.createOptions.numAsyncFrames)),
          (this.nextPbo = 0)))
    : // @ts-expect-error
      ((this.caps.supports32BitIndices = null != a.getExtension("OES_element_index_uint")),
      // @ts-expect-error
      this.caps.supports32BitIndices ||
        this._logWarning(
          "WebGL1: extension 'OES_element_index_uint' not supported, captures w/32-bit index data will not be playable"
        ),
      // @ts-expect-error
      (this.textures = [null]),
      // @ts-expect-error
      (this.fbo2 = a.createFramebuffer()),
      // @ts-expect-error
      (d = this._createProgram(
        a,
        "#version 100\n            attribute mediump vec2 pos;\n            varying mediump vec2 uv;\n            void main()\n            {\n                uv = (0.5 * pos + vec2(0.5, 0.5));\n                gl_Position = vec4(pos, 0.0, 1.0);\n            }\n            ",
        "#version 100\n            uniform lowp sampler2D textureSampler;\n            varying mediump vec2 uv;\n            void main()\n            {\n                gl_FragColor = texture2D(textureSampler, uv);\n                //gl_FragColor = vec4(1.0, 1.0, 0.0, 1.0);\n            }\n            ",
        // @ts-expect-error
        function (b) {
          a.bindAttribLocation(b, 0, "pos");
        }
      )),
      // @ts-expect-error
      (d.vertexAttribLoc = 0),
      // @ts-expect-error
      (d.textureSamplerLoc = a.getUniformLocation(d, "textureSampler")),
      // @ts-expect-error
      (this.texCopyShader = d),
      // @ts-expect-error
      (this.texCopyShader.vertexAttribLoc = 0),
      // @ts-expect-error
      (this.texCopyVerts = a.createBuffer()),
      // @ts-expect-error
      a.bindBuffer(a.ARRAY_BUFFER, this.texCopyVerts),
      a.bufferData(a.ARRAY_BUFFER, new Float32Array([-1, 3, 3, -1, -1, -1]), a.STATIC_DRAW),
      a.bindBuffer(a.ARRAY_BUFFER, null));
  d = a.getParameter(a.TEXTURE_BINDING_2D);
  // @ts-expect-error
  for (e = 0; e < this.textures.length; ++e)
    // @ts-expect-error
    (this.textures[e] = a.createTexture()),
      // @ts-expect-error
      a.bindTexture(a.TEXTURE_2D, this.textures[e]),
      a.texParameteri(a.TEXTURE_2D, a.TEXTURE_WRAP_S, a.CLAMP_TO_EDGE),
      a.texParameteri(a.TEXTURE_2D, a.TEXTURE_WRAP_T, a.CLAMP_TO_EDGE),
      a.texParameteri(a.TEXTURE_2D, a.TEXTURE_MAG_FILTER, a.LINEAR),
      a.texParameteri(a.TEXTURE_2D, a.TEXTURE_MIN_FILTER, a.LINEAR);
  a.bindTexture(a.TEXTURE_2D, d);
};
// @ts-expect-error
HoloVideoObject.prototype._releaseWebGLResources = function (a) {
  if (this.caps.webgl2 && !this.caps.badTF) {
    a.deleteBuffer(this.deltasBuf);
    for (var d = 0; 3 > d; ++d)
      a.deleteBuffer(this.outputBuffers[d]),
        (this.outputBuffers[d] = null),
        a.deleteTransformFeedback(this.transformFeedbacks[d]),
        (this.transformFeedbacks[d] = null),
        a.deleteVertexArray(this.vaos[d]),
        (this.vaos[d] = null);
    a.deleteTransformFeedback(this.normalsTF);
    this.normalsTF = null;
    a.deleteVertexArray(this.normalsVao);
    this.normalsVao = null;
    a.deleteProgram(this.tfShader);
    this.tfShader = null;
    a.deleteProgram(this.octNormalsShader);
    this.octNormalsShader = null;
  }
  this.texCopyShader && (a.deleteProgram(this.texCopyShader), (this.texCopyShader = null));
  this.texCopyVerts && (a.deleteBuffer(this.texCopyVerts), (this.texCopyVerts = null));
  if (this.pixelBuffers)
    for (d = 0; d < this.pixelBuffers.length; ++d) a.deleteBuffer(this.pixelBuffers[d]), (this.pixelBuffers[d] = null);
  if (this.readFences)
    for (d = 0; d < this.readFences.length; ++d) a.deleteSync(this.readFences[d]), (this.readFences[d] = null);
  for (d = this.nextPbo = 0; d < this.textures.length; ++d) a.deleteTexture(this.textures[d]);
  this.fbo1 && (a.deleteFramebuffer(this.fbo1), (this.fbo1 = null));
  this.fbo2 && (a.deleteFramebuffer(this.fbo2), (this.fbo2 = null));
};
// @ts-expect-error
HoloVideoObject.prototype.getLoadProgress = function () {
  return void 0 == this.minBuffers
    ? 0
    : this.state >= HoloVideoObjectStates.Opened
    ? 1
    : (this.buffersLoaded + this.videosLoaded) / (this.minBuffers + this.minVideos);
};
// @ts-expect-error
HoloVideoObject.prototype.setBuffers = function (a, d, e, b, f) {
  var k = {};
  // @ts-expect-error
  k.posBuf = a;
  // @ts-expect-error
  k.indexBuf = d;
  // @ts-expect-error
  k.uvBuf = e;
  // @ts-expect-error
  k.norBuf = b;
  // @ts-expect-error
  k.tex = f;
  this.clientBuffers = k;
};
// @ts-expect-error
HoloVideoObject.prototype.updateToLastKeyframe = function () {
  -1 != this.lastKeyframe &&
    ((this.frameIndex = this.lastKeyframe - 1), (this.prevPrevMesh = this.prevMesh = this.curMesh = null));
};
// @ts-expect-error
HoloVideoObject.prototype._loadFallbackFrame = function () {
  if (this.json && this.fallbackFrameBuffer) {
    if (!this.fallbackTextureImage) {
      this.fallbackTextureImage = new Image();
      var a = this.json.bufferViews[this.json.images[0].bufferView];
      this.fallbackTextureImage.src =
        "data:image/jpeg;base64," +
        (function (c) {
          for (var p = "", m, l, n, g, h, q, t = 0; t < c.length; )
            (m = c[t++]),
              (l = t < c.length ? c[t++] : Number.NaN),
              (n = t < c.length ? c[t++] : Number.NaN),
              (g = m >> 2),
              (m = ((m & 3) << 4) | (l >> 4)),
              (h = ((l & 15) << 2) | (n >> 6)),
              (q = n & 63),
              isNaN(l) ? (h = q = 64) : isNaN(n) && (q = 64),
              (p +=
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=".charAt(g) +
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=".charAt(m) +
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=".charAt(h) +
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=".charAt(q));
          return p;
        })(new Uint8Array(this.fallbackFrameBuffer, a.byteOffset, a.byteLength));
      this.fallbackTextureImage.onload = function () {
        this._logInfo("fallback image loaded");
        this.fallbackTextureImage.loaded = !0;
      }.bind(this);
    }
    if (
      this.fallbackTextureImage &&
      this.fallbackTextureImage.loaded &&
      !this.filledFallbackFrame &&
      this.clientBuffers &&
      this.clientBuffers.posBuf
    ) {
      a = this.gl;
      var d = this.json.meshes[0].primitives[0],
        e = a.getParameter(a.ARRAY_BUFFER_BINDING),
        b = a.getParameter(a.ELEMENT_ARRAY_BUFFER_BINDING),
        f = this.json.accessors[d.attributes.POSITION],
        k = this.json.bufferViews[f.bufferView];
      a.bindBuffer(a.ARRAY_BUFFER, this.clientBuffers.posBuf);
      a.bufferData(
        a.ARRAY_BUFFER,
        new Float32Array(this.fallbackFrameBuffer, k.byteOffset + f.byteOffset, 3 * f.count),
        a.STATIC_DRAW
      );
      this.clientBuffers.norBuf &&
        this.fileInfo.haveNormals &&
        ((f = this.json.accessors[d.attributes.NORMAL]),
        (k = this.json.bufferViews[f.bufferView]),
        a.bindBuffer(a.ARRAY_BUFFER, this.clientBuffers.norBuf),
        a.bufferData(
          a.ARRAY_BUFFER,
          new Float32Array(this.fallbackFrameBuffer, k.byteOffset + f.byteOffset, 3 * f.count),
          a.STATIC_DRAW
        ));
      f = this.json.accessors[d.attributes.TEXCOORD_0];
      k = this.json.bufferViews[f.bufferView];
      a.bindBuffer(a.ARRAY_BUFFER, this.clientBuffers.uvBuf);
      a.bufferData(
        a.ARRAY_BUFFER,
        new Uint16Array(this.fallbackFrameBuffer, k.byteOffset + f.byteOffset, 2 * f.count),
        a.STATIC_DRAW
      );
      f = this.json.accessors[d.indices];
      k = this.json.bufferViews[f.bufferView];
      a.bindBuffer(a.ELEMENT_ARRAY_BUFFER, this.clientBuffers.indexBuf);
      f.componentType == a.UNSIGNED_SHORT
        ? a.bufferData(
            a.ELEMENT_ARRAY_BUFFER,
            new Uint16Array(this.fallbackFrameBuffer, k.byteOffset + f.byteOffset, f.count),
            a.STATIC_DRAW
          )
        : a.bufferData(
            a.ELEMENT_ARRAY_BUFFER,
            new Uint32Array(this.fallbackFrameBuffer, k.byteOffset + f.byteOffset, f.count),
            a.STATIC_DRAW
          );
      a.bindBuffer(a.ARRAY_BUFFER, e);
      a.bindBuffer(a.ELEMENT_ARRAY_BUFFER, b);
      a.pixelStorei(a.PACK_ALIGNMENT, 4);
      a.pixelStorei(a.UNPACK_FLIP_Y_WEBGL, !1);
      a.pixelStorei(a.UNPACK_PREMULTIPLY_ALPHA_WEBGL, !1);
      e = a.getParameter(a.TEXTURE_BINDING_2D);
      a.bindTexture(a.TEXTURE_2D, this.clientBuffers.tex);
      a.texImage2D(a.TEXTURE_2D, 0, a.RGBA, a.RGBA, a.UNSIGNED_BYTE, this.fallbackTextureImage);
      a.bindTexture(a.TEXTURE_2D, e);
      this.currentFrameInfo.primCount = f.count;
      f = this.json.accessors[d.extensions[HoloVideoObject._extName || ""].attributes.POSITION];
      a = f.extensions[HoloVideoObject._extName || ""].decodeMax;
      this.currentFrameInfo.bboxMin = f.extensions[HoloVideoObject._extName || ""].decodeMin;
      this.currentFrameInfo.bboxMax = a;
      this.filledFallbackFrame = !0;
    }
    return this.filledFallbackFrame;
  }
};
HoloVideoObject.prototype.updateBuffers = function () {
  if (this.contextLost) return !1;
  // @ts-expect-error
  if (!this.filledFallbackFrame) return this._loadFallbackFrame();
  var a =
      // @ts-expect-error
      this.json?.images[this.json?.extensions[HoloVideoObject._extName || ""].timeline[this.currentVideoIndex].image],
    d = a.video,
    e = !1;
  if (d && d.playing && !this.suspended) {
    var b = window.performance.now();
    /**
     * Здесь определяется количество кадров в секунду
     */
    if (60 > b - this.lastUpdate) return !1;

    this.lastVideoTime = 1e3 * d.currentTime;
    this.lastUpdate = b;

    // @ts-expect-error
    b = this.gl;
    // @ts-expect-error
    this.watermarkPixels ||
      // @ts-expect-error
      (this.watermarkPixels = new Uint8Array(4 * a.extensions[HoloVideoObject._extName || ""].width));
    var f = -1,
      // @ts-expect-error
      k = b.getParameter(b.FRAMEBUFFER_BINDING),
      // @ts-expect-error
      c = b.getParameter(b.TEXTURE_BINDING_2D),
      // @ts-expect-error
      p = this.caps.webgl2 && !this.createOptions.disableAsyncDecode;
    if (p) {
      // @ts-expect-error
      var m = (this.nextPbo + 1) % this.pixelBuffers.length;
      if (null != this.readFences[m]) {
        // @ts-expect-error
        b.getSyncParameter(this.readFences[m], b.SYNC_STATUS);
        // @ts-expect-error
        b.deleteSync(this.readFences[m]);
        this.readFences[m] = null;
        // @ts-expect-error
        b.bindBuffer(b.PIXEL_PACK_BUFFER, this.pixelBuffers[m]);
        // @ts-expect-error
        b.getBufferSubData(b.PIXEL_PACK_BUFFER, 0, this.watermarkPixels, 0, this.watermarkPixels.byteLength);
        var l = 4 * a.extensions[HoloVideoObject._extName || ""].blockSize;
        for (a = f = 0; 16 > a; ++a)
          // @ts-expect-error
          if (128 < this.watermarkPixels[l * a] || 128 < this.watermarkPixels[l * a + 4]) f += 1 << a;
      }
      // @ts-expect-error
      this.pixelBuffers[this.nextPbo] ||
        // @ts-expect-error
        ((this.pixelBuffers[this.nextPbo] = b.createBuffer()),
        // @ts-expect-error
        b.bindBuffer(b.PIXEL_PACK_BUFFER, this.pixelBuffers[this.nextPbo]),
        // @ts-expect-error
        b.bufferData(b.PIXEL_PACK_BUFFER, this.watermarkPixels.byteLength, b.DYNAMIC_READ));
      // @ts-expect-error
      b.pixelStorei(b.PACK_ALIGNMENT, 4);
      // @ts-expect-error
      b.pixelStorei(b.UNPACK_FLIP_Y_WEBGL, !1);
      // @ts-expect-error
      b.pixelStorei(b.UNPACK_PREMULTIPLY_ALPHA_WEBGL, !1);
      // @ts-expect-error
      b.bindTexture(b.TEXTURE_2D, this.textures[this.nextPbo]);
      // @ts-expect-error
      b.texImage2D(b.TEXTURE_2D, 0, b.RGBA, b.RGBA, b.UNSIGNED_BYTE, d);
      // @ts-expect-error
      b.bindFramebuffer(b.FRAMEBUFFER, this.fbo1);
      // @ts-expect-error
      b.framebufferTexture2D(b.FRAMEBUFFER, b.COLOR_ATTACHMENT0, b.TEXTURE_2D, this.textures[this.nextPbo], 0);
      // @ts-expect-error
      b.bindBuffer(b.PIXEL_PACK_BUFFER, this.pixelBuffers[this.nextPbo]);
      // @ts-expect-error
      b.readPixels(0, 0, this.watermarkPixels.byteLength / 4, 1, b.RGBA, b.UNSIGNED_BYTE, 0);
      // @ts-expect-error
      b.bindFramebuffer(b.FRAMEBUFFER, null);
      // @ts-expect-error
      b.getError() == b.NO_ERROR
        ? // @ts-expect-error
          ((this.readFences[this.nextPbo] = b.fenceSync(b.SYNC_GPU_COMMANDS_COMPLETE, 0)),
          // @ts-expect-error
          (this.nextPbo = (this.nextPbo + 1) % this.pixelBuffers.length))
        : this._logWarning("webgl error: " + n + " skipping video texture read");
    } else {
      // @ts-expect-error
      b.pixelStorei(b.PACK_ALIGNMENT, 4);
      // @ts-expect-error
      b.pixelStorei(b.UNPACK_FLIP_Y_WEBGL, !1);
      // @ts-expect-error
      b.pixelStorei(b.UNPACK_PREMULTIPLY_ALPHA_WEBGL, !1);
      // @ts-expect-error
      b.bindTexture(b.TEXTURE_2D, this.textures[0]);
      // @ts-expect-error
      b.texImage2D(b.TEXTURE_2D, 0, b.RGBA, b.RGBA, b.UNSIGNED_BYTE, d);
      // @ts-expect-error
      var n = b.getError();
      // @ts-expect-error
      if (n == b.NO_ERROR) {
        // @ts-expect-error
        b.bindFramebuffer(b.FRAMEBUFFER, this.fbo1);
        // @ts-expect-error
        b.framebufferTexture2D(b.FRAMEBUFFER, b.COLOR_ATTACHMENT0, b.TEXTURE_2D, this.textures[0], 0);
        // @ts-expect-error
        b.readPixels(0, 0, this.watermarkPixels.byteLength / 4, 1, b.RGBA, b.UNSIGNED_BYTE, this.watermarkPixels);
        l = 4 * a.extensions[HoloVideoObject._extName || ""].blockSize;
        for (a = f = 0; 16 > a; ++a)
          // @ts-expect-error
          if (128 < this.watermarkPixels[l * a] || 128 < this.watermarkPixels[l * a + 4]) f += 1 << a;
        n = !0;
        // @ts-expect-error
        if (0 == f && f < this.lastVideoSampleIndex) {
          // @ts-expect-error
          for (a = 0; a < this.watermarkPixels.byteLength; ++a)
            // @ts-expect-error
            if (0 != this.watermarkPixels[a]) {
              n = !1;
              break;
            }
          if (n) return this._logWarning("dropping empty/black video frame"), (this.currentFrameInfo.primCount = 0), !0;
        }
      } else this._logWarning("webgl error: " + n + " skipping video texture read");
    }
    if (-1 < f) {
      // @ts-expect-error
      if (null == this.curMesh || this.curMesh.frameIndex != f) {
        // @ts-expect-error
        if ((this._logDebug("videoSampleIndex -> " + f), this.meshFrames[f].ensureBuffers())) {
          // @ts-expect-error
          const isLoop = f < this.lastVideoSampleIndex;

          if (isLoop) {
            this.onLoopDetected && this.onLoopDetected(this);

            (this.frameIndex = -1),
              // @ts-expect-error
              this._updateMesh(
                // @ts-expect-error
                this.clientBuffers.posBuf,
                // @ts-expect-error
                this.clientBuffers.uvBuf,
                // @ts-expect-error
                this.clientBuffers.indexBuf,
                // @ts-expect-error
                this.clientBuffers.norBuf
              ),
              this._logInfo(
                // @ts-expect-error
                "loop detected, videoSampleIndex = " + f + ", curMesh.frameIndex = " + this.curMesh.frameIndex
              );
          }

          for (
            ;
            // @ts-expect-error
            (null == this.curMesh || this.curMesh.frameIndex < f) &&
            // @ts-expect-error
            this._updateMesh(
              // @ts-expect-error
              this.clientBuffers.posBuf,
              // @ts-expect-error
              this.clientBuffers.uvBuf,
              // @ts-expect-error
              this.clientBuffers.indexBuf,
              // @ts-expect-error
              this.clientBuffers.norBuf
            );

          );
          // @ts-expect-error
          this._logDebug("updated to frame index = " + f);
          // @ts-expect-error
          if (this.curMesh.frameIndex == f)
            if (((n = d.videoWidth), (a = d.videoHeight), p))
              // @ts-expect-error
              b.bindFramebuffer(b.READ_FRAMEBUFFER, this.fbo1),
                // @ts-expect-error
                b.framebufferTexture2D(b.READ_FRAMEBUFFER, b.COLOR_ATTACHMENT0, b.TEXTURE_2D, this.textures[m], 0),
                // @ts-expect-error
                b.readBuffer(b.COLOR_ATTACHMENT0),
                // @ts-expect-error
                b.bindFramebuffer(b.DRAW_FRAMEBUFFER, this.fbo2),
                // @ts-expect-error
                b.framebufferTexture2D(
                  // @ts-expect-error
                  b.DRAW_FRAMEBUFFER,
                  // @ts-expect-error
                  b.COLOR_ATTACHMENT0,
                  // @ts-expect-error
                  b.TEXTURE_2D,
                  // @ts-expect-error
                  this.clientBuffers.tex,
                  0
                ),
                // @ts-expect-error
                b.drawBuffers([b.COLOR_ATTACHMENT0]),
                // @ts-expect-error
                b.blitFramebuffer(0, 0, n, a, 0, 0, n, a, b.COLOR_BUFFER_BIT, b.NEAREST);
            else {
              // @ts-expect-error
              m = this.texCopyShader;
              // @ts-expect-error
              p = b.getParameter(b.ARRAY_BUFFER_BINDING);
              // @ts-expect-error
              l = b.getParameter(b.CURRENT_PROGRAM);
              // @ts-expect-error
              var g = b.getParameter(b.VIEWPORT),
                // @ts-expect-error
                h = b.isEnabled(b.SCISSOR_TEST),
                // @ts-expect-error
                q = b.isEnabled(b.CULL_FACE),
                // @ts-expect-error
                t = b.isEnabled(b.BLEND),
                // @ts-expect-error
                x = b.getParameter(b.ACTIVE_TEXTURE),
                // @ts-expect-error
                r = b.getVertexAttrib(m.vertexAttribLoc, b.VERTEX_ATTRIB_ARRAY_BUFFER_BINDING),
                // @ts-expect-error
                v = b.getVertexAttrib(m.vertexAttribLoc, b.VERTEX_ATTRIB_ARRAY_ENABLED),
                // @ts-expect-error
                u = b.getVertexAttrib(m.vertexAttribLoc, b.VERTEX_ATTRIB_ARRAY_SIZE),
                // @ts-expect-error
                w = b.getVertexAttrib(m.vertexAttribLoc, b.VERTEX_ATTRIB_ARRAY_TYPE),
                // @ts-expect-error
                y = b.getVertexAttrib(m.vertexAttribLoc, b.VERTEX_ATTRIB_ARRAY_NORMALIZED),
                // @ts-expect-error
                z = b.getVertexAttrib(m.vertexAttribLoc, b.VERTEX_ATTRIB_ARRAY_STRIDE),
                // @ts-expect-error
                A = b.getVertexAttribOffset(m.vertexAttribLoc, b.VERTEX_ATTRIB_ARRAY_POINTER);
              // @ts-expect-error
              b.bindFramebuffer(b.FRAMEBUFFER, this.fbo2);
              // @ts-expect-error
              b.framebufferTexture2D(b.FRAMEBUFFER, b.COLOR_ATTACHMENT0, b.TEXTURE_2D, this.clientBuffers.tex, 0);
              // @ts-expect-error
              b.viewport(0, 0, n, a);
              // @ts-expect-error
              b.disable(b.SCISSOR_TEST);
              // @ts-expect-error
              b.disable(b.CULL_FACE);
              // @ts-expect-error
              b.disable(b.BLEND);
              // @ts-expect-error
              b.clear(b.COLOR_BUFFER_BIT);
              // @ts-expect-error
              b.activeTexture(b.TEXTURE0);
              // @ts-expect-error
              b.bindTexture(b.TEXTURE_2D, this.textures[0]);
              // @ts-expect-error
              b.useProgram(m);
              // @ts-expect-error
              b.uniform1i(m.textureSamplerLoc, 0);
              // @ts-expect-error
              b.bindBuffer(b.ARRAY_BUFFER, this.texCopyVerts);
              // @ts-expect-error
              b.enableVertexAttribArray(m.vertexAttribLoc);
              // @ts-expect-error
              b.vertexAttribPointer(m.vertexAttribLoc, 2, b.FLOAT, !1, 0, 0);
              // @ts-expect-error
              b.drawArrays(b.TRIANGLES, 0, 3);
              // @ts-expect-error
              b.useProgram(l);
              // @ts-expect-error
              b.bindBuffer(b.ARRAY_BUFFER, r);
              // @ts-expect-error
              b.vertexAttribPointer(m.vertexAttribLoc, u, w, y, z, A);
              // @ts-expect-error
              v ? b.enableVertexAttribArray(m.vertexAttribLoc) : b.disableVertexAttribArray(m.vertexAttribLoc);
              // @ts-expect-error
              b.bindBuffer(b.ARRAY_BUFFER, p);
              // @ts-expect-error
              b.viewport(g[0], g[1], g[2], g[3]);
              // @ts-expect-error
              h && b.enable(b.SCISSOR_TEST);
              // @ts-expect-error
              t && b.enable(b.BLEND);
              // @ts-expect-error
              q && b.enable(b.CULL_FACE);
              // @ts-expect-error
              b.activeTexture(x);
            }
          this.curMesh &&
            // @ts-expect-error
            this.curMesh.frameIndex != f &&
            // @ts-expect-error
            this._logInfo("texture (" + f + ") <-> mesh (" + this.curMesh.frameIndex + ") mismatch");
          this.lastVideoSampleIndex = f;
        } else
          this._logWarning("ran out of mesh data, suspending video " + d.mp4Name),
            d.pause(),
            (this.needMeshData = this.suspended = !0),
            // @ts-expect-error
            this.pendingBufferDownload || this._loadNextBuffer();
      } else
        this.pendingVideoEndEvent &&
          this.state == HoloVideoObjectStates.Playing &&
          // @ts-expect-error
          (this.pendingVideoEndEventWaitCount++, (e = 3 < this.pendingVideoEndEventWaitCount));
    }
    // @ts-expect-error
    b.bindFramebuffer(b.FRAMEBUFFER, k);
    // @ts-expect-error
    b.bindTexture(b.TEXTURE_2D, c);
  }
  this.pendingVideoEndEvent &&
    (this.lastVideoSampleIndex == this.meshFrames.length - 1 || e) &&
    // @ts-expect-error
    ((d.playing = !1), this._onVideoEnded(d), (this.pendingVideoEndEvent = !1));
  if (this.curMesh) {
    // @ts-expect-error
    this.currentFrameInfo.primCount = this.curMesh.indexCount;
    // @ts-expect-error
    this.currentFrameInfo.frameIndex = this.curMesh.frameIndex;
    // @ts-expect-error
    if (this.onUpdateCurrentFrame) this.onUpdateCurrentFrame(this.curMesh.frameIndex);
    return !0;
  }
  return !1;
};
HoloVideoObject.prototype.close = function () {
  this.httpRequest && (this.httpRequest.abort(), (this.httpRequest = null));
  // @ts-expect-error
  this.dashPlayer && this.dashPlayer.reset();
  for (var a = 0; a < this.videoElements.length; ++a)
    this.videoElements[a].pause(), this.videoElements[a].removeAttribute("src");
  this.state = HoloVideoObjectStates.Closed;
};
HoloVideoObject.prototype.pause = function () {
  0 < this.videoElements.length &&
    this.videoElements[this.currentVideoIndex] &&
    (this.videoElements[this.currentVideoIndex].pause(), (this.state = HoloVideoObjectStates.Paused));
};
HoloVideoObject.prototype.setAudioVolume = function (a) {
  // @ts-expect-error
  this.audioVolume = a;
  // @ts-expect-error
  this.videoElements[this.currentVideoIndex].volume = a;
};
HoloVideoObject.prototype.setAutoLooping = function (a) {
  this.openOptions.autoloop = a;
  this.videoElements[this.currentVideoIndex].loop = a;
};
HoloVideoObject.prototype.setAudioEnabled = function (a) {
  this.videoElements[this.currentVideoIndex].muted = !a;
};
HoloVideoObject.prototype.audioEnabled = function () {
  return !this.videoElements[this.currentVideoIndex].muted;
};
HoloVideoObject.prototype.play = function () {
  var a = this;
  this.isSafari && this.videoElements[this.currentVideoIndex].pause();
  var d = this.videoElements[this.currentVideoIndex].play();
  void 0 !== d &&
    d
      .then(function (e) {
        a.state = HoloVideoObjectStates.Playing;
      })
      .catch(function (e) {
        a._logWarning("play prevented: " + e);
        // @ts-expect-error
        a._onError(HoloVideoObject.ErrorStates.PlaybackPrevented, e);
      });
};
HoloVideoObject.prototype.open = function (a, d) {
  this.state >= HoloVideoObjectStates.Opening && this.close();
  this.state = HoloVideoObjectStates.Opening;
  this.urlRoot = a.substring(0, a.lastIndexOf("/") + 1);
  this.meshFrames = [];
  this.videosLoaded = this.buffersLoaded = 0;
  this.freeArrayBuffers = [];
  this.freeVideoElements = [];
  this.buffers = [];
  void 0 === this.videoElements && (this.videoElements = []);
  this.nextBufferLoadIndex = this.nextVideoLoadIndex = 0;
  // @ts-expect-error
  this.videoState = HoloVideoObject.VideoStates.Undefined;
  this.currentFrameInfo = {
    primCount: 0
  };
  this.currentVideoIndex = 0;
  this.currentBufferIndex = -1;
  this.lastUpdate = this.lastVideoTime = 0;
  this.json = null;
  this.fileInfo = {
    haveNormals: !1,
    octEncodedNormals: !1
  };
  this.openOptions = d ? d : {};
  this.openOptions.minBuffers || (this.openOptions.minBuffers = 2);
  this.openOptions.maxBuffers || (this.openOptions.maxBuffers = 3);
  if (this.readFences)
    // @ts-expect-error
    for (d = 0; d < this.readFences.length; ++d)
      // @ts-expect-error
      this.readFences[d] && (this.gl.deleteSync(this.readFences[d]), (this.readFences[d] = null));
  this.nextPbo = 0;
  this.prevPrevMesh = this.prevMesh = this.curMesh = null;
  this.lastVideoSampleIndex = this.lastKeyframe = this.frameIndex = -1;
  this.filledFallbackFrame = !1;
  this.fallbackTextureImage = this.fallbackFrameBuffer = null;
  this.eos = !1;
  this._loadJSON(a, this._onJsonLoaded.bind(this));
};

HoloVideoObject._instanceCounter = 0;

HoloVideoObject.States = HoloVideoObjectStates;

HoloVideoObject.ErrorStates = {
  NetworkError: -1,
  VideoError: -2,
  PlaybackPrevented: -3
};
HoloVideoObject.StreamMode = {
  Automatic: 0,
  MP4: 1,
  HLS: 2,
  Dash: 3
};
HoloVideoObject.VideoStates = {
  Undefined: 0,
  CanPlay: 1,
  CanPlayThrough: 2,
  Waiting: 3,
  Suspended: 4,
  Stalled: 5,
  Playing: 6
};
HoloVideoObject._extName = "HCAP_holovideo";
HoloVideoObject.Version = {};
HoloVideoObject.Version.Major = 1;
HoloVideoObject.Version.Minor = 3;
HoloVideoObject.Version.Patch = 7;
HoloVideoObject.Version.String =
  HoloVideoObject.Version.Major + "." + HoloVideoObject.Version.Minor + "." + HoloVideoObject.Version.Patch;

