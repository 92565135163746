import React from 'react'
import OptionsButton from '../../../ui/IndividualComponents/Buttons/OptionsButton/OptionsButton';


type AnswerProps = {
  isClicked: boolean;
  onClick: () => void;
  correct: boolean;
  value: string;
  isZvuk: boolean;
};

export const QuizAnswer: React.FC<AnswerProps> = ({ isClicked, onClick, correct, value, isZvuk }) => {
  return (
    <OptionsButton
      text={value}
      animation="color"
      onClick={onClick}
      correct={isClicked ? (correct ? `correct` : `notcorrect`) : ``}
    ></OptionsButton>
  );
};
