import { ReactNode } from "react";
import ReactDOM from "react-dom";

type MainViewportModalProps = {
  children: ReactNode;
};

// TODO Надо избавиться от этого компонента, так как он рендерит не в боди, а .mainViewport,
// а у него свои стили, но в сторибук этого компонента нет

/**
 * @deprecated Этот компонент будет удален
 */
export const MainViewportModal: React.FC<MainViewportModalProps> = ({ children }) => {

  /**
   * Надо учитывать, что mainViewport имеет свои стили (в том чисте display: flex)
   * и размещает объекты по центру.
   * Компоненты, которые рендерятся в сам боди, таких родительских стилей не имеют.
   */
  const container = window.document.querySelector(".mainViewport") || window.document.body;

  return ReactDOM.createPortal(children, container);
};
