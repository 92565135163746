import React, { FC } from "react";

import { ModalWindow } from "../ui/StructureComponents/ModalWindow/ModalWindow";
import { FlexLayout } from "../ui/LayoutComponents/FlexLayout/FlexLayout";
import { IndividualComponentsCloseButton } from "../ui/IndividualComponents/Buttons/CloseButton/CloseButton";
import { AspectRatioImage } from "../ui/IndividualComponents/AspectRatioImages/AspectRatioImage/AspectRatioImage";
import { PillButton } from "../ui/IndividualComponents/Buttons/PillButton/PillButton";

import customStyles from "../ui/Stylization/CustomModal.style.scss";

interface Props {
  windowTitle: string;
  IconCat: string;
  collectedAll: number;
  TOTAL: number;
  text: string | JSX.Element;
  closeModal: () => void;
}

export const CollectModalWindow: FC<Props> = ({ windowTitle, IconCat, collectedAll, TOTAL, text = "", closeModal }) => {
  return (
    <ModalWindow
      LayoutStructure={
        <FlexLayout
          stylesParameters={{ direction: "column", align: "center", justify: "flex-start" }}
          className={customStyles.row}
        >
          <h5 className={customStyles.title}>{windowTitle}</h5>

          <AspectRatioImage img={IconCat} className={customStyles.ModalIcon}></AspectRatioImage>

          <div className={customStyles.total}>
            <b>{collectedAll}</b>/{TOTAL}
          </div>

          <div className={customStyles.text}>{text}</div>
          <PillButton
            text="Хорошо!"
            onClick={closeModal}
            className={customStyles.continueBtn}
            animationDirection="right"
          ></PillButton>
        </FlexLayout>
      }
      CloseButton={<IndividualComponentsCloseButton className={customStyles.closeBtn} onClick={closeModal}></IndividualComponentsCloseButton>}
      handleClose={closeModal}
      className={customStyles.modalWindow}
    ></ModalWindow>
  );
};
