import React, { FC } from "react";
import { CloseBtnLineStyled, CloseButtonInnerStyled, CloseButtonStyled } from "./styles";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  className?: string;
}

/**
 * Кнопка закрыть. Расширяет HTMLButtonElement и имеет все атрибуты кнопки. В частности *onClick* и т.д.
 * Заданная структура открывает широкие возможности для кастомизации и создания производительных анимаций
 * с использованием псевдо-элементов(::before/::after) и свойств opacity/transform
 */
export const CloseButton: FC<Props> = props => {
  return (
    <CloseButtonStyled {...props}>
      <CloseButtonInnerStyled>
        <CloseBtnLineStyled />
        <CloseBtnLineStyled />
      </CloseButtonInnerStyled>
    </CloseButtonStyled>
  );
};
