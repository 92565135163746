import React, { AllHTMLAttributes } from "react";
import { QuizModalStyled, QuizModalStyledProps } from "./styles";
import { CloseButton } from "../../../ui/Button/CloseButton";

export type QuizModalProps = React.PropsWithChildren<
  QuizModalStyledProps & {
    closeHandler: (event: React.MouseEvent) => void;
  } & AllHTMLAttributes<HTMLDivElement>
>;

/**
 * Важно учитывать!: Этот компонент сейчас не рассчитан на рендеринг в document.body,
 * он для тех компонентов, которые рендерятся в .mainViewport через компонент MainViewportModal.
 * Здесь не прописаны отступы от краев и размеры основного контейнера (берется от родительского контейнера).
 * И здесь сейчас нет фонового затемнения на всю страницу.
 */
export const QuizModal: React.FC<QuizModalProps> = ({ children, closeHandler, orientation_breakpoints, ...other }) => {
  return (
    <QuizModalStyled {...other} orientation_breakpoints={orientation_breakpoints}>
      <CloseButton onClick={closeHandler} />

      {children}
    </QuizModalStyled>
  );
};
