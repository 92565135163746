import React from "react";
import ReactDom from "react-dom";
import { MainViewportModal } from "../../react-components/modals/MainViewportModal";
import styles from "./styles.scss";
import closeBtnFillerDefault from "./assets/images/cross_01.png";
import closeBtnFillerActive from "./assets/images/cross_02.png";

export type PhotoMessageModalProps = {
  body: {
    src: string;
  };
  closeModal: () => void;
};

export const PhotoMessageModal: React.FC<PhotoMessageModalProps> = ({ body: { src }, closeModal }) => {
  return ReactDom.createPortal(
    <div className={styles.Modal}>
      <div className={styles.ModalOverlay} onClick={closeModal}></div>
      <div className={styles.ModalWrapper}>
        <div className={styles.ModalInnerWrapper}>
          <img className={styles.banner} src={src} />
        </div>

        <button className={styles.closeBtn} onClick={closeModal} aria-label="Закрыть окно">
          x
        </button>

        <a className={styles.shareBtn} href={src} download="MetaSelfi">
          Скачать фото
        </a>
      </div>
    </div>,
    document.body
  );
};
