import React, { FC } from "react";
import styles from "./CloseButton.module.scss";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  className?: string;
}

/**
* Кнопка закрыть. Расширяет HTMLButtonElement и имеет все атрибуты кнопки. В частности *onClick* и т.д.
* Заданная структура открывает широкие возможности для кастомизации и создания производительных анимаций с использованием псевдо-элементов(::before/::after) и свойств opacity/transform
### Props
  - **className** (*string*): Возможность задать класс для кастомизации положения/стилей кнопки
*/
export const IndividualComponentsCloseButton: FC<Props> = ({ onClick, className = "" }) => {
  return (
    <button className={`${styles.CloseBtnWrapper} ${className}`} onClick={onClick}>
      <div className={`${styles.CloseBtn}`}>
        <span className={styles.CloseBtnLine}></span>
        <span className={styles.CloseBtnLine}></span>
      </div>
    </button>
  );
};
