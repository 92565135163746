import { useCallback, useEffect, useState } from "react";

type useCollectProps = {
  storageKey: string;
  collectCollisionEventName: string;
  total: number
};

export function useCollect({ storageKey, collectCollisionEventName, total }: useCollectProps) {
  // All finded
  const [finded, findedSetter] = useState<string[]>([]);

  /**
   * Получаем исходное состояние хранилища и фиксируем в стейт
   */
  const initData = useCallback(() => {
    let data: string[] = [];

    try {
      const collections = localStorage.getItem(storageKey);
      data = collections ? JSON.parse(collections) : [];

      if (!Array.isArray(data)) {
        data = [];
      }
    } catch (error) {
      console.error(error);
    }

    /**
     * Если есть уже найденные, надо удалить их из сцены
     */
    if (data.length) {
      /**
       * Получаем все ноды объектов из загруженной сцены
       */
      const nodes = [...document.querySelectorAll("#environment-scene > [gltf-model-plus] > [environment-settings] *")];

      nodes.forEach(node => {
        // @ts-expect-error
        const mesh: AElementECS["object3D"] | undefined = node.getObject3D("mesh");

        const { eventId, objectId } = mesh?.userData || {};

        if (eventId === collectCollisionEventName && objectId) {
          /**
           * Если id объекта совпадает, удаляем узел
           */
          if (data.includes(objectId.toString())) {
            node.remove();
          }
        }
      });
    }

    /**
     * Фиксируем количество найденных
     */
    findedSetter(data);

    return;
  }, []);

  /**
   * Первичная инициализация данных
   */
  useEffect(() => {
    initData();
  }, []);

  /**
   * Сохраняем измененное состояние в хранилище
   */
  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(finded));
  }, [finded]);

  /**
   * Навешиваем обработчик на обновление окрежения сцены, так
   * как при быстром переходе в другие хабы нет загрузки страницы
   * и не срабатывает инициализация
   */
  useEffect(() => {
    const environmentScene = document.querySelector("#environment-scene");

    if (!environmentScene) {
      return;
    }

    environmentScene.addEventListener("model-loaded", initData);

    return () => {
      environmentScene.removeEventListener("model-loaded", initData);
    };
  }, []);


  const collectedAll = finded.length <= total ? finded.length : total;

  return {
    finded,
    findedSetter,
    collectedAll,
  };
}
