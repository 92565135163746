import "./jscomp";
import * as THREE from "three";
import { IHoloVideoObject, HoloVideoObjectThreeJSF, IHoloVideoObjectThreeJS, HvoFileInfo } from "./interfaces";
import { HoloVideoObjectStates } from "./constants";
import { HoloVideoObject } from "./HoloVideoObject";
import { Mesh } from "three";

export const HoloVideoObjectThreeJS: HoloVideoObjectThreeJSF = function (renderer, mesh_callback, e, b, f) {
  // IHoloVideoObject
  // var k: IHoloVideoObject = new (HoloVideoObject as any)(renderer.getContext(), e, f);
  var hvo: IHoloVideoObject = new (HoloVideoObject as any)(renderer.getContext(), e, f);
  this.hvo = hvo;
  this.renderer = renderer;

  // @ts-expect-error
  hvo.onEndOfStream = this._hvoOnEndOfStream.bind(this);
  // @ts-expect-error
  hvo.onUpdateCurrentFrame = b;
  // @ts-expect-error
  hvo.onLoaded = function (this: IHoloVideoObjectThreeJS, p: HvoFileInfo) {

    this.fileInfo = p;
    const haveNormals = p.haveNormals;

    const material = new THREE.MeshBasicMaterial({
      map: null,
      transparent: !1,
      side: THREE.DoubleSide
    });

    const material2 = new THREE.MeshLambertMaterial({
      map: null,
      transparent: !1,
      side: THREE.DoubleSide
    });

    var g = this.hvo.meshFrames[0].indices.componentType;

    const renderingContext = renderer.getContext();
    if (this.mesh)
      if (
        // @ts-expect-error
        this.mesh.geometry.getIndex().type != g
      )
        // @ts-expect-error
        this.mesh = null;
      else {
        var q = haveNormals ? material2 : material;
        // @ts-expect-error
        q.map = this.mesh.material.map;
        this.mesh.material = q;
      }
    if (!this.mesh) {
      var t = new THREE.BufferGeometry();
      t.boundingSphere = new THREE.Sphere();
      t.boundingSphere.set(new THREE.Vector3(), Infinity);
      t.boundingBox = new THREE.Box3();
      t.boundingBox.set(
        new THREE.Vector3(-Infinity, -Infinity, -Infinity),
        new THREE.Vector3(Infinity, Infinity, Infinity)
      );
      var x = renderingContext.createBuffer(),
        r =
          // @ts-expect-error
          120 <= THREE.REVISION
            ? // @ts-expect-error
              new THREE.GLBufferAttribute(x, renderingContext.FLOAT, 3, 0)
            : // @ts-expect-error
              new THREE.GLBufferAttribute(renderingContext, x, renderingContext.FLOAT, 3, 0);

      // @ts-expect-error
      r.getX = function (position) {
        return this.buffer[this.elementSize * position];
      };
      // @ts-expect-error
      r.getY = function (position) {
        return this.buffer[this.elementSize * (position + 1)];
      };
      // @ts-expect-error
      r.getZ = function (position) {
        return this.buffer[this.elementSize * (position + 2)];
      };

      // @ts-expect-error
      t.setAttribute("position", r);
      // @ts-expect-error
      r = null;
      if (haveNormals) {
        // @ts-expect-error
        r = renderingContext.createBuffer();
        var v =
          // @ts-expect-error
          120 <= THREE.REVISION
            ? // @ts-expect-error
              new THREE.GLBufferAttribute(r, renderingContext.FLOAT, 3, 0)
            : // @ts-expect-error
              new THREE.GLBufferAttribute(renderingContext, r, renderingContext.FLOAT, 3, 0);
        // @ts-expect-error
        t.setAttribute("normal", v);
      }
      // @ts-expect-error
      v = renderingContext.createBuffer();

      const uv =
        // @ts-expect-error
        120 <= THREE.REVISION
          ? // @ts-expect-error
            new THREE.GLBufferAttribute(v, renderingContext.UNSIGNED_SHORT, 2, 0)
          : // @ts-expect-error
            new THREE.GLBufferAttribute(renderingContext, v, renderingContext.UNSIGNED_SHORT, 2, 0);
      // @ts-expect-error
      uv.normalized = !0;
      // @ts-expect-error
      t.setAttribute("uv", uv);

      const u = renderingContext.createBuffer();

      // @ts-expect-error
      g =
        120 <= parseFloat(THREE.REVISION)
          ? // @ts-expect-error
            new THREE.GLBufferAttribute(u, g, 0, 0)
          : // @ts-expect-error
            new THREE.GLBufferAttribute(renderingContext, u, g, 0, 0);

      // @ts-expect-error
      g.getX = function (position) {
        return this.buffer[this.elementSize * position];
      };

      // @ts-expect-error
      t.setIndex(g);
      var w = new THREE.Texture();
      w.encoding = THREE.sRGBEncoding;
      g = renderer.properties.get(w);
      // @ts-expect-error
      g.__webglTexture = renderingContext.createTexture();
      q = renderingContext.getParameter(renderingContext.TEXTURE_BINDING_2D);
      renderingContext.bindTexture(
        renderingContext.TEXTURE_2D,
        // @ts-expect-error
        g.__webglTexture
      );
      renderingContext.texImage2D(
        renderingContext.TEXTURE_2D,
        0,
        renderingContext.RGBA,
        // @ts-expect-error
        p.videoWidth,
        p.videoHeight,
        0,
        renderingContext.RGBA,
        renderingContext.UNSIGNED_BYTE,
        null
      );
      renderingContext.texParameteri(
        renderingContext.TEXTURE_2D,
        renderingContext.TEXTURE_WRAP_S,
        renderingContext.CLAMP_TO_EDGE
      );
      renderingContext.texParameteri(
        renderingContext.TEXTURE_2D,
        renderingContext.TEXTURE_WRAP_T,
        renderingContext.CLAMP_TO_EDGE
      );
      renderingContext.texParameteri(
        renderingContext.TEXTURE_2D,
        renderingContext.TEXTURE_MIN_FILTER,
        renderingContext.LINEAR
      );
      renderingContext.texParameteri(
        renderingContext.TEXTURE_2D,
        renderingContext.TEXTURE_MAG_FILTER,
        renderingContext.LINEAR
      );
      renderingContext.bindTexture(renderingContext.TEXTURE_2D, q);
      q = haveNormals ? material2 : material;
      q.map = w;
      {
        const p: Mesh = new THREE.Mesh(t, q);
        p.scale.x = 0.001;
        p.scale.y = 0.001;
        p.scale.z = 0.001;
        // @ts-expect-error
        hvo.setBuffers(x, u, v, r, g.__webglTexture);
        this.mesh = p;
        this.bufferGeometry = t;
      }
    }
    this.state = this.hvo.state;

    mesh_callback(this.mesh);
  }.bind(this);
  var c = renderer.getContext();
  e = c.canvas;
  e.addEventListener(
    "webglcontextlost",
    // @ts-expect-error
    function (p) {
      this.mesh && c.deleteTexture(this.mesh.material.map.__webglTexture);
    }.bind(this),
    !1
  );
  e.addEventListener(
    "webglcontextrestored",
    // @ts-expect-error
    function (p) {
      if (this.mesh) {
        var m = this.mesh.geometry,
          l = null;
        p = c.createBuffer();
        var n =
          // @ts-expect-error
          120 <= THREE.REVISION
            ? // @ts-expect-error
              new THREE.GLBufferAttribute(p, c.FLOAT, 3, 0)
            : // @ts-expect-error
              new THREE.GLBufferAttribute(c, p, c.FLOAT, 3, 0);
        m.setAttribute("position", n);
        if ((n = m.attributes.normal))
          (l = c.createBuffer()),
            (n =
              // @ts-expect-error
              120 <= THREE.REVISION
                ? // @ts-expect-error
                  new THREE.GLBufferAttribute(l, c.FLOAT, 3, 0)
                : // @ts-expect-error
                  new THREE.GLBufferAttribute(c, l, c.FLOAT, 3, 0)),
            m.setAttribute("normal", n);
        // @ts-expect-error
        n = c.createBuffer();
        var g =
          // @ts-expect-error
          120 <= THREE.REVISION
            ? // @ts-expect-error
              new THREE.GLBufferAttribute(n, c.UNSIGNED_SHORT, 2, 0)
            : // @ts-expect-error
              new THREE.GLBufferAttribute(c, n, c.UNSIGNED_SHORT, 2, 0);
        // @ts-expect-error
        g.normalized = !0;
        m.setAttribute("uv", g);
        var h = this.hvo.meshFrames[0].indices.componentType;
        // @ts-expect-error
        g = c.createBuffer();
        h =
          // @ts-expect-error
          120 <= THREE.REVISION ? new THREE.GLBufferAttribute(g, h, 0, 0) : new THREE.GLBufferAttribute(c, g, h, 0, 0);
        m.setIndex(h);
        m = new THREE.Texture();
        m.encoding = THREE.sRGBEncoding;
        h = renderer.properties.get(m);
        h.__webglTexture = c.createTexture();
        var q = c.getParameter(c.TEXTURE_BINDING_2D);
        c.bindTexture(c.TEXTURE_2D, h.__webglTexture);
        c.texImage2D(
          c.TEXTURE_2D,
          0,
          c.RGBA,
          this.fileInfo.videoWidth,
          this.fileInfo.videoHeight,
          0,
          c.RGBA,
          c.UNSIGNED_BYTE,
          null
        );
        c.texParameteri(c.TEXTURE_2D, c.TEXTURE_WRAP_S, c.CLAMP_TO_EDGE);
        c.texParameteri(c.TEXTURE_2D, c.TEXTURE_WRAP_T, c.CLAMP_TO_EDGE);
        c.texParameteri(c.TEXTURE_2D, c.TEXTURE_MIN_FILTER, c.LINEAR);
        c.texParameteri(c.TEXTURE_2D, c.TEXTURE_MAG_FILTER, c.LINEAR);
        c.bindTexture(c.TEXTURE_2D, q);
        this.mesh.material.map = m;
        this.hvo.setBuffers(p, g, n, l, h.__webglTexture);
        this.hvo.updateToLastKeyframe();
        this.bufferGeometry.index.count = 0;
      }
    }.bind(this),
    !1
  );
};
// @ts-expect-error
HoloVideoObjectThreeJS.prototype._hvoOnEndOfStream = function (a: any) {
  if (this.onEndOfStream) this.onEndOfStream(this);
};
HoloVideoObjectThreeJS.prototype.open = function (a, d) {
  this.state > (HoloVideoObjectStates.Empty || 0) && this.close();
  this.hvo.open(a, d);
  this.state = this.hvo.state;
};
// HoloVideoObjectThreeJS.prototype.state = get function () {
//   return this.hvo.state;
// };

// Object.defineProperty(HoloVideoObjectThreeJS.prototype, "state", {
//   get: function state() {
//     // code
//     return this.hvo.state;
//   },
//   set: function state(newState: number) {
//     // code
//     console.log('newState', newState);
//   }
// });

// @ts-expect-error
HoloVideoObjectThreeJS.prototype.update = function (this: IHoloVideoObjectThreeJS) {
  this.hvo && this.mesh && (this.state = this.hvo.state);
  if (this.hvo.updateBuffers()) {
    var a = this.hvo.currentFrameInfo.bboxMin,
      d = this.hvo.currentFrameInfo.bboxMax,
      e = this.bufferGeometry;
    if (!a) {
      console.error("Can not get a");
      return;
    }
    if (!d) {
      console.error("Can not get d");
      return;
    }
    if (!e?.boundingBox) {
      console.error("Can not get e.boundingBox");
      return;
    }
    if (!e?.boundingSphere) {
      console.error("Can not get e.boundingSphere");
      return;
    }
    if (!e?.index) {
      console.error("Can not get e.index");
      return;
    }
    e.boundingBox.min.x = a[0];
    e.boundingBox.min.y = a[1];
    e.boundingBox.min.z = a[2];
    e.boundingBox.max.x = d[0];
    e.boundingBox.max.y = d[1];
    e.boundingBox.max.z = d[2];
    e.boundingBox.getCenter(e.boundingSphere.center);
    e.boundingSphere.radius = 0.5 * Math.max(d[0] - a[0], d[1] - a[1], d[2] - a[2]);
    e.index.count = this.hvo.currentFrameInfo.primCount;
  }
};
// @ts-expect-error
HoloVideoObjectThreeJS.prototype.rewind = function () {
  this.hvo.rewind();
};
// @ts-expect-error
HoloVideoObjectThreeJS.prototype.play = function () {
  this.hvo.state == HoloVideoObjectStates.Opening
    ? this.hvo.forceLoad()
    : this.hvo.state >= HoloVideoObjectStates.Opened &&
      this.hvo.state != HoloVideoObjectStates.Playing &&
      this.hvo.play();
};
// @ts-expect-error
HoloVideoObjectThreeJS.prototype.close = function () {
  this.bufferGeometry && (this.bufferGeometry.index.count = 0);
  this.hvo.close();

  // TODO Проработать удаление плейера
  // delete this.hvo;
};
HoloVideoObjectThreeJS.prototype.pause = function () {
  this.hvo.pause();
  this.state = this.hvo.state;
};
// @ts-expect-error
HoloVideoObjectThreeJS.prototype.setLogLevel = function (
  this: IHoloVideoObjectThreeJS,
  a: IHoloVideoObject["logLevel"]
) {
  // if(!this.hvo) {
  //   return;
  // }
  this.hvo.logLevel = a;
};

// @ts-expect-error
HoloVideoObjectThreeJS.prototype.setAudioEnabled = function (this: IHoloVideoObjectThreeJS, a: boolean) {
  this.hvo.setAudioEnabled(a);
};

// @ts-expect-error
HoloVideoObjectThreeJS.prototype.audioEnabled = function (this: IHoloVideoObjectThreeJS) {
  return this.hvo.audioEnabled();
};

// @ts-expect-error
HoloVideoObjectThreeJS.prototype.setAudioVolume = function (this: IHoloVideoObjectThreeJS, a: boolean) {
  this.hvo.setAudioVolume(a);
};

// @ts-expect-error
HoloVideoObjectThreeJS.prototype.setAutoLooping = function (this: IHoloVideoObjectThreeJS, a: boolean) {
  this.hvo.setAutoLooping(a);
};
