import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./Modal.scss";

export function Modal({
  title,
  titleNode,
  beforeTitle,
  afterTitle,
  children,
  contentClassName,
  classes,
  disableFullscreen,
  beforeTitleStyled,
  style
}) {
  return (
    <div style={style} className={classNames(styles.modal, { [styles.smFullscreen]: !disableFullscreen }, classes?.main)}>
      {(title || beforeTitle || afterTitle) && (
          <div className={classNames(styles.header, classes?.header)}>
          <div className={styles.beforeTitle} style={beforeTitleStyled}>{beforeTitle}</div>
          {titleNode ? titleNode : <h1>{title}</h1>}
          <div className={styles.afterTitle}>{afterTitle}</div>
        </div>
      )}
      <div className={classNames(styles.content, contentClassName)}>{children}</div>
    </div>
  );
}

Modal.propTypes = {
  title: PropTypes.node,
  beforeTitleStyled: PropTypes.object,
  titleNode: PropTypes.node,
  beforeTitle: PropTypes.node,
  afterTitle: PropTypes.node,
  children: PropTypes.node,
  classes: PropTypes.string,
  contentClassName: PropTypes.string,
  disableFullscreen: PropTypes.bool
};
