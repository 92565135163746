const { getHashValue } = require("./custom/utils/url");
let bit = 0;
const nextBit = () => 1 << bit++;
const CL = {
  ALL: -1,
  NONE: 0,
  INTERACTABLES: nextBit(),
  ENVIRONMENT: nextBit(),
  AVATAR: nextBit(),
  HANDS: nextBit(),
  MEDIA_FRAMES: nextBit(),
  // ts compability
  DEFAULT_SPAWNER: 0,
  DEFAULT_INTERACTABLE: 0,
  UNOWNED_INTERACTABLE: 0
};

// @TODO we should split these "sets" off into something other than COLLISION_LAYERS or at least name
// them differently to indicate they are a combination of multiple bits
CL.DEFAULT_INTERACTABLE = CL.INTERACTABLES | CL.ENVIRONMENT | CL.AVATAR | CL.HANDS | CL.MEDIA_FRAMES;
CL.UNOWNED_INTERACTABLE = CL.INTERACTABLES | CL.HANDS | CL.MEDIA_FRAMES;
CL.DEFAULT_SPAWNER = CL.INTERACTABLES | CL.HANDS;

export const POSTPROCESSING = getHashValue("postprocessing");
export const PRIVACY = "https://www.mozilla.org/en-US/privacy/hubs/";
export const TERMS = "https://www.mozilla.org/en-US/about/legal/terms/hubs/";
export const FULLBODY = true; //getHashValue("avatar");

export const COLLISION_LAYERS = CL;
export default { FULLBODY, POSTPROCESSING, PRIVACY, TERMS };

/**
 * Можно ли использовать в имени разделить #
 */
export const AVATAR_USE_NAME_HASH_PART = true;
