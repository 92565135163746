/**
 * Отключаем у аватара возможность ходить
 */
export const disableMovement = () => {
  if (!window.APP?.store?.state?.preferences) {
    return;
  }

  window.APP.store.state.preferences.disableMovement = true;
  window.APP.store.state.preferences.disableTeleporter = true;
  window.APP.store.state.preferences.snapRotationDegrees = 0;
};

/**
 * Включаем аватару возможность ходить
 */
export const enableMovement = () => {
  if (!window.APP?.store?.state?.preferences) {
    return;
  }

  window.APP.store.state.preferences.disableMovement = false;
  window.APP.store.state.preferences.disableTeleporter = false;
  window.APP.store.state.preferences.snapRotationDegrees = 45;
};
