
/**
 * Переход в портал по триггеру.
 * В споуке создаем модель и прописываем:
 * eventId - portal
 * objectId - Целевой УРЛ
 */

import { CollisionEventDetail } from "../../Collect/helpers/onCollisionCollect";
import { COLLIDABLE_MODELS } from "../constants";

function onCollision(event: CustomEvent<CollisionEventDetail>){

  const url = event.detail.mesh.userData.objectId

  if(url) {
    location.replace(url)
  }
}

window.addEventListener(COLLIDABLE_MODELS.portal.eventId, onCollision);
