import React, { useCallback, useState } from "react";
import { isMessageOwner } from "../utils/isMessageOwner";
import { PhotoMessageModal, PhotoMessageModalProps } from "./Modal";

type PhotoMessageProps = Omit<PhotoMessageModalProps, "closeModal"> & {
  sessionId: string;
};

export const PhotoMessageCustom: React.FC<PhotoMessageProps> = props => {
  const [opened, openedSetter] = useState(true);

  const closeModal = useCallback(() => {
    openedSetter(false);
  }, []);

  const { body, sessionId } = props;

  const isOwner = isMessageOwner(sessionId);

  return isOwner && opened ? <PhotoMessageModal body={body} closeModal={closeModal} /> : null;
};
