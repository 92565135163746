import React, { useEffect } from "react";
import { MainViewportModal } from "../../../react-components/modals/MainViewportModal";
import { FirstQuiz } from "../../first-quiz/FirstQuiz";
import { disableMovement, enableMovement } from "../../../utils/movement";

type QuizTipModalProps = {
  TOTAL_ANSWERS: number;
  handleTipClose: (event: React.MouseEvent) => void;
  collectedAll: number;
};

export const QuizTipModal: React.FC<QuizTipModalProps> = ({ TOTAL_ANSWERS, collectedAll, handleTipClose }) => {
  useEffect(() => {
    disableMovement();

    return () => {
      enableMovement();
    };
  }, []);

  return (
    <MainViewportModal>
      <FirstQuiz
        TOTAL_ANSWERS={TOTAL_ANSWERS}
        onClick={handleTipClose}
        collectedAll={collectedAll}
        closeHandler={handleTipClose}
      >
        <>
          <b>Ты ответил на вопрос Квиза!</b>
          <br />
          <br />
          Найди все вопросы и узнай больше про Искусственный интеллект! ;-)
        </>
      </FirstQuiz>
    </MainViewportModal>
  );
};
