import { useEffect, useState } from "react";
import { getNextHub } from "../../utils/get-next-hub";

type useAutoSwitchHubProps = {
  entryDisallowed: boolean;
  joinRoomButton: HTMLButtonElement | null;
};

/**
 * Автоматическая смена хаба, если он уже занят
 */
export const useAutoSwitchHub = ({ entryDisallowed, joinRoomButton }: useAutoSwitchHubProps) => {
  const hub = window.APP?.hub;

  const [inRequest, inRequestSetter] = useState(false);

  /**
   * Смена хаба, если нет прав на вход
   */
  useEffect(() => {
    if (!entryDisallowed || inRequest || !hub?.name) {
      return;
    }

    const calback = async () => {
      inRequestSetter(true);

      /**
       * Получаем id другого хаба
       */
      const hubId = await getNextHub(hub.name)
        .then(hubId => {
          return hubId;
        })
        .catch(console.error)
        .finally(() => {
          inRequestSetter(false);
        });

      /**
       * Если был получен, пробуем переключиться на новый хаб
       */
      if (hubId) {
        await global?.changeHub(hubId).catch(error => {
          console.error(error);
          setTimeout(calback, 1000);
        });
        /**
         * Иначе повторно пытаемся запросить новый хаб
         */
      } else {
        setTimeout(calback, 1000);
      }
    };

    /**
     * Выполняем запрос с задержкой, чтобы не долбить часто сервер
     */
    setTimeout(calback, 1000);
  }, [entryDisallowed, inRequest, hub]);


  /**
   * Автоматический вход в комнату
   */
  useEffect(() => {
    if (!joinRoomButton) {
      return;
    }

    joinRoomButton.click();
  }, [joinRoomButton]);
};
