/**
 * Данный класс создан для удобства работы с кастомными (своими) эвентами (слушателями)
 * Можно в будущем рассширить и улучшить для более удобной работы.
 */
export default class EventHelper{
  #event:CustomEvent
  #eventName:string
  #eventBlock:any

  constructor(eventName:string,eventBlock:any = window){
    if (eventName){
      this.#eventName = eventName
      this.#eventBlock = eventBlock
      this.creater()
    } else { console.error("no event name!") }
  }

  creater(){
    this.#event = new CustomEvent(this.#eventName, {detail:{}});
  }

  dispatch(type:string = 'empty-event', data:any = {}){
    this.dispatchEvent({
      type:type,
      data:data,
    })
  }

  dispatchEvent(keyData:string | object | any, data:any = ''){
    if(typeof keyData === 'string'){
      this.#event.detail[keyData] = data
      this.#eventBlock.dispatchEvent(this.#event);
    } else if(keyData && typeof keyData === 'object'){
      Object.keys(keyData).forEach(key => {this.#event.detail[key] = keyData[key]})
      this.#eventBlock.dispatchEvent(this.#event);
    }
  }
}