import React from "react";
import { CollectCats2 } from "../collect-cats-2/react-components/CollectCats2";
import { ChipQuest } from "../quest/component";

import { Quiz } from "../Quiz/quiz-ui";
import { TriggableVolumetric } from "../TriggableVolumetric";
import styles from './styles.scss'

type CollectProps = {
  /**
   * Можно включать компонент только когда мы в комнате
   */
  entered: true;
};

/**
 * Корневой компонент для котов и квизов
 */
export const Collect: React.FC<CollectProps> = () => {
  if (!global.localStorage) {
    return null;
  }

  return (
    <div className={styles.collectWrapper}>
      {/* <CollectCats2 /> */}
      <ChipQuest />
      <Quiz />
      <TriggableVolumetric />
    </div>
  );
};
