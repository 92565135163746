import React from "react";
import PropTypes from "prop-types";
import { AcceptButton } from "../input/Button";
import styles from "./AvatarSettingsContent.scss";
import { Column } from "../layout/Column";
import { useBadgeData } from "../../custom/hooks/useBadgeData";
import { AvatarSettingsContentCustom } from "../../custom/avatar/AvatarSettingsContent";

export function AvatarSettingsContent(props) {
  const {
    displayName,
    displayNameInputRef,
    disableDisplayNameInput,
    onChangeDisplayName,
    avatarPreview,
    displayNamePattern,
    onEditAvatar,
    uploadingState,
    // eslint-disable-next-line no-unused-vars
    onChangeAvatar,
    // eslint-disable-next-line react/prop-types, no-unused-vars
    onSaveAvatar,
    ...rest
  } = props;

  const badgeFields = useBadgeData({
    disableDisplayNameInput,
    displayName,
    onChangeDisplayName,
    styles,
    displayNameInputRef,
    displayNamePattern
  });

  return (
    <Column as="form" className={styles.content} {...rest}>
      <AvatarSettingsContentCustom
        avatarPreview={avatarPreview}
        uploadingState={uploadingState}
        onEditAvatar={onEditAvatar}
      />

      {badgeFields}
      <AcceptButton disabled={uploadingState} preset="accept" type="submit" />
    </Column>
  );
}

AvatarSettingsContent.propTypes = {
  className: PropTypes.string,
  displayName: PropTypes.string,
  displayNameInputRef: PropTypes.func,
  disableDisplayNameInput: PropTypes.bool,
  displayNamePattern: PropTypes.string,
  onChangeDisplayName: PropTypes.func,
  avatarPreview: PropTypes.node,
  onChangeAvatar: PropTypes.func,
  onEditAvatar: PropTypes.func,
  uploadingState: PropTypes.bool
};
