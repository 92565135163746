/**
 * Размеры экранов
 */
const breakpoints = {
  xs: 320,
  sm: 568,
  md: 896,
  lg: 1200,
  xl: 1920,
}

/**
 * Цвета
 */
const colors = {
  primary: '#333',
}

/**
 * Итоговая тема
 */
export const styledComponentsTheme = {
  ui: {},
  colors,
  breakpoints,
}

export type Theme = typeof styledComponentsTheme

// props that later will be injected by styled-components
export type ThemeProps = { theme?: Theme }

// export default theme
