import React, { useEffect, useState } from "react";
import { PRE_LOADER } from "./data";
import { Modal } from "./modal";

export const PreLoaderHub: React.FC = () => {
  const [modalControl, modalControlSetter] = useState<Boolean>(false);

  useEffect(() => {
    // Включаем окно загрузки прелоадера между хабами
    const onModal = () => {
      modalControlSetter(true);
    };

    // Отключаем окно загрузки прелоадера
    const offModal = () => {
      modalControlSetter(false);
    };

    window.addEventListener(PRE_LOADER.event.sceneFadeOut, onModal);
    window.addEventListener(PRE_LOADER.event.sceneFadeIn, offModal);

    return () => {
      window.removeEventListener(PRE_LOADER.event.sceneFadeOut, onModal);
      window.removeEventListener(PRE_LOADER.event.sceneFadeIn, offModal);
    };
  }, [modalControl]);

  return <>{modalControl && <Modal text={PRE_LOADER.text} />}</>;
};
