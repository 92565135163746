import React, { useCallback, useEffect, useState } from "react";
import { metrikaReachGoal } from "../utils/metrika";
import { COLLIDABLE_MODELS } from "../collidable-model/constants";
import {
  CollisionEventDetail,
  onCollisionCollect,
  onCollisionCollectProps
} from "../Collect/helpers/onCollisionCollect";
import { useCollect } from "../Collect/hooks/useCollect";
import { quizQuestions } from "./data";
import { AnswerItem } from "./interfaces";
import { QuizQuestionModal } from "./Modal/QuizQuestionModal";
import { QuizCompletedModal } from "./Modal/QuizCompletedModal";
import { QuizPromocodeModal } from "./Modal/QuizPromocodeModal";
import { QuizTipModal } from "./Modal/QuizTipModal";
import { CollectInfo } from "../Collect/CollectInfo";
import icon from "./assets/images/Question.source.svg";

const CollectCollisionEventName = COLLIDABLE_MODELS.quiz.eventId;

const TOTAL_ANSWERS = Object.keys(quizQuestions).length;

const litresPromocode = "";

const STORAGE_KEY = "quiz";

export const Quiz: React.FC = () => {
  const [promocode, setPromocode] = useState<"">("");
  const [quiz, setQuiz] = useState<AnswerItem | null>(null);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [quizTip, setQuizTip] = useState(false);

  const { finded, findedSetter, collectedAll } = useCollect({
    storageKey: STORAGE_KEY,
    collectCollisionEventName: CollectCollisionEventName,
    total: TOTAL_ANSWERS
  });

  const handleQuizClose = useCallback(() => {
    setQuiz(null);
    setIsClicked(false);
    if (collectedAll === TOTAL_ANSWERS) {
      setQuizCompleted(true);
      metrikaReachGoal("window-zvuk");
      return;
    } else if (collectedAll === 1) {
      setQuizTip(true);
      setIsClicked(false);
      return;
    } else if (collectedAll === TOTAL_ANSWERS / 2) {
      setPromocode(litresPromocode);
      metrikaReachGoal("windows-litres");
      return;
    }
  }, [collectedAll, litresPromocode]);

  const handlePromocodeCopy = () => {
    promocode && navigator.clipboard.writeText(promocode);
  };

  const handleAnswer = (quizId: string) => {
    setIsClicked(true);
  };

  const handleTipClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    setQuizTip(false);
  };

  const handlePromocodeClose = () => {
    setPromocode("");
  };

  const onCollected = useCallback<onCollisionCollectProps["onCollected"]>((findedId, finded) => {
    const quiz = quizQuestions[findedId];

    if (!quiz) {
      return;
    }

    setQuiz(quiz);
  }, []);

  /**
   * Навешиваем обработчик события коллизии
   */
  useEffect(() => {
    const onCollision = (event: CustomEvent<CollisionEventDetail>) => {
      onCollisionCollect({
        event,
        collectAnimationName: "QuizTriggerCollected",
        finded,
        findedSetter,
        onCollected
      });
    };

    window.addEventListener(CollectCollisionEventName, onCollision);

    return () => {
      window.removeEventListener(CollectCollisionEventName, onCollision);
    };
  }, [finded, onCollected]);

  const handleSberQuizFinished = (close = false) => {
    if (close) {
      setQuizCompleted(false);
    }
  };

  // TODO сейчас логика неправильная. Сейчас получается, что если пользователь
  // уже достиг чего-то (половины или все предметы), то ему не выводится информационное окно,
  // а окно с призом. А должен бы быть статус и список всех достижений. Иначе, достигнув половины,
  // пользователь может решить, что это окончательное достижение.
  const handleCounterClick = () => {
    const answeredCount = collectedAll;
    if (answeredCount === TOTAL_ANSWERS) {
      setQuizCompleted(state => !state);
      return;
    }
    if (answeredCount >= TOTAL_ANSWERS / 2) {
      if (promocode) {
        setPromocode("");
        return;
      }
      setPromocode(litresPromocode);
      return;
    }
    if (answeredCount <= TOTAL_ANSWERS && answeredCount >= 1) {
      setQuizTip(state => !state);
      return;
    }
  };

  let modal: JSX.Element | undefined;

  if (quiz) {
    modal = (
      <QuizQuestionModal
        handleAnswer={handleAnswer}
        isClicked={isClicked}
        handleQuizClose={handleQuizClose}
        quiz={quiz}
      />
    );
  } else if (quizCompleted) {
    modal = <QuizCompletedModal handleSberQuizFinished={handleSberQuizFinished} />;
  } else if (promocode) {
    modal = (
      <QuizPromocodeModal
        handlePromocodeCopy={handlePromocodeCopy}
        promocode={promocode}
        handlePromocodeClose={handlePromocodeClose}
      />
    );
  } else if (quizTip) {
    // TODO Сейчас это окно выводится в последнюю очередь. В текущей реализации,
    // когда достигнут какой-то приз (к примеру, промо по половине выполненных квизов),
    // то по клику вверху в иконку пользователь никогда не увидит это окно
    // Надо бы в информационное окно добавить список всех достижений, чтобы
    // пользователь видел весь прогресс и что он уже достиг
    modal = <QuizTipModal TOTAL_ANSWERS={TOTAL_ANSWERS} handleTipClose={handleTipClose} collectedAll={collectedAll} />;
  }

  return (
    <>
      <CollectInfo finded={collectedAll} total={TOTAL_ANSWERS} icon={icon} onClick={handleCounterClick} />
      {modal}
    </>
  );
};
