import { AnswerItem } from "../interfaces";

/**
 * Чтобы подготовить данные в новом формате из старого
 * Object.values(data).map(n => {

    return [
        n.question,
        n.prompt,
        n.answer,
        n.isZvuk,
    ]
})
 */

const data: Array<
  [
    // question
    string,
    // prompt
    string,
    // answer
    boolean
    // isZvuk
    // boolean
  ]
> = [
  [
    "Правда ли, что Айзек Азимов в своей книге «Я, робот» сформулировал моральный кодекс для роботов?",
    "Такое описание может служить основой принципов отношения роботов к себе и окружающему миру.",
    true
  ],
  [
    "Правда ли, что уже сегодня по городам ездят автомобили без водителя?",
    "Сбер и другие компании активно развивают беспилотные технологии.",
    true
  ],
  [
    "Правда ли, что в игре го можно просчитать все комбинации?",
    "Возможных комбинаций в го, больше чем атомов во Вселенной. ",
    false
  ],
  [
    "Правда ли, что первый игровой автомат работал без участия человека?",
    "Внутри такой машины (1769 год) сидел человек (сильный игрок) и играл против другого человека.",
    false
  ],
  // [
  //   "Пабло Пикассо говорил о способностях компьютеров следующее: «Компьютеры не предназначены для творчества: они должны делать то, что им говорят». Правдива ли сегодня эта фраза?",
  //   "Сегодня компьютеры делают успешные попытки генерации разных по сложности текстов, изображений и даже программного кода. ",
  //   false
  // ],
  // [
  //   "Правда ли, что на 3D-принтере удавалось напечатать цифровой портрет, подобный тому, что может создать автор-человек?",
  //   "Например, в рамках проекта «Новый Рембрандт» был напечатан портрет в стиле Рембрандта, состоящий из 148 миллионов пикселей.",
  //   true
  // ],
  // [
  //   "Правда ли, что одна из программ, созданная японскими разработчиками, написала короткий роман, который вышел в финал литературного конкурса?",
  //   "Этот роман вышел в финал конкурса имени Хоси Синъити.",
  //   true
  // ],
  // [
  //   "Правда ли, что совокупность данных, которые пользователь генерирует во время пребывания в виртуальном пространстве, является цифровым следом?",
  //   "Разнообразие данных, которые мы получаем и отправляем, формируют наш цифровой след.",
  //   true
  // ],
  // [
  //   "Правда ли, что цель кластеризации в машинном обучении — автоматически собрать схожие между собой образцы в группы?",
  //   "Данные объединяются в группы по специальным признакам сходства.",
  //   true
  // ],
  // [
  //   "Правда ли, что чем меньше доступных данных для обработки в анализе данных, тем лучше?",
  //   "Чем больше данных доступно для исследования, тем качественнее будет результат их анализа.",
  //   false
  // ],
  // [
  //   "Правда ли что, при работе с данными не следует включать в свой набор данные, которые статистически никак не имеют отношения к решаемой проблеме?",
  //   "Такие данные могут негативно отразиться на результатах.",
  //   true
  // ],
  // [
  //   "Правда, ли что алгоритм можно обучить отличать собаку от кота?",
  //   "Такие алгоритмы распознавания помогают классифицировать объекты.",
  //   true
  // ],
  // [
  //   "Правда ли, что набор критериев, который используется в том числе для оценки качества модели, — это метрика?",
  //   "Существуют различные метрики оценки моделей машинного обучения. ",
  //   true
  // ],
  // [
  //   "Правда ли, что принцип работы перцептрона в нейронной сети заключается в том, чтобы принимать входные сигналы, обрабатывать их и выдавать некоторый числовой результат?",
  //   "Классическая модель перцептрона состоит из трёх слоёв нейронов, взаимодействующих между собой.",
  //   true
  // ],
  // [
  //   "Правда ли, что когнитивные технологии, как и различные органы человека, занимаются распознаванием внешней информации, а также воспроизведением разных действий?",
  //   "Основные категории когнитивных технологий касаются речи, языка, зрения, принятия решений.",
  //   true
  // ],
  // [
  //   "Правда ли, что модерацию контента в интернете можно отнести к категории систем принятия решений?",
  //   "Ведь выбор по определённым принципам того, что можно опубликовать, а что нельзя, — это не что иное, как принятие решений.",
  //   true
  // ]
];

export const quizQuestions = data.reduce<Record<string, AnswerItem>>((r, n, index) => {
  const { "0": question, "1": prompt, "2": answer } = n;

  const i = index + 1;

  r[i] = {
    id: i.toString(),
    question,
    prompt,
    answer,
    isZvuk: false
  };

  return r;
}, {});
