import React, { FC } from "react";
import styles from "./AspectRatioImage.module.scss";

interface Props {
  img: string;
  ratio?: number;
  objectFit?: "cover" | "contain";
  className?: string;
}

/* 
В основе работы лежит известный хак https://css-tricks.com/aspect-ratio-boxes/
Еще пример: https://ratiobuddy.com/
*/

/**
* AspectRatioImage - резиновая картика. Сохраняет заданные пропорции при изменении ширины
### Props
  - **img** (*string*): Ссылка на изображение
  - **ratio** (*number*): Коэффициент - отношение высоты к ширине 
  - **objectFit** (**cover** | *contain*): object-fit - управляет тем, как изображение занимает предоставляемый размер 
  - **className** (*string*): Возможность задать класс для кастомизации
*/
export const AspectRatioImage: FC<Props> = ({ img, ratio = 100, objectFit = "cover", className = "" }) => {
  return (
    <div className={`${styles.AspectRatioOutterWrapper} ${className}`}>
      <div
        className={styles.AspectRatioInnerWrapper}
        style={{
          paddingTop: `${ratio}%`
        }}
      >
        <img
          className={styles.AspectRatioImage}
          src={img}
          style={{
            objectFit
          }}
          alt=""
        />
      </div>
    </div>
  );
};
