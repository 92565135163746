import React, { useCallback, useEffect, useMemo, useState } from "react";
import IconCat from "./icons/chip-icon.png";
import { CollectModalWindow } from "./CollectModalWindow";

import styles from "./styles.scss";

/**
 * Сколько всего котов требуется
 */
const TOTAL = 4;

type CollectCatsModal = "any" | "all";

export const ChipQuest: React.FC = () => {
  const quest_found_items = localStorage.getItem("quest_found_items")?.split(",").filter(Boolean).length;

  const [finded, setFinded] = useState(quest_found_items || 0);
  const [started, setStarted] = useState(!!localStorage.getItem("dialogue_set_flag_request"));
  const [complited, setComplited] = useState(!!localStorage.getItem("chip-quest-complited"));

  const [modal, modalSetter] = useState<CollectCatsModal | null>(null);

  const showModal = useCallback((finded: number, showAny = false) => {
    /**
     * В зависимости от количества найденных, выводим модалку
     */
    switch (finded) {
      case TOTAL:
        modalSetter("all");
        break;
      default:
        showAny && modalSetter("any");
        break;
    }
  }, []);

  const showStatusModal = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();

      showModal(finded, true);
    },
    [finded, showModal]
  );

  useEffect(() => {
    const questEventHandler = (event: CustomEvent) => {
      const { type, data } = event.detail || {};
      const { chain, countItems } = data || {};

      if (type === "found-item" && chain === "chip") {
        setFinded(countItems);

        if (countItems === 1 || countItems === 4) {
          showModal(countItems, true);
        }

        return;
      }

      if (type === "start" && chain === "chip") {
        setStarted(true);
      }
    };

    window.addEventListener("quest-event", questEventHandler);

    const dialogQuestComplitedEventHandler = (event: CustomEvent) => {
      if (event.detail.quest === "chip-quest") {
        setComplited(true);
        localStorage.setItem("chip-quest-complited", "true");
      }
    };

    window.addEventListener("quest-complited", dialogQuestComplitedEventHandler);

    return () => {
      window.removeEventListener("quest-event", questEventHandler);
      window.removeEventListener("quest-complited", dialogQuestComplitedEventHandler);
    };
  }, []);

  const closeModal = useCallback(() => {
    modalSetter(null);
  }, []);

  const modalContent = useMemo(() => {
    if (!modal) {
      return;
    }

    let text: string | JSX.Element = "";

    switch (true) {
      case finded === TOTAL:
        text = (
          <>
            Отправляйся к БотБоту и отдай ему
            <br />
            собранные модули!
          </>
        );
        break;
      case finded === 1:
        text = (
          <>
            <b>Ты нашел первую микросхему голосового модуля!</b>
            <br /> Собери все микросхемы для БотБота!
          </>
        );
        break;
      default:
        text = (
          <>
            Пройди по Сбервселенной и собери все части
            <br /> для голосового помощника БотБота
          </>
        );
        break;
    }

    return (
      <>
        <CollectModalWindow
          key={`${modal}-${finded}`}
          windowTitle={finded === 4 ? "Задание выполнено" : "Задание"}
          IconCat={IconCat}
          collectedAll={finded}
          TOTAL={TOTAL}
          text={text}
          closeModal={closeModal}
        ></CollectModalWindow>
      </>
    );
  }, [modal, finded, closeModal]);

  if (!started || complited) {
    return null;
  }

  return (
    <>
      <div className={styles.catsCollection} onClick={showStatusModal}>
        <div className={styles.iconCat}>
          <div className={styles.counterCat}>
            <span className={styles.currCats}>{finded}</span>/{TOTAL}
          </div>
        </div>
      </div>
      {modalContent}
    </>
  );
};
