import React, { useEffect } from "react";
import { DialogAdapter } from "../../naf-dialog-adapter";

/**
 * На смену хаба
 */
export function onHubChange() {
  try {
    /**
     * Если микрофон включен, отключаем его
     */
    if (APP.dialog.isMicEnabled) {
      APP.dialog.enableMicrophone(false);
    }
  } catch (error) {
    console.error(error);
  }
}

export function isAllowEnableMic(state: boolean) {
  /**
   * Необходимо проверять стейт, так как этот метод используется на проверку
   * и отключения микрофона, поэтому если не проверять, то и отключить
   * его нельзя
   */
  if (state && APP.hub?.name === "lectory") {
    return false;
  }

  return true;
}

type MicriphonHandlerProps = {
  dialog?: DialogAdapter;
};

export const MicriphonHandler: React.FC<MicriphonHandlerProps> = ({ dialog }) => {
  /**
   * Необходимо отслеживать попытки включения микрофона.
   * В некоторых случаях нельзя включать.
   * Так как вызовы прописаны в нескольких местах и не получается централизованно вклиниваться,
   * просто навешиваем обработчик и в случае, если
   * микрофон включился, отключаем его.
   */
  useEffect(() => {
    if (!dialog) {
      return;
    }

    const onChangeMicState = (event: { enabled: false }) => {
      const { enabled } = event;

      if (enabled && !isAllowEnableMic(enabled)) {
        /**
         * Необходимо откладывать выключение, так как
         * иначе ломается очередность вызовов и фактически
         * микрофон может остаться включенным
         */
        setTimeout(() => {
          dialog.enableMicrophone(false);
        }, 500);
      }
    };

    dialog.addListener("mic-state-changed", onChangeMicState);

    return () => {
      dialog.removeListener("mic-state-changed", onChangeMicState);
    };
  }, [dialog]);

  return null;
};
