import { styled } from "styled-components";

// .currCats
export const CollectInfoCounterFindedStyled = styled.span`
  font-weight: 600;
`;

// .counterCat
export const CollectInfoCounterWrapperStyled = styled.div`
  font-family: "SB Sans Interface", sans-serif;
  font-size: 20px;
  color: #ffffff;
  line-height: 24.88px;
  padding-right: 10px;
  letter-spacing: 1px;
`;

type CollectInfoIconWrapperStyledProps = {
  icon: string;
};

// .iconCat
export const CollectInfoIconWrapperStyled = styled.div<CollectInfoIconWrapperStyledProps>`
  background: no-repeat 8px center url("${({ icon }) => icon}");
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  flex-direction: column;
`;

// .catsCollection
export const CollectInfoStyled = styled.div`
  flex: none;
  background: rgba(38, 62, 84, 0.8);
  border-radius: 32px;
  height: 64px;
  width: 112px;
  cursor: pointer;

  @media screen and (orientation: landscape) and (max-height: 570px) {
    width: 120px;
    height: 52px;
  }
`;
