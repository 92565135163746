import React, { FC, ReactNode, useEffect, useRef } from "react";
import styles from "./ModalWindow.module.scss";

export interface IModalProps<CALLBACK_ARGUMENTS extends any[]> {
  LayoutStructure?: ReactNode;
  CloseButton?: ReactNode;
  handleClose?: (...args: CALLBACK_ARGUMENTS) => void;
  className?: string;
}

/**
* Модальное окно с подложкой. С возможностью закрыть окно либо по клику на кнопку "Закрыть", либо на подложку.
* Компонент ModalWindow представляет собой лишь функционал и скелет, с возможностью кастомизировать стили по дизайну.
### Props
  - **LayoutStructure** (*ReactNode*): Дочерние узлы, чаще всего передаётся **FlexLayout**, но также возможно передать любые элементы.

  - **CloseButton** (*ReactNode*): Кнопка закрыть(опционально), обычно выставляется на position: absolute с возможность кастомизации позиционирования в том или ином угле (top/left/transform). Обычно используется UI компонент **CloseButton**

  - **handleClose** (*(...args: any[]) => void*): Колбек, чтобы закрыть модалку

  - **className** (*string*): Возможность задать класс для кастомизации flex-контейнера
*/
export const ModalWindow: FC<IModalProps<any>> = ({ LayoutStructure, CloseButton, handleClose, className = "" }) => {
  const overlayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    overlayRef.current!.style.opacity = `1`;

    return () => {
      overlayRef.current!.style.opacity = `0`;
    };
  }, []);

  return (
    <div className={`${styles.modalWindow}`}>
      <div className={`${styles.modalWindowOverlay}`} ref={overlayRef} onClick={handleClose}>
      </div>
      <div className={`${styles.modalWindowBodyWrapper} ${className}`}>
        <div className={`${styles.modalWindowBody}`}>
          {LayoutStructure}
          {/* Pass as ^ */}
          {/* {
            <LayoutStructure info={} onClick={handleClose}></LayoutStructure>
          } */}
          {CloseButton}
          {/* Pass as ^ */}
          {/* {
            <CloseButton onClick={handleClose}></CloseButton>
          } */}
        </div>
      </div>
    </div>
  );
};

// export { ModalWindow };
