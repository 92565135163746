import React from "react";
import {
  CollectInfoCounterFindedStyled,
  CollectInfoCounterWrapperStyled,
  CollectInfoIconWrapperStyled,
  CollectInfoStyled
} from "./styles";

// import styles from "../../collect-cats-2/react-components/CollectCats2/styles.scss";

type CollectInfoProps = {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  icon: string;
  // Сколько найдено
  finded: number;
  // Сколько всего
  total: number;
};

export const CollectInfo: React.FC<CollectInfoProps> = ({ onClick, icon, finded, total, ...props }) => {
  return (
    <CollectInfoStyled
      // className={styles.catsCollection}
      onClick={onClick}
      {...props}
    >
      <CollectInfoIconWrapperStyled
        // className={styles.iconCat}
        icon={icon}
      >
        <CollectInfoCounterWrapperStyled
        // className={styles.counterCat}
        >
          <CollectInfoCounterFindedStyled
          // className={styles.currCats}
          >
            {finded}
          </CollectInfoCounterFindedStyled>
          /{total}
        </CollectInfoCounterWrapperStyled>
      </CollectInfoIconWrapperStyled>
    </CollectInfoStyled>
  );
};
