export default {
  exclamationMark: "#environment-scene .ExclamationMarkRobot", // Восклицательный знак ( необязателен )
  items: [ // Цепочка квестовых предметов, выполняются сверху вниз
    { chain: "chip", amount: 4 } // найти предметы с chain (цепочка квеста) в количестве amount.
  ],
  itemsSeparator: "-", // Это разделитель между цепочками квестов которые заполняются в споке как (1-1, 1-2, и т.д) где 1 это цепочка, а 2 это ИД предмета
  status: { // Статусы текущего квеста
    off: "off", // квест не взят.
    start: "start", // взяли квест ( ищим предметы ) *ненайдено не одного
    search: "search", // взяли квест ( ищим предметы ) *найден хотябы 1 и больше, но не все
    found: "found", // Все предметы найденны
    end: "end" // Закончили квест
  },
  eventDialog: "dialogue-set-flag-request", // Эвент  Для работы с диалогом
  eventCollide: "quest", // Эвент колизии который для всех объектов ( сбора ) , задаётся через споук
  quesCollide: "quest-areal", // Эвент колизии точки где берётся квест ( вошли взяли квест ), задаётся через споук
  eventName: "quest-event", // Эвент по которому будет проходить вся реакция
  eventBlock: window, // Элемент на котором будет вызван эвент
  eventType: {// Типы эвентов
    clickObj: "click-obj", // Нажатие на объект который даёт квест
    joinObj: "join-obj", // Зашли в объект
    exitObj: "exit-obj", // вышли из объекта
    start: "start", // Приняли квест
    foundItem: "found-item", // Нашли предмет по квесту
    foundAll: "found-all", // Нашли Все предметы
    end: "end" // Закончили весь квест, и все его цепочки
  },
  localStorage: { // Имена глобальных переменных в локал сторадже
    questChainElement: "quest_chain_element", // текущий квест из цепочки квестов
    questStatus: "quest_status", // Текущий квест из цепочки
    questFoundItems: "quest_found_items", // массив уже найденных предметов
    questDialoguesFlag: "dialogue_set_flag_request" // массив уже найденных предметов
  },
  collision:{ // Данные для объекта коллизии
    event:'collision-detection', // Эвент для прослушивания колизии
    on:'collision-on', // Тип коллизии когда вошли в объект
    off:'collision-off', // Тип коллизии когда покинули объект
    throttleTick: 800 // Как часто мы обрабатываем коллизию внутри тика
  }
};

/**
 * Каждый эвент возвращает:
 * 'status' - текущий статус квеста
 * 'activeChain' - активный сейчас квест из всей цепочки
 * 'countItems' - Количество итемов что уже собрали
 * 'amountAllItems' - Количество ВСЕХ итемов что нужно собрать
 *
 * Эвент когда подобрали предмет + к тем что выше
 * 'eventId' - Эвент который произашле ( тож самое что и 'activeChain')
 * 'objectId' - ИД текущего объекта что подобрали
 * 'mesh' - сам 3Д объект который подобрали ( меш скоторым была колизия )
 */
