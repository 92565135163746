import React, { useEffect, useRef } from "react";
import IconQuiz from "../assets/images/quizz.png";
import styles from "../assets/stylesheets/quiz-ui.scss";
import { FirstQuizStyled, FirstQuizModalContentStyled } from "./styles";

type FirstQuizProps = {
  TOTAL_ANSWERS?: number;
  closeHandler: (event: React.MouseEvent) => void;
  onClick: (event: React.MouseEvent | MouseEvent) => void;
  collectedAll?: number;
  buttonText?: string;
} & {
  children: React.ReactNode;
};

export const FirstQuiz: React.FC<FirstQuizProps> = ({
  onClick,
  closeHandler,
  TOTAL_ANSWERS,
  collectedAll,
  buttonText,
  children
}) => {
  return (
    <FirstQuizStyled className={styles.modalWrapper} closeHandler={closeHandler}>
      <FirstQuizModalContentStyled className={styles.modalContent}>
        <div className={styles.title}>Молодец!</div>
        <img src={IconQuiz} />
        {collectedAll !== undefined && TOTAL_ANSWERS !== undefined && (
          <div className={styles.total}>
            <b>{collectedAll}</b>/{TOTAL_ANSWERS}
          </div>
        )}
        <div className={styles.text}>{children}</div>
        <div onClick={onClick} className={styles.searchQuiz}>
          {buttonText || "Ух, интересно!"}
        </div>
      </FirstQuizModalContentStyled>
    </FirstQuizStyled>
  );
};
