import React from "react";
import { QuizAnswer } from "./Answer";
import styles from "../assets/stylesheets/quiz-ui.scss";
import QuestionImgZvuk from "../assets/images/icon_zvuk_quiz.png";
import QuestionImg from "../assets/images/Quiz.png";
import { ZvukLogo } from "../ZvukLogo";
import { AnswerItem } from "../interfaces";
import { ModalWindow } from "../../ui/StructureComponents/ModalWindow/ModalWindow";
import quizStyles from "./Question.module.scss";
import { IndividualComponentsCloseButton } from "../../ui/IndividualComponents/Buttons/CloseButton/CloseButton";

type QuizQuestionProps = {
  // prompt: string;
  // question: string;
  // id: string;
  // answer: boolean;
  // isZvuk: boolean;
  quiz: AnswerItem;
  isClicked: boolean;
  onClick: (id: string) => void;
  onClose: () => void;
};

export const QuizQuestion: React.FC<QuizQuestionProps> = ({ quiz, isClicked, onClick, onClose }) => {
  const { prompt, question, id, answer, isZvuk } = quiz;

  return (
    <ModalWindow
      LayoutStructure={
        <div className={quizStyles.wrapper}>
          <h5 className={quizStyles.question}>
            {isClicked ? `${answer ? "Правда" : "Неправда"}! ${prompt}` : question}
          </h5>
          <div className={quizStyles.answersContainer}>
            <QuizAnswer
              isZvuk={isZvuk}
              correct={answer}
              isClicked={isClicked}
              onClick={() => onClick(id)}
              value="Правда"
            />
            <QuizAnswer
              isZvuk={isZvuk}
              correct={!answer}
              isClicked={isClicked}
              onClick={() => onClick(id)}
              value="Неправда"
            />
          </div>
        </div>
      }
      handleClose={onClose}
      CloseButton={<IndividualComponentsCloseButton onClick={onClose} className={quizStyles.closeBtn}></IndividualComponentsCloseButton>}
    ></ModalWindow>
  );
};
