import React, { useEffect } from "react";
import { QuizQuestion } from "../../Question";
import { AnswerItem } from "../../interfaces";
import { MainViewportModal } from "../../../react-components/modals/MainViewportModal";
import { disableMovement, enableMovement } from "../../../utils/movement";

type QuizQuestionModalProps = {
  handleAnswer: (quizId: string) => void;
  handleQuizClose: () => void;
  isClicked: boolean;
  quiz: AnswerItem;
};

/**
 * Показываем вопрос
 */
export const QuizQuestionModal: React.FC<QuizQuestionModalProps> = ({
  handleAnswer,
  handleQuizClose,
  isClicked,
  quiz,
  ...other
}) => {
  useEffect(() => {
    disableMovement();

    return () => {
      enableMovement();
    };
  }, []);

  return (
    <MainViewportModal>
      <QuizQuestion onClick={handleAnswer} isClicked={isClicked} onClose={handleQuizClose} quiz={quiz} {...other} />
    </MainViewportModal>
  );
};
