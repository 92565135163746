import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../modal/Modal";
// import { BackButton } from "../input/BackButton";
import { AvatarSettingsContent } from "./AvatarSettingsContent";
import { FormattedMessage } from "react-intl";
import { useHub } from "../../custom/hooks/useHub";

export function AvatarSetupModal({ className, onBack, ...rest }) {
  const { name: hubName = "" } = useHub() || {};

  return (
    <Modal
      beforeTitle={<FormattedMessage id="avatar-setup-sidebar.before-title" defaultMessage="Комната" />}
      // title={hubName}
      titleNode={
        <h1
          style={{
            textAlign: "center"
          }}
        >
          {hubName}
        </h1>
      }
      className={className}
    >
      <AvatarSettingsContent {...rest} />
    </Modal>
  );
}

AvatarSetupModal.propTypes = {
  className: PropTypes.string,
  onBack: PropTypes.func
};
