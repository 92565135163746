import React, { FC, forwardRef, useImperativeHandle, useRef, ForwardRefRenderFunction } from "react";
import styles from "./OptionsButton.module.scss";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  text: string;
  style?: React.CSSProperties;
  animation?: "circle" | "fade" | "color";
  bordered?: true | false;
  isHidden?: true | false;
  correct?: "correct" | "notcorrect" | "";
}
// TODO: ^ Refactor animation to the separate folder and make it less boundy
const OptionsButton = forwardRef<HTMLButtonElement, Props>(
  (
    { text, className = "", onClick, style = {}, animation = "color", bordered = false, isHidden = false, correct="", ...props },
    ref
  ) => {
    const classNameList = [
      styles.button,
      animation === "circle" ? styles.buttonCircleAnimation : ``,
      animation === "fade" ? styles.buttonFadeAnimation : ``,
      animation === "color" ? styles.buttonColorAnimation : ``,
      bordered === true ? styles.buttonBordered : ``,
      isHidden === true ? styles.buttonAnimated : ``,
      correct === "correct" ? styles.buttonGreen : ``,
      correct === "notcorrect" ? styles.buttonRed : ``,
      className
    ];
    return (
      <button
        ref={ref}
        className={`${classNameList.join(" ")}`}
        style={style}
        onClick={onClick}
        {...props}
      >
        <span className={styles.buttonText}>{text}</span>
        {animation === "circle" ? (
          <span className={styles.buttonOverlay}>
            <span className={styles.buttonOverlayCircle}></span>
          </span>
        ) : null}
        {animation === "color" ? (
          <span className={`bgOverlay ${styles.bgOverlay} ${correct ? styles.bgOverlayFixed : ``}`}>
          </span>
        ) : null}
      </button>
    );
  }
);

export default OptionsButton;
