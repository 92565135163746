import { styled } from "styled-components";

export const CloseBtnLineStyled = styled.span``;

export const CloseButtonInnerStyled = styled.div`
  position: absolute;
  overflow: hidden;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  border: 2px solid rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.5);
  transform-origin: center;
  transition: transform 0.35s ease-out;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    background-color: #fff;
    transform-origin: center;
    transform: scale3d(0.4, 0.4, 1);
    opacity: 0;
    transition: opacity 0.35s ease-out, transform 0.35s 0.35s ease-out;
    pointer-events: none;
  }

  ${CloseBtnLineStyled} {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    width: 80%;
    height: 2px;
    background-color: #fff;
    transform-origin: center;
    pointer-events: none;

    &:nth-child(1) {
      // Scale to prevent rare bug in browsers: https://stackoverflow.com/questions/40843657/css-pseudo-element-changes-height-when-moving-its-position
      transform: translate(-50%, -50%) scaleY(1.0001) rotate(45deg);
    }

    &:nth-child(2) {
      // Scale to prevent rare bug in browsers: https://stackoverflow.com/questions/40843657/css-pseudo-element-changes-height-when-moving-its-position
      transform: translate(-50%, -50%) scaleY(1.0001) rotate(-45deg);
    }

    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: inherit;
      background-color: #333;
      opacity: 0;
      transition: opacity 0.35s ease-out;
      pointer-events: none;
    }
  }
`;

export const CloseButtonStyled = styled.button`
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    ${CloseButtonInnerStyled} {
      transform: scale3d(0.8, 0.8, 1);

      &::before {
        transform: scale3d(1, 1, 1);
        transition: opacity 0.35s ease-out, transform 0.35s ease-out;
        opacity: 1;
      }

      ${CloseBtnLineStyled}::before {
        opacity: 1;
      }
    }
  }
`;
